:root {
    --primary-color: #0056b3;
    --secondary-color: #6c757d;
}

.phone-number {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
}

.benefit-card {
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.benefit-card:hover {
    transform: translateY(-5px);
}

.benefit-card i {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.content-box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.stat-card {
    padding: 1.5rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.stat-card h4 {
    color: var(--primary-color);
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.model-card {
    background: #fff;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.model-card:hover {
    transform: translateY(-5px);
}

.testimonial-card {
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.size-badge {
    background: var(--primary-color);
    transition: transform 0.3s ease;
}

.size-badge:hover {
    transform: translateY(-3px);
}

.list-unstyled li {
    margin-bottom: 1rem;
    padding-left: 2rem;
    position: relative;
}

.list-unstyled li i {
    position: absolute;
    left: 0;
    top: 4px;
}

@media (max-width: 768px) {
    .phone-number {
        font-size: 1.2rem;
    }

    .benefit-card {
        margin-bottom: 1rem;
    }

    .stat-card {
        margin-bottom: 1rem;
    }
}