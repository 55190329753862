.brands-section{
    padding: 100px 0;
}
.brands-section .heading{
    color: #fff;
    padding-left: 20px;
}
.brands-section .sub-heading{
    color: #fff;
    padding-left: 20px;
}
.brands-section .desc{
    color: #fff;
    padding-left: 20px;
}
.brand_box{
    display: flex;
}
.brand_box-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}
.brand_box .brand-li {
    padding: 35px 13px;
    -webkit-box-shadow: rgb(233 233 233) 0px 0px 6px;
    box-shadow: rgb(233 233 233) 0px 0px 6px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid #efefef;
    width: 18%;
    margin: 10px;
    text-align: center;
}

.brand_box .brand-li:hover {
    -webkit-transform: scale(1.055);
    transform: scale(1.055);
}

.brand_box .brand-li span {
    display: block;
    font-weight: 500;
    margin-top: 10px;
    font-size: 18px;
    color: #ef233c;
}

.brand_box .brand-li img {
    width: 70px !important;
}

.elementor-5040 .elementor-element.elementor-element-912648c .elementor-icon-list-icon svg{
    width: 25px;
}

.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 30%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

#modalForm{
    
}
.modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.modal-content{
    padding: 20px;
}
.modal-content input{
    margin-bottom: 20px;
}
.modal-content button{
    margin-top: 20px;
    width: 100%;
    background: #ef233c;
}
.custom-dflex {
    justify-content: space-between;
}
.custom-card-width {
    width: 49% !important;
}
.custom-card-width-100 {
    width: 100% !important;
}
.elementor-5040 .elementor-element.elementor-element-17b0c7e{
    padding: 20px 0px 20px 0px !important;
}

@media (max-width: 676px){
    .brand_box .brand-li {
        width: 47%;
        margin: 4px;
    }
    .modal-content {
        width: 90%;
    }
    .custom-card-width {
        width: 100% !important;
    }
}


.faq-qus{
    color: #333;
    font-weight: 400;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -0.04em;
    margin-bottom: 0;
}
.faq-ans{
    background: #f0f2f4;
    padding: 30px;
    padding-right: 50px;
    font-size: 16px;
    color: #535357;
    border-radius: 20px;
    margin-top: 30px;
    text-align: left;
    position: relative;
    margin-bottom: 20px;
}

.owl-carousel .item {
    margin: 5px;
    height: 100%;
    position: relative;
}
.owl-carousel .item img {
    display: block;
    width: 100%;
    height: 100%;
    height: auto;
    border-radius: 10px;
}
.owl-carousel .item .content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.owl-carousel .item .content h2{

}
.owl-carousel .item .content h4{
    color: #fff;
}
.site-header .header-mobile .site-brand img {
    height: 36px !important;
}

.sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Hide sidebar off-screen */
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: left 0.3s ease;
    z-index: 1000;
}
/* Sidebar.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.site-header .klbth-menu-wrapper.primary .klbth-menu > .menu-item > a,
.site-header .header-topbar .header-notice p a,
.site-header .klbth-menu-wrapper.topbar .klbth-menu > li > a,
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item a{
    text-decoration: none;
}


@media screen and (min-width: 320px) {
    .site-drawer {
        max-width: 18.25rem;
    }
}