.klb-search-results {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: 1px solid #d9dde3;
    border-radius: 12px;
    width: 100%;
    z-index: 9999999;
}

.search-holder-form .klb-search-results {
    max-height: 190px;
    overflow-y: scroll;
}

.klb-search-results::-webkit-scrollbar {
	width: 3px;
}

.klb-search-results::-webkit-scrollbar-track {
	background-color: #f6f4f0;
	border-radius: 9px;
}
.klb-search-results::-webkit-scrollbar-thumb {
	background-color: #c1c1c1;
	border-radius: 9px;
}

.klb-search-results ul {
    padding: 0;
	margin:0;
}

.klb-search-results ul li {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;
}

.klb-search-results ul li.search-more {
    justify-content: center;
}

.klb-search-results ul li.search-more a {
    color: var(--color-main-text);
}

.klb-search-results .search-img img {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    max-width: 42px;
    margin-right: 10px;
}

.klb-search-results .search-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.klb-search-results h1.product-title {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 0;
	margin-right: 10px;
}

.klb-search-results h1.product-title a {
    color: var(--color-main-text);
}

.klb-search-results span.price {
    text-align: right;
    font-size: 15px;
    flex-direction: column;
    gap: inherit;
}

.klb-search-results span.price > * {
    display: block;
    margin: 0;
}

.search-loading button i{
	opacity:0;
}

form.search-form .loader-image{
    left: 0;
    right: 0;
    width: 20px;
    color: #212529;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { 
	display: none; 
}