.klbtheme-empty,
.klbtheme-terms + select:not(.klbtheme-select),
.klbtheme-term.klbtheme-disabled:after,
.klbtheme-term.klbtheme-disabled:before {
    display: none !important;
}
.klbtheme-term.klbtheme-disabled {
    opacity: .4;
    cursor: default;
}

.klbtheme-variations {
    margin-bottom: 20px;
}
.variations .klbtheme-variations-items + .klbtheme-variations-items {
    margin-top: 10px;
}

.klbtheme-terms {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.klbtheme-term {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    position: relative;
}

.klbtheme-type-color .klbtheme-term{
    background-color: gray;
	margin-right: 10px;
	width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-bottom: 0;
}

.klbtheme-type-color .klbtheme-term:first-child {
    margin-left: 0;
}

.klbtheme-type-color span.klbtheme-term.klbtheme-selected {
    box-shadow: 0 0 0 2px var(--color-text-description);
    border: 2px solid #fff;
}

.klbtheme-type-image .klbtheme-term {
    height: 42px;
    width: 42px;
}

.klbtheme-type-image .klbtheme-term img {
    width: 100%;
    height: 100%;
    display: block;
}
.klbtheme-type-color .klbtheme-term {
    font-size: 0;
}
.klbtheme-type-button .klbtheme-term {
    font-size: 12px;
}


.klbtheme-type-button span.klbtheme-term {
    width: calc(40% - 20px * 4 / 5);
    border-radius: 0;
    border: 1px solid #d9dde3;
    padding: 15px 14px;
    height: auto;
    line-height: 1;
    margin: 4px 8px 4px 0;
    font-size: 15px;
}

.klbtheme-type-button span.klbtheme-term.klbtheme-selected {
    background: var(--color-primary);
    color: #fff;
    border-color: var(--color-primary);
}

table.variations span.selected-value img {
    display: none;
}

.klbtheme-type-image span.klbtheme-term.klbtheme-selected {
    border: 1px solid var(--color-primary);
}

.single-product table.variations tr {
    display: block !important;
}

@media screen and (min-width: 992px) {
	.single-product-wrapper .product-detail form.cart table.variations .value {
		padding-left: 0 !important; 
		margin-top:10px;
	}
}

.single-product-wrapper .product-detail form.cart table.variations .label span.selected-value {
    color: var(--color-text-primary);
    font-weight: bolder;
}