@charset "UTF-8";
/*------------------------------------------------------------------
[Table of contents]
  01. Theme Variables
  02. Mixins
  03. Helper
  04. Icons
    04.1 Car Service Icons
    04.2 Phone Service Icons
  05. Plugins
    05.1 - Perfect Scrollbar
    05.2 - Slick slider
    05.3 - Select2
    05.4 - Magnific Popup
  06. Form
  07. Theme Menus
  08. Header
  09. Drawer
  10. Modules
    10.1 - SLider Module
    10.2 - Banner Module
    10.3 - Deals products
    10.4 - Coupon Banner
    10.5 - Category Products
    10.6 - Categories
    10.7 - Testimonial
    10.8 - Blog Posts
    10.9 - Logos
    10.10 - Search Holder
  11. WooCommerce
  12. Sidebar
  13. Footer
  14. Social Media
-------------------------------------------------------------------*/
/*------------------------------------ 
    01 - Theme Settings 
------------------------------------*/
/*------------------------------------ 
    02 - Mixins
------------------------------------*/
:root {
  --color-body: #fff;
  --color-text-primary: #212529;
  --color-text-description: #868E96;
  --color-text-light: #B8BDC1;
  --color-text-lighter: #CED4DA;
  --color-info: #E9ECEF;
  --color-info-dark: #DEE2E6;
  --color-info-light: #F1F3F5;
  --color-form-border: #CED4DA;
  --color-form-active-shadow: #ebedf0;
  --color-form-placeholder: #ADB5BD;
  --color-primary: #EF233C;
  --color-primary-dark: #D81D33;
  --color-primary-lighter: #FFF4F6;
  --color-secondary: #3BC9DB;
  --color-secondary-dark: #15AABF;
  --color-secondary-lighter: #E3FAFC;
  --color-success: #12B886;
  --color-success-dark: #099268;
  --color-success-lighter: #E6FCF5;
  --color-danger: #F03E3E;
  --color-danger-dark: #C92A2A;
  --color-danger-light: #FFF5F5;
  --color-warning: #FD7E14;
  --color-warning-dark: #E8590C;
  --color-warning-lighter: #FFF4E6;
  --color-review: #FFD43B;
  --color-review-dark: #FCC419;
  --color-review-lighter: #FFF9DB;
  --color-gradient-1: linear-gradient(45deg, rgb(239, 35, 60) 0%, rgb(244, 113, 3) 100%);
}

:root {
  --theme-primary-font: "Krub", sans-serif;
  --theme-secondary-font: "Krub", sans-serif;
  --theme-font-size: 1rem;
  --theme-font-weight: 400;
  --theme-letter-spacing: -0.03px;
  --theme-container: 1280px;
  --theme-gutter: 15px;
  --theme-theme-radius-xsmall: 6px;
  --theme-theme-radius-small: 8px;
  --theme-theme-radius-base: 12px;
  --theme-theme-radius-large: 18px;
  --theme-form-font-size: 15px;
  --theme-form-font-weight: 500;
  --theme-form-radius: 12px;
  --theme-form-height: 42px;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

@media (min-width: 62rem) {
  .d-mt-0 {
    margin-top: 0px !important;
  }
  .d-pt-0 {
    padding-top: 0px !important;
  }
}
.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

@media (min-width: 62rem) {
  .d-mb-0 {
    margin-bottom: 0px !important;
  }
  .d-pb-0 {
    padding-bottom: 0px !important;
  }
}
.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

@media (min-width: 62rem) {
  .d-ml-0 {
    margin-left: 0px !important;
  }
  .d-pl-0 {
    padding-left: 0px !important;
  }
}
.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

@media (min-width: 62rem) {
  .d-mr-0 {
    margin-right: 0px !important;
  }
  .d-pr-0 {
    padding-right: 0px !important;
  }
}
.mt-5 {
  margin-top: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

@media (min-width: 62rem) {
  .d-mt-5 {
    margin-top: 5px !important;
  }
  .d-pt-5 {
    padding-top: 5px !important;
  }
}
.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

@media (min-width: 62rem) {
  .d-mb-5 {
    margin-bottom: 5px !important;
  }
  .d-pb-5 {
    padding-bottom: 5px !important;
  }
}
.ml-5 {
  margin-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

@media (min-width: 62rem) {
  .d-ml-5 {
    margin-left: 5px !important;
  }
  .d-pl-5 {
    padding-left: 5px !important;
  }
}
.mr-5 {
  margin-right: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

@media (min-width: 62rem) {
  .d-mr-5 {
    margin-right: 5px !important;
  }
  .d-pr-5 {
    padding-right: 5px !important;
  }
}
.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

@media (min-width: 62rem) {
  .d-mt-10 {
    margin-top: 10px !important;
  }
  .d-pt-10 {
    padding-top: 10px !important;
  }
}
.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

@media (min-width: 62rem) {
  .d-mb-10 {
    margin-bottom: 10px !important;
  }
  .d-pb-10 {
    padding-bottom: 10px !important;
  }
}
.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

@media (min-width: 62rem) {
  .d-ml-10 {
    margin-left: 10px !important;
  }
  .d-pl-10 {
    padding-left: 10px !important;
  }
}
.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

@media (min-width: 62rem) {
  .d-mr-10 {
    margin-right: 10px !important;
  }
  .d-pr-10 {
    padding-right: 10px !important;
  }
}
.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

@media (min-width: 62rem) {
  .d-mt-15 {
    margin-top: 15px !important;
  }
  .d-pt-15 {
    padding-top: 15px !important;
  }
}
.mb-15 {
  margin-bottom: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

@media (min-width: 62rem) {
  .d-mb-15 {
    margin-bottom: 15px !important;
  }
  .d-pb-15 {
    padding-bottom: 15px !important;
  }
}
.ml-15 {
  margin-left: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

@media (min-width: 62rem) {
  .d-ml-15 {
    margin-left: 15px !important;
  }
  .d-pl-15 {
    padding-left: 15px !important;
  }
}
.mr-15 {
  margin-right: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

@media (min-width: 62rem) {
  .d-mr-15 {
    margin-right: 15px !important;
  }
  .d-pr-15 {
    padding-right: 15px !important;
  }
}
.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

@media (min-width: 62rem) {
  .d-mt-20 {
    margin-top: 20px !important;
  }
  .d-pt-20 {
    padding-top: 20px !important;
  }
}
.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

@media (min-width: 62rem) {
  .d-mb-20 {
    margin-bottom: 20px !important;
  }
  .d-pb-20 {
    padding-bottom: 20px !important;
  }
}
.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

@media (min-width: 62rem) {
  .d-ml-20 {
    margin-left: 20px !important;
  }
  .d-pl-20 {
    padding-left: 20px !important;
  }
}
.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

@media (min-width: 62rem) {
  .d-mr-20 {
    margin-right: 20px !important;
  }
  .d-pr-20 {
    padding-right: 20px !important;
  }
}
.mt-25 {
  margin-top: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

@media (min-width: 62rem) {
  .d-mt-25 {
    margin-top: 25px !important;
  }
  .d-pt-25 {
    padding-top: 25px !important;
  }
}
.mb-25 {
  margin-bottom: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

@media (min-width: 62rem) {
  .d-mb-25 {
    margin-bottom: 25px !important;
  }
  .d-pb-25 {
    padding-bottom: 25px !important;
  }
}
.ml-25 {
  margin-left: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

@media (min-width: 62rem) {
  .d-ml-25 {
    margin-left: 25px !important;
  }
  .d-pl-25 {
    padding-left: 25px !important;
  }
}
.mr-25 {
  margin-right: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

@media (min-width: 62rem) {
  .d-mr-25 {
    margin-right: 25px !important;
  }
  .d-pr-25 {
    padding-right: 25px !important;
  }
}
.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

@media (min-width: 62rem) {
  .d-mt-30 {
    margin-top: 30px !important;
  }
  .d-pt-30 {
    padding-top: 30px !important;
  }
}
.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

@media (min-width: 62rem) {
  .d-mb-30 {
    margin-bottom: 30px !important;
  }
  .d-pb-30 {
    padding-bottom: 30px !important;
  }
}
.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

@media (min-width: 62rem) {
  .d-ml-30 {
    margin-left: 30px !important;
  }
  .d-pl-30 {
    padding-left: 30px !important;
  }
}
.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

@media (min-width: 62rem) {
  .d-mr-30 {
    margin-right: 30px !important;
  }
  .d-pr-30 {
    padding-right: 30px !important;
  }
}
.mt-35 {
  margin-top: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

@media (min-width: 62rem) {
  .d-mt-35 {
    margin-top: 35px !important;
  }
  .d-pt-35 {
    padding-top: 35px !important;
  }
}
.mb-35 {
  margin-bottom: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

@media (min-width: 62rem) {
  .d-mb-35 {
    margin-bottom: 35px !important;
  }
  .d-pb-35 {
    padding-bottom: 35px !important;
  }
}
.ml-35 {
  margin-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

@media (min-width: 62rem) {
  .d-ml-35 {
    margin-left: 35px !important;
  }
  .d-pl-35 {
    padding-left: 35px !important;
  }
}
.mr-35 {
  margin-right: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

@media (min-width: 62rem) {
  .d-mr-35 {
    margin-right: 35px !important;
  }
  .d-pr-35 {
    padding-right: 35px !important;
  }
}
.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

@media (min-width: 62rem) {
  .d-mt-40 {
    margin-top: 40px !important;
  }
  .d-pt-40 {
    padding-top: 40px !important;
  }
}
.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

@media (min-width: 62rem) {
  .d-mb-40 {
    margin-bottom: 40px !important;
  }
  .d-pb-40 {
    padding-bottom: 40px !important;
  }
}
.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

@media (min-width: 62rem) {
  .d-ml-40 {
    margin-left: 40px !important;
  }
  .d-pl-40 {
    padding-left: 40px !important;
  }
}
.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

@media (min-width: 62rem) {
  .d-mr-40 {
    margin-right: 40px !important;
  }
  .d-pr-40 {
    padding-right: 40px !important;
  }
}
.mt-45 {
  margin-top: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

@media (min-width: 62rem) {
  .d-mt-45 {
    margin-top: 45px !important;
  }
  .d-pt-45 {
    padding-top: 45px !important;
  }
}
.mb-45 {
  margin-bottom: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

@media (min-width: 62rem) {
  .d-mb-45 {
    margin-bottom: 45px !important;
  }
  .d-pb-45 {
    padding-bottom: 45px !important;
  }
}
.ml-45 {
  margin-left: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

@media (min-width: 62rem) {
  .d-ml-45 {
    margin-left: 45px !important;
  }
  .d-pl-45 {
    padding-left: 45px !important;
  }
}
.mr-45 {
  margin-right: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

@media (min-width: 62rem) {
  .d-mr-45 {
    margin-right: 45px !important;
  }
  .d-pr-45 {
    padding-right: 45px !important;
  }
}
.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

@media (min-width: 62rem) {
  .d-mt-50 {
    margin-top: 50px !important;
  }
  .d-pt-50 {
    padding-top: 50px !important;
  }
}
.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

@media (min-width: 62rem) {
  .d-mb-50 {
    margin-bottom: 50px !important;
  }
  .d-pb-50 {
    padding-bottom: 50px !important;
  }
}
.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

@media (min-width: 62rem) {
  .d-ml-50 {
    margin-left: 50px !important;
  }
  .d-pl-50 {
    padding-left: 50px !important;
  }
}
.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

@media (min-width: 62rem) {
  .d-mr-50 {
    margin-right: 50px !important;
  }
  .d-pr-50 {
    padding-right: 50px !important;
  }
}
.mt-55 {
  margin-top: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

@media (min-width: 62rem) {
  .d-mt-55 {
    margin-top: 55px !important;
  }
  .d-pt-55 {
    padding-top: 55px !important;
  }
}
.mb-55 {
  margin-bottom: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

@media (min-width: 62rem) {
  .d-mb-55 {
    margin-bottom: 55px !important;
  }
  .d-pb-55 {
    padding-bottom: 55px !important;
  }
}
.ml-55 {
  margin-left: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

@media (min-width: 62rem) {
  .d-ml-55 {
    margin-left: 55px !important;
  }
  .d-pl-55 {
    padding-left: 55px !important;
  }
}
.mr-55 {
  margin-right: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

@media (min-width: 62rem) {
  .d-mr-55 {
    margin-right: 55px !important;
  }
  .d-pr-55 {
    padding-right: 55px !important;
  }
}
.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

@media (min-width: 62rem) {
  .d-mt-60 {
    margin-top: 60px !important;
  }
  .d-pt-60 {
    padding-top: 60px !important;
  }
}
.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

@media (min-width: 62rem) {
  .d-mb-60 {
    margin-bottom: 60px !important;
  }
  .d-pb-60 {
    padding-bottom: 60px !important;
  }
}
.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

@media (min-width: 62rem) {
  .d-ml-60 {
    margin-left: 60px !important;
  }
  .d-pl-60 {
    padding-left: 60px !important;
  }
}
.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

@media (min-width: 62rem) {
  .d-mr-60 {
    margin-right: 60px !important;
  }
  .d-pr-60 {
    padding-right: 60px !important;
  }
}
.mt-65 {
  margin-top: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

@media (min-width: 62rem) {
  .d-mt-65 {
    margin-top: 65px !important;
  }
  .d-pt-65 {
    padding-top: 65px !important;
  }
}
.mb-65 {
  margin-bottom: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

@media (min-width: 62rem) {
  .d-mb-65 {
    margin-bottom: 65px !important;
  }
  .d-pb-65 {
    padding-bottom: 65px !important;
  }
}
.ml-65 {
  margin-left: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

@media (min-width: 62rem) {
  .d-ml-65 {
    margin-left: 65px !important;
  }
  .d-pl-65 {
    padding-left: 65px !important;
  }
}
.mr-65 {
  margin-right: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

@media (min-width: 62rem) {
  .d-mr-65 {
    margin-right: 65px !important;
  }
  .d-pr-65 {
    padding-right: 65px !important;
  }
}
.mt-70 {
  margin-top: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

@media (min-width: 62rem) {
  .d-mt-70 {
    margin-top: 70px !important;
  }
  .d-pt-70 {
    padding-top: 70px !important;
  }
}
.mb-70 {
  margin-bottom: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

@media (min-width: 62rem) {
  .d-mb-70 {
    margin-bottom: 70px !important;
  }
  .d-pb-70 {
    padding-bottom: 70px !important;
  }
}
.ml-70 {
  margin-left: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

@media (min-width: 62rem) {
  .d-ml-70 {
    margin-left: 70px !important;
  }
  .d-pl-70 {
    padding-left: 70px !important;
  }
}
.mr-70 {
  margin-right: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

@media (min-width: 62rem) {
  .d-mr-70 {
    margin-right: 70px !important;
  }
  .d-pr-70 {
    padding-right: 70px !important;
  }
}
.mt-75 {
  margin-top: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

@media (min-width: 62rem) {
  .d-mt-75 {
    margin-top: 75px !important;
  }
  .d-pt-75 {
    padding-top: 75px !important;
  }
}
.mb-75 {
  margin-bottom: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

@media (min-width: 62rem) {
  .d-mb-75 {
    margin-bottom: 75px !important;
  }
  .d-pb-75 {
    padding-bottom: 75px !important;
  }
}
.ml-75 {
  margin-left: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

@media (min-width: 62rem) {
  .d-ml-75 {
    margin-left: 75px !important;
  }
  .d-pl-75 {
    padding-left: 75px !important;
  }
}
.mr-75 {
  margin-right: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

@media (min-width: 62rem) {
  .d-mr-75 {
    margin-right: 75px !important;
  }
  .d-pr-75 {
    padding-right: 75px !important;
  }
}
.mt-80 {
  margin-top: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

@media (min-width: 62rem) {
  .d-mt-80 {
    margin-top: 80px !important;
  }
  .d-pt-80 {
    padding-top: 80px !important;
  }
}
.mb-80 {
  margin-bottom: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

@media (min-width: 62rem) {
  .d-mb-80 {
    margin-bottom: 80px !important;
  }
  .d-pb-80 {
    padding-bottom: 80px !important;
  }
}
.ml-80 {
  margin-left: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

@media (min-width: 62rem) {
  .d-ml-80 {
    margin-left: 80px !important;
  }
  .d-pl-80 {
    padding-left: 80px !important;
  }
}
.mr-80 {
  margin-right: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

@media (min-width: 62rem) {
  .d-mr-80 {
    margin-right: 80px !important;
  }
  .d-pr-80 {
    padding-right: 80px !important;
  }
}
.mt-85 {
  margin-top: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

@media (min-width: 62rem) {
  .d-mt-85 {
    margin-top: 85px !important;
  }
  .d-pt-85 {
    padding-top: 85px !important;
  }
}
.mb-85 {
  margin-bottom: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

@media (min-width: 62rem) {
  .d-mb-85 {
    margin-bottom: 85px !important;
  }
  .d-pb-85 {
    padding-bottom: 85px !important;
  }
}
.ml-85 {
  margin-left: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

@media (min-width: 62rem) {
  .d-ml-85 {
    margin-left: 85px !important;
  }
  .d-pl-85 {
    padding-left: 85px !important;
  }
}
.mr-85 {
  margin-right: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

@media (min-width: 62rem) {
  .d-mr-85 {
    margin-right: 85px !important;
  }
  .d-pr-85 {
    padding-right: 85px !important;
  }
}
.mt-90 {
  margin-top: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

@media (min-width: 62rem) {
  .d-mt-90 {
    margin-top: 90px !important;
  }
  .d-pt-90 {
    padding-top: 90px !important;
  }
}
.mb-90 {
  margin-bottom: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

@media (min-width: 62rem) {
  .d-mb-90 {
    margin-bottom: 90px !important;
  }
  .d-pb-90 {
    padding-bottom: 90px !important;
  }
}
.ml-90 {
  margin-left: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

@media (min-width: 62rem) {
  .d-ml-90 {
    margin-left: 90px !important;
  }
  .d-pl-90 {
    padding-left: 90px !important;
  }
}
.mr-90 {
  margin-right: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

@media (min-width: 62rem) {
  .d-mr-90 {
    margin-right: 90px !important;
  }
  .d-pr-90 {
    padding-right: 90px !important;
  }
}
.mt-95 {
  margin-top: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

@media (min-width: 62rem) {
  .d-mt-95 {
    margin-top: 95px !important;
  }
  .d-pt-95 {
    padding-top: 95px !important;
  }
}
.mb-95 {
  margin-bottom: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

@media (min-width: 62rem) {
  .d-mb-95 {
    margin-bottom: 95px !important;
  }
  .d-pb-95 {
    padding-bottom: 95px !important;
  }
}
.ml-95 {
  margin-left: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

@media (min-width: 62rem) {
  .d-ml-95 {
    margin-left: 95px !important;
  }
  .d-pl-95 {
    padding-left: 95px !important;
  }
}
.mr-95 {
  margin-right: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

@media (min-width: 62rem) {
  .d-mr-95 {
    margin-right: 95px !important;
  }
  .d-pr-95 {
    padding-right: 95px !important;
  }
}
.mt-100 {
  margin-top: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

@media (min-width: 62rem) {
  .d-mt-100 {
    margin-top: 100px !important;
  }
  .d-pt-100 {
    padding-top: 100px !important;
  }
}
.mb-100 {
  margin-bottom: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

@media (min-width: 62rem) {
  .d-mb-100 {
    margin-bottom: 100px !important;
  }
  .d-pb-100 {
    padding-bottom: 100px !important;
  }
}
.ml-100 {
  margin-left: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

@media (min-width: 62rem) {
  .d-ml-100 {
    margin-left: 100px !important;
  }
  .d-pl-100 {
    padding-left: 100px !important;
  }
}
.mr-100 {
  margin-right: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

@media (min-width: 62rem) {
  .d-mr-100 {
    margin-right: 100px !important;
  }
  .d-pr-100 {
    padding-right: 100px !important;
  }
}
.mt-110 {
  margin-top: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

@media (min-width: 62rem) {
  .d-mt-110 {
    margin-top: 110px !important;
  }
  .d-pt-110 {
    padding-top: 110px !important;
  }
}
.mb-110 {
  margin-bottom: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

@media (min-width: 62rem) {
  .d-mb-110 {
    margin-bottom: 110px !important;
  }
  .d-pb-110 {
    padding-bottom: 110px !important;
  }
}
.ml-110 {
  margin-left: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

@media (min-width: 62rem) {
  .d-ml-110 {
    margin-left: 110px !important;
  }
  .d-pl-110 {
    padding-left: 110px !important;
  }
}
.mr-110 {
  margin-right: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

@media (min-width: 62rem) {
  .d-mr-110 {
    margin-right: 110px !important;
  }
  .d-pr-110 {
    padding-right: 110px !important;
  }
}
.mt-120 {
  margin-top: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

@media (min-width: 62rem) {
  .d-mt-120 {
    margin-top: 120px !important;
  }
  .d-pt-120 {
    padding-top: 120px !important;
  }
}
.mb-120 {
  margin-bottom: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

@media (min-width: 62rem) {
  .d-mb-120 {
    margin-bottom: 120px !important;
  }
  .d-pb-120 {
    padding-bottom: 120px !important;
  }
}
.ml-120 {
  margin-left: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

@media (min-width: 62rem) {
  .d-ml-120 {
    margin-left: 120px !important;
  }
  .d-pl-120 {
    padding-left: 120px !important;
  }
}
.mr-120 {
  margin-right: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

@media (min-width: 62rem) {
  .d-mr-120 {
    margin-right: 120px !important;
  }
  .d-pr-120 {
    padding-right: 120px !important;
  }
}
.mt-130 {
  margin-top: 130px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

@media (min-width: 62rem) {
  .d-mt-130 {
    margin-top: 130px !important;
  }
  .d-pt-130 {
    padding-top: 130px !important;
  }
}
.mb-130 {
  margin-bottom: 130px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

@media (min-width: 62rem) {
  .d-mb-130 {
    margin-bottom: 130px !important;
  }
  .d-pb-130 {
    padding-bottom: 130px !important;
  }
}
.ml-130 {
  margin-left: 130px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

@media (min-width: 62rem) {
  .d-ml-130 {
    margin-left: 130px !important;
  }
  .d-pl-130 {
    padding-left: 130px !important;
  }
}
.mr-130 {
  margin-right: 130px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

@media (min-width: 62rem) {
  .d-mr-130 {
    margin-right: 130px !important;
  }
  .d-pr-130 {
    padding-right: 130px !important;
  }
}
.mt-140 {
  margin-top: 140px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

@media (min-width: 62rem) {
  .d-mt-140 {
    margin-top: 140px !important;
  }
  .d-pt-140 {
    padding-top: 140px !important;
  }
}
.mb-140 {
  margin-bottom: 140px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

@media (min-width: 62rem) {
  .d-mb-140 {
    margin-bottom: 140px !important;
  }
  .d-pb-140 {
    padding-bottom: 140px !important;
  }
}
.ml-140 {
  margin-left: 140px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

@media (min-width: 62rem) {
  .d-ml-140 {
    margin-left: 140px !important;
  }
  .d-pl-140 {
    padding-left: 140px !important;
  }
}
.mr-140 {
  margin-right: 140px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

@media (min-width: 62rem) {
  .d-mr-140 {
    margin-right: 140px !important;
  }
  .d-pr-140 {
    padding-right: 140px !important;
  }
}
.mt-150 {
  margin-top: 150px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

@media (min-width: 62rem) {
  .d-mt-150 {
    margin-top: 150px !important;
  }
  .d-pt-150 {
    padding-top: 150px !important;
  }
}
.mb-150 {
  margin-bottom: 150px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

@media (min-width: 62rem) {
  .d-mb-150 {
    margin-bottom: 150px !important;
  }
  .d-pb-150 {
    padding-bottom: 150px !important;
  }
}
.ml-150 {
  margin-left: 150px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

@media (min-width: 62rem) {
  .d-ml-150 {
    margin-left: 150px !important;
  }
  .d-pl-150 {
    padding-left: 150px !important;
  }
}
.mr-150 {
  margin-right: 150px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

@media (min-width: 62rem) {
  .d-mr-150 {
    margin-right: 150px !important;
  }
  .d-pr-150 {
    padding-right: 150px !important;
  }
}
.mt-160 {
  margin-top: 160px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

@media (min-width: 62rem) {
  .d-mt-160 {
    margin-top: 160px !important;
  }
  .d-pt-160 {
    padding-top: 160px !important;
  }
}
.mb-160 {
  margin-bottom: 160px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

@media (min-width: 62rem) {
  .d-mb-160 {
    margin-bottom: 160px !important;
  }
  .d-pb-160 {
    padding-bottom: 160px !important;
  }
}
.ml-160 {
  margin-left: 160px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

@media (min-width: 62rem) {
  .d-ml-160 {
    margin-left: 160px !important;
  }
  .d-pl-160 {
    padding-left: 160px !important;
  }
}
.mr-160 {
  margin-right: 160px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

@media (min-width: 62rem) {
  .d-mr-160 {
    margin-right: 160px !important;
  }
  .d-pr-160 {
    padding-right: 160px !important;
  }
}
.mt-165 {
  margin-top: 165px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

@media (min-width: 62rem) {
  .d-mt-165 {
    margin-top: 165px !important;
  }
  .d-pt-165 {
    padding-top: 165px !important;
  }
}
.mb-165 {
  margin-bottom: 165px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

@media (min-width: 62rem) {
  .d-mb-165 {
    margin-bottom: 165px !important;
  }
  .d-pb-165 {
    padding-bottom: 165px !important;
  }
}
.ml-165 {
  margin-left: 165px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

@media (min-width: 62rem) {
  .d-ml-165 {
    margin-left: 165px !important;
  }
  .d-pl-165 {
    padding-left: 165px !important;
  }
}
.mr-165 {
  margin-right: 165px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

@media (min-width: 62rem) {
  .d-mr-165 {
    margin-right: 165px !important;
  }
  .d-pr-165 {
    padding-right: 165px !important;
  }
}
.mt-170 {
  margin-top: 170px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

@media (min-width: 62rem) {
  .d-mt-170 {
    margin-top: 170px !important;
  }
  .d-pt-170 {
    padding-top: 170px !important;
  }
}
.mb-170 {
  margin-bottom: 170px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

@media (min-width: 62rem) {
  .d-mb-170 {
    margin-bottom: 170px !important;
  }
  .d-pb-170 {
    padding-bottom: 170px !important;
  }
}
.ml-170 {
  margin-left: 170px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

@media (min-width: 62rem) {
  .d-ml-170 {
    margin-left: 170px !important;
  }
  .d-pl-170 {
    padding-left: 170px !important;
  }
}
.mr-170 {
  margin-right: 170px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

@media (min-width: 62rem) {
  .d-mr-170 {
    margin-right: 170px !important;
  }
  .d-pr-170 {
    padding-right: 170px !important;
  }
}
.mt-180 {
  margin-top: 180px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

@media (min-width: 62rem) {
  .d-mt-180 {
    margin-top: 180px !important;
  }
  .d-pt-180 {
    padding-top: 180px !important;
  }
}
.mb-180 {
  margin-bottom: 180px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

@media (min-width: 62rem) {
  .d-mb-180 {
    margin-bottom: 180px !important;
  }
  .d-pb-180 {
    padding-bottom: 180px !important;
  }
}
.ml-180 {
  margin-left: 180px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

@media (min-width: 62rem) {
  .d-ml-180 {
    margin-left: 180px !important;
  }
  .d-pl-180 {
    padding-left: 180px !important;
  }
}
.mr-180 {
  margin-right: 180px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

@media (min-width: 62rem) {
  .d-mr-180 {
    margin-right: 180px !important;
  }
  .d-pr-180 {
    padding-right: 180px !important;
  }
}
.mt-190 {
  margin-top: 190px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

@media (min-width: 62rem) {
  .d-mt-190 {
    margin-top: 190px !important;
  }
  .d-pt-190 {
    padding-top: 190px !important;
  }
}
.mb-190 {
  margin-bottom: 190px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

@media (min-width: 62rem) {
  .d-mb-190 {
    margin-bottom: 190px !important;
  }
  .d-pb-190 {
    padding-bottom: 190px !important;
  }
}
.ml-190 {
  margin-left: 190px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

@media (min-width: 62rem) {
  .d-ml-190 {
    margin-left: 190px !important;
  }
  .d-pl-190 {
    padding-left: 190px !important;
  }
}
.mr-190 {
  margin-right: 190px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

@media (min-width: 62rem) {
  .d-mr-190 {
    margin-right: 190px !important;
  }
  .d-pr-190 {
    padding-right: 190px !important;
  }
}
.mt-200 {
  margin-top: 200px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

@media (min-width: 62rem) {
  .d-mt-200 {
    margin-top: 200px !important;
  }
  .d-pt-200 {
    padding-top: 200px !important;
  }
}
.mb-200 {
  margin-bottom: 200px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

@media (min-width: 62rem) {
  .d-mb-200 {
    margin-bottom: 200px !important;
  }
  .d-pb-200 {
    padding-bottom: 200px !important;
  }
}
.ml-200 {
  margin-left: 200px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

@media (min-width: 62rem) {
  .d-ml-200 {
    margin-left: 200px !important;
  }
  .d-pl-200 {
    padding-left: 200px !important;
  }
}
.mr-200 {
  margin-right: 200px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

@media (min-width: 62rem) {
  .d-mr-200 {
    margin-right: 200px !important;
  }
  .d-pr-200 {
    padding-right: 200px !important;
  }
}
.mt-250 {
  margin-top: 250px !important;
}

.pt-250 {
  padding-top: 250px !important;
}

@media (min-width: 62rem) {
  .d-mt-250 {
    margin-top: 250px !important;
  }
  .d-pt-250 {
    padding-top: 250px !important;
  }
}
.mb-250 {
  margin-bottom: 250px !important;
}

.pb-250 {
  padding-bottom: 250px !important;
}

@media (min-width: 62rem) {
  .d-mb-250 {
    margin-bottom: 250px !important;
  }
  .d-pb-250 {
    padding-bottom: 250px !important;
  }
}
.ml-250 {
  margin-left: 250px !important;
}

.pl-250 {
  padding-left: 250px !important;
}

@media (min-width: 62rem) {
  .d-ml-250 {
    margin-left: 250px !important;
  }
  .d-pl-250 {
    padding-left: 250px !important;
  }
}
.mr-250 {
  margin-right: 250px !important;
}

.pr-250 {
  padding-right: 250px !important;
}

@media (min-width: 62rem) {
  .d-mr-250 {
    margin-right: 250px !important;
  }
  .d-pr-250 {
    padding-right: 250px !important;
  }
}
@-webkit-keyframes animation-b7n1on {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes animation-b7n1on {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*------------------------------------ 
    03 - Helper 
------------------------------------*/
body {
  font-family: var(--theme-primary-font);
  font-size: var(--theme-font-size);
  font-weight: var(--theme-font-weight);
  -webkit-font-feature-settings: "kern";
          font-feature-settings: "kern";
  letter-spacing: var(--theme-letter-spacing);
  color: var(--color-text-primary);
  background-color: var(--color-body);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--theme-primary-font);
  font-weight: 700;
  letter-spacing: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

@media screen and (max-width: 991.98px) {
  .hide-below-992 {
    display: none !important;
  }
}

@media screen and (max-width: 1023.98px) {
  .hide-below-1024 {
    display: none !important;
  }
}

@media screen and (max-width: 1119.98px) {
  .hide-below-1200 {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .hide-above-992 {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .hide-above-1024 {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .hide-above-1200 {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .d-mt-0 {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .d-mb-0 {
    margin-bottom: 0 !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.container {
  padding-left: var(--theme-gutter);
  padding-right: var(--theme-gutter);
}
@media screen and (min-width: 1280px) {
  .container {
    max-width: var(--theme-container);
  }
}
.container .row {
  margin-left: calc(var(--theme-gutter) * -1);
  margin-right: calc(var(--theme-gutter) * -1);
}
.container .row .col {
  padding-left: var(--theme-gutter);
  padding-right: var(--theme-gutter);
}
@media screen and (min-width: 1200px) {
  .container.wide {
    max-width: 100%;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}
@media screen and (min-width: 1360px) {
  .container.wide {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
}

.content-wrapper {
  position: relative;
}
@media screen and (min-width: 1024px) {
  .content-wrapper.sidebar-right .primary-column {
    padding-right: 1.875rem;
  }
}
@media screen and (min-width: 1024px) {
  .content-wrapper.sidebar-left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 1024px) {
  .content-wrapper.sidebar-left .primary-column {
    padding-left: 1.875rem;
  }
}

.column {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.column.start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media screen and (min-width: 992px) {
  .column.d-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.column.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (min-width: 992px) {
  .column.d-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.column.right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .column.d-right {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.column.align-top {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.column.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.column.align-bottom {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.site-scroll {
  position: relative;
  width: 100%;
  height: 100%;
}

.site-brand {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.site-brand a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: currentColor;
  text-decoration: none;
}
.site-brand .logo-text {
  font-size: 2rem;
  font-weight: 700;
}

.badge.large {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  min-height: 1.25rem;
  line-height: 0;
  padding: 0.125rem 0.625rem;
}
.badge.theme-primary {
  background-color: var(--color-primary);
}
.badge.theme-secondary {
  background-color: var(--color-secondary);
}
.badge.theme-success {
  background-color: var(--color-success);
}
.badge.theme-danger {
  background-color: var(--color-danger);
}
.badge.theme-warning {
  background-color: var(--color-warning);
}

.partdo-tooltip {
  font-family: var(--theme-primary-font);
  font-size: 0.75rem;
  font-weight: 500;
  --bs-tooltip-bg: var(--color-text-primary);
  --bs-tooltip-opacity: 1;
}
.partdo-tooltip.arrow-hide .tooltip-arrow {
  display: none !important;
}
.partdo-tooltip.white {
  --bs-tooltip-color: #212529;
  --bs-tooltip-bg: #fff;
}
.partdo-tooltip.white .tooltip-inner {
  border: 1px solid var(--color-info-light);
  -webkit-box-shadow: 0 4px 6px -1px rgba(33, 37, 41, 0.1), 0 2px 4px -1px rgba(33, 37, 41, 0.06);
          box-shadow: 0 4px 6px -1px rgba(33, 37, 41, 0.1), 0 2px 4px -1px rgba(33, 37, 41, 0.06);
}

.site-close a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.875rem;
  width: 2.125rem;
  height: 2.125rem;
  color: var(--color-text-primary);
  background-color: var(--color-info-light);
  border-radius: var(--theme-theme-radius-small);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-close a:hover {
  background-color: var(--color-info);
}
.site-close a:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.klbth-modal-holder {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: initial;
  line-height: initial;
  white-space: initial;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  color: var(--color-text-primary);
  cursor: initial;
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  z-index: 1050;
}
.klbth-modal-holder .klbth-modal-inner {
  max-width: 36.25rem;
  width: 100%;
  background-color: var(--color-body);
  border-radius: var(--theme-theme-radius-large);
  opacity: 0;
  -webkit-transform: translateY(-0.9375rem);
          transform: translateY(-0.9375rem);
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
}
.klbth-modal-holder .klbth-modal-inner.size--sm {
  max-width: 32.5rem;
}
.klbth-modal-holder .klbth-modal-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(33, 37, 41, 0.5);
}
.klbth-modal-holder .klbth-modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1.25rem 1.25rem 0.625rem;
}
@media screen and (min-width: 992px) {
  .klbth-modal-holder .klbth-modal-header {
    padding: 1.25rem 1.875rem 0.625rem;
  }
}
.klbth-modal-holder .klbth-modal-header .entry-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0;
}
.klbth-modal-holder .klbth-modal-header .site-close {
  margin-left: auto;
}
.klbth-modal-holder .klbth-modal-body {
  padding: 0.9375rem 1.25rem 0.625rem;
}
@media screen and (min-width: 992px) {
  .klbth-modal-holder .klbth-modal-body {
    padding: 0.9375rem 1.875rem 0.9375rem;
  }
}

.entry-description {
  font-size: 0.875rem;
  line-height: 1.4;
}
.entry-description p {
  line-height: inherit;
}

.service-search-modal img {
  display: inherit;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
}
.service-search-modal .form-column {
  margin-bottom: 0.625rem;
}
.service-search-modal .form-column:last-child {
  margin-top: 1.25rem;
}
.service-search-modal .form-column button {
  height: 2.875rem;
}
.service-search-modal .service-description {
  font-size: 0.8125rem;
  text-align: center;
  margin-top: 0.5rem;
  color: var(--color-text-description);
}
.service-search-modal .service-description p {
  margin-bottom: 0.5rem;
}

.site-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  z-index: 999;
}

.klbth-loader {
  position: absolute;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99999px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-bottom-color: transparent;
  border-left-color: transparent;
  -webkit-animation: 0.45s linear 0s infinite normal none running animation-b7n1on;
          animation: 0.45s linear 0s infinite normal none running animation-b7n1on;
}
.klbth-loader.color-primary {
  border-top-color: var(--color-primary);
  border-right-color: var(--color-primary);
}
.klbth-loader.color-secondary {
  border-top-color: var(--color-secondary);
  border-right-color: var(--color-secondary);
}
.klbth-loader.border-xs {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
}
.klbth-loader.border-sm {
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
}
.klbth-loader.border-md {
  border-top-width: 3px;
  border-right-width: 3px;
  border-bottom-width: 3px;
  border-left-width: 3px;
}
.klbth-loader.border-lg {
  border-top-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
}
.klbth-loader.border-xlg {
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 5px;
}
.klbth-loader.size-xs {
  width: 0.75rem;
  height: 0.75rem;
}
.klbth-loader.size-sm {
  width: 1.875rem;
  height: 1.875rem;
}
.klbth-loader.size-md {
  width: 2.5rem;
  height: 2.5rem;
}
.klbth-loader.size-lg {
  width: 3.125rem;
  height: 3.125rem;
}
.klbth-loader.size-xlg {
  width: 3.875rem;
  height: 3.875rem;
}

.entry-media {
  border-radius: var(--theme-theme-radius-base);
  overflow: hidden;
}

.font-xs {
  font-size: 0.75rem;
  line-height: normal;
}

.font-sm {
  font-size: 0.8125rem;
  line-height: normal;
}

.font-md {
  font-size: 0.875rem;
  line-height: normal;
}

.font-lg {
  font-size: 0.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-lg {
    font-size: 0.9375rem;
    line-height: normal;
  }
}

.font-xlg {
  font-size: 0.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-xlg {
    font-size: 1rem;
    line-height: 1.375;
  }
}

.font-2xlg {
  font-size: 0.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-2xlg {
    font-size: 1.125rem;
    line-height: 1.3333333333;
  }
}

.font-3xlg {
  font-size: 1rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-3xlg {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.font-banner-xs {
  font-size: 1.25rem;
  line-height: normal;
}

.font-banner-xsm {
  font-size: 1.25rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-banner-xsm {
    font-size: 1.375rem;
    line-height: normal;
  }
}

.font-banner-sm {
  font-size: 1.25rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-banner-sm {
    font-size: 1.5rem;
    line-height: normal;
  }
}

.font-banner-md {
  font-size: 1.25rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-banner-md {
    font-size: 1.625rem;
    line-height: normal;
  }
}

.font-banner-lg {
  font-size: 1.25rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-banner-lg {
    font-size: 1.75rem;
    line-height: normal;
  }
}

.font-banner-xlg {
  font-size: 1.25rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-banner-xlg {
    font-size: 1.875rem;
    line-height: normal;
  }
}

.font-banner-2xlg {
  font-size: 1.25rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-banner-2xlg {
    font-size: 2rem;
    line-height: normal;
  }
}

.font-large-xs {
  font-size: 1.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-large-xs {
    font-size: 2.25rem;
    line-height: normal;
  }
}

.font-large-sm {
  font-size: 1.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-large-sm {
    font-size: 2.625rem;
    line-height: normal;
  }
}

.font-large-md {
  font-size: 1.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-large-md {
    font-size: 3rem;
    line-height: normal;
  }
}

.font-large-lg {
  font-size: 1.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-large-lg {
    font-size: 3.5rem;
    line-height: normal;
  }
}

.font-large-xlg {
  font-size: 1.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-large-xlg {
    font-size: 4.25rem;
    line-height: normal;
  }
}

.font-large-2xlg {
  font-size: 1.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .font-large-2xlg {
    font-size: 4.75rem;
    line-height: normal;
  }
}

.weight-100 {
  font-weight: 100;
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.weight-900 {
  font-weight: 900;
}

@media screen and (max-width: 575.98px) {
  .overlay-10-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 575.98px) {
  .overlay-15-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (max-width: 575.98px) {
  .overlay-20-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 575.98px) {
  .overlay-25-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@media screen and (max-width: 575.98px) {
  .overlay-30-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 575.98px) {
  .overlay-35-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

@media screen and (max-width: 575.98px) {
  .overlay-40-dark-max576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media screen and (min-width: 576px) {
  .overlay-1-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 576px) {
  .overlay-15-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: 576px) {
  .overlay-20-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 576px) {
  .overlay-25-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@media screen and (min-width: 576px) {
  .overlay-30-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 576px) {
  .overlay-35-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

@media screen and (min-width: 576px) {
  .overlay-40-dark-min576::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-10-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-15-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-20-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-25-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-30-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-35-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

@media screen and (max-width: 767.98px) {
  .overlay-40-dark-max768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media screen and (min-width: 768px) {
  .overlay-10-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 768px) {
  .overlay-15-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: 768px) {
  .overlay-20-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 768px) {
  .overlay-25-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@media screen and (min-width: 768px) {
  .overlay-30-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 768px) {
  .overlay-35-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

@media screen and (min-width: 768px) {
  .overlay-40-dark-min768::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-10-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-15-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-20-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-25-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-30-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-35-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

@media screen and (max-width: 991.98px) {
  .overlay-40-dark-max992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media screen and (min-width: 992px) {
  .overlay-10-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 992px) {
  .overlay-15-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: 992px) {
  .overlay-20-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (min-width: 992px) {
  .overlay-25-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}

@media screen and (min-width: 992px) {
  .overlay-30-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@media screen and (min-width: 992px) {
  .overlay-35-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }
}

@media screen and (min-width: 992px) {
  .overlay-40-dark-min992::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.link-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
}

.entry-subtitle {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: 500;
}
@media screen and (min-width: 992px) {
  .entry-subtitle {
    font-size: 1rem;
    line-height: normal;
  }
}

.entry-content {
  font-size: 0.875rem;
  line-height: normal;
}
@media screen and (min-width: 992px) {
  .entry-content {
    font-size: 1rem;
    line-height: normal;
  }
}

.kblth-countdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.9375rem;
}
.kblth-countdown .separator {
  font-size: 80%;
  font-weight: 600;
  margin: 0 4px;
}
.kblth-countdown .count-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 600;
  width: 1.875rem;
  height: 1.875rem;
  background-color: var(--color-info);
  border-radius: var(--theme-theme-radius-small);
}

.klbth-border.line {
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--color-info-dark);
}

.banner-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.625rem;
}
@media screen and (min-width: 768px) {
  .banner-group {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 0;
  }
  .banner-group .klbth-banner {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .banner-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .banner-group .klbth-banner {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .banner-group.no-gutter {
    gap: 0;
  }
  .banner-group.no-gutter .klbth-banner:first-child .entry-media {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .banner-group.no-gutter .klbth-banner:last-child .entry-media {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .banner-group.no-gutter .klbth-banner + .klbth-banner:not(:last-child) .entry-media {
    border-radius: 0;
  }
}

.iconbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.iconbox .iconbox-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.75rem;
  line-height: 1;
  color: var(--color-secondary);
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.5rem;
          flex: 0 0 2.5rem;
  max-width: 2.5rem;
  margin-right: 0.9375rem;
}
.iconbox .iconbox-detail .entry-title {
  font-size: 0.875rem;
  font-weight: 600;
}
.iconbox .iconbox-detail .entry-description {
  font-size: 0.75rem;
  color: var(--color-text-description);
}
.iconbox .iconbox-detail .entry-description p {
  margin-bottom: 0;
}
.iconbox.large .iconbox-icon {
  font-size: 2.875rem;
  -webkit-box-flex: 3.5rem;
      -ms-flex: 3.5rem;
          flex: 3.5rem;
  max-width: 3.5rem;
}
@media screen and (min-width: 768px) {
  .iconbox.large .iconbox-icon {
    -webkit-box-flex: 5.375rem;
        -ms-flex: 5.375rem;
            flex: 5.375rem;
    max-width: 5.375rem;
  }
}
.iconbox.large .iconbox-detail .entry-title {
  font-size: 1rem;
}
.iconbox.large .iconbox-detail .entry-description {
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.modal-active .header-topbar, .modal-active .header-sub {
  z-index: 8 !important;
}

.page-container {
  margin-top: 1.5rem;
}
@media screen and (min-width: 992px) {
  .page-container {
    margin-top: 2.625rem;
  }
}

.site-pagination, .woocommerce-pagination {
  margin-top: 1.875rem;
}
@media screen and (min-width: 992px) {
  .site-pagination, .woocommerce-pagination {
    margin-top: 3rem;
  }
}
.site-pagination ul, .woocommerce-pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 3px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-pagination ul li > *, .woocommerce-pagination ul li > * {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.875rem;
  font-weight: 500;
  width: 2rem;
  height: 2rem;
  color: var(--color-text-primary);
  background-color: var(--color-info-light);
  border-radius: var(--theme-theme-radius-xsmall);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
@media screen and (min-width: 992px) {
  .site-pagination ul li > *, .woocommerce-pagination ul li > * {
    width: 2.25rem;
    height: 2.25rem;
  }
}
.site-pagination ul li > *:not(.current):hover, .woocommerce-pagination ul li > *:not(.current):hover {
  background-color: var(--color-info);
}
.site-pagination ul li > *.dots, .woocommerce-pagination ul li > *.dots {
  font-size: 1.25rem;
  background-color: transparent !important;
  padding-bottom: 0.625rem;
}
.site-pagination ul li > *.current, .woocommerce-pagination ul li > *.current {
  color: #fff;
  background-color: var(--color-primary);
}
.site-pagination ul li > *.prev, .site-pagination ul li > *.next, .woocommerce-pagination ul li > *.prev, .woocommerce-pagination ul li > *.next {
  font-size: 1.125rem;
  background-color: transparent !important;
}
.site-pagination ul li > *.prev, .woocommerce-pagination ul li > *.prev {
  margin-right: 0.625rem;
}
.site-pagination ul li > *.next, .woocommerce-pagination ul li > *.next {
  margin-left: 0.625rem;
}

.entry-page-header {
  position: relative;
}
.entry-page-header > .container {
  position: relative;
  z-index: 2;
  color: #fff;
}
.entry-page-header .entry-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.entry-page-header .entry-image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.entry-page-header .entry-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.entry-page-header .page-header-content {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 992px) {
  .entry-page-header .page-header-content {
    padding-top: 10rem;
    padding-bottom: 17.5rem;
  }
}
@media screen and (min-width: 992px) {
  .entry-page-header .page-header-content .entry-title {
    font-size: 3.75rem;
  }
}
.entry-page-header .page-header-content .entry-description {
  font-size: 0.875rem;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .entry-page-header .page-header-content .entry-description {
    font-size: 0.9375rem;
    line-height: 1.4375rem;
  }
}
.entry-page-header .page-header-content .entry-description p {
  margin-bottom: 0;
}
.entry-page-header + .white-box {
  position: relative;
  margin-top: -5rem;
  z-index: 3;
}
@media screen and (min-width: 992px) {
  .entry-page-header + .white-box::before {
    content: "";
    position: absolute;
    top: -80px;
    left: -80px;
    right: -80px;
    height: 200px;
    background-color: var(--color-body);
    border-top-left-radius: var(--theme-theme-radius-large);
    border-top-right-radius: var(--theme-theme-radius-large);
  }
}
.entry-page-header + .white-box > * {
  position: relative;
  z-index: 1;
}

.head-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (min-width: 992px) {
  .head-block {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.head-block > span {
  font-size: 2.5rem;
  font-weight: 700;
  color: #F0F2F4;
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 992px) {
  .head-block > span {
    font-size: 5.625rem;
    line-height: 6rem;
    margin-bottom: 0;
    margin-right: 1.25rem;
  }
}
.head-block .head-content .entry-title {
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .head-block .head-content .entry-title {
    font-size: 2.25rem;
    line-height: normal;
  }
}
.head-block .head-content .entry-subtitle {
  font-size: 0.875rem;
  color: var(--color-text-description);
  margin-bottom: 0.375rem;
}

@media screen and (min-width: 992px) {
  .title-block .entry-title {
    font-size: 2.25rem;
    line-height: normal;
  }
}

.description-block {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  color: var(--color-text-description);
}

.contact-addresses {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.25rem;
}
@media screen and (min-width: 992px) {
  .contact-addresses {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.contact-addresses .address-item {
  font-size: 0.8125rem;
  line-height: 1.7692307692;
}
@media screen and (min-width: 992px) {
  .contact-addresses .address-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 180px;
            flex: 0 0 180px;
    max-width: 180px;
  }
}
.contact-addresses .address-item > span {
  font-weight: 500;
  color: var(--color-text-light);
}

.contact-iconboxes .iconbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.contact-iconboxes .iconbox + .iconbox {
  margin-top: 1.875rem;
}
@media screen and (min-width: 992px) {
  .contact-iconboxes .iconbox + .iconbox {
    margin-top: 2.5rem;
  }
}
.contact-iconboxes .iconbox .icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.375rem;
          flex: 0 0 2.375rem;
  max-width: 2.375rem;
  height: 2.375rem;
  color: var(--color-text-description);
  border: 1px solid var(--color-info-dark);
  border-radius: 50%;
  margin-right: 1.25rem;
}
.contact-iconboxes .iconbox .detail > span {
  display: block;
  font-size: 0.8125rem;
  font-weight: 600;
  color: var(--color-text-light);
}
.contact-iconboxes .iconbox .detail > p {
  margin-bottom: 0;
}
.contact-iconboxes .iconbox .detail > p.phone {
  font-size: 1.25rem;
  font-weight: 600;
}
.contact-iconboxes .iconbox .detail > a {
  font-size: 0.8125rem;
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-danger);
}

.contact-social > span {
  display: block;
  font-size: 0.8125rem;
  font-weight: 500;
  color: var(--color-text-light);
  margin-bottom: 0.625rem;
}

.contact-form-wrapper .wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 0.75rem;
}

.quickview-product {
  border-radius: var(--theme-theme-radius-small);
}
@media screen and (min-width: 992px) {
  .quickview-product {
    border-radius: var(--theme-theme-radius-large);
  }
}
.quickview-product .quickview-product-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.quickview-product .product {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
@media screen and (min-width: 992px) {
  .quickview-product .product {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.quickview-product .product .cell {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .quickview-product .product .cell {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 49%;
            flex: 0 0 49%;
    max-width: 49%;
  }
  .quickview-product .product .cell.product-gallery {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 420px;
            flex: 0 0 420px;
    max-width: 420px;
  }
}
.quickview-product .product .product-gallery {
  position: relative;
}
@media screen and (min-width: 992px) {
  .quickview-product .product .product-title {
    font-size: 2.125rem;
  }
}
.quickview-product .mfp-close {
  background-color: var(--color-info-dark) !important;
}
.quickview-product .mfp-close::before {
  content: "\ea1c";
}
.quickview-product.loaded .klbth-loader {
  opacity: 0;
  visibility: hidden;
}
.quickview-product.loaded .product {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.gdpr-content {
  position: fixed;
  width: 100%;
  bottom: 0;
  color: var(--color-body);
  background-color: var(--color-text-primary);
  padding: 0.9375rem;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  will-change: opacity, transform;
  z-index: 999;
}
.gdpr-content .gdpr-inner {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .gdpr-content .gdpr-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.gdpr-content .gdpr-inner p {
  font-size: 0.8125rem;
  font-weight: 500;
}
@media screen and (min-width: 992px) {
  .gdpr-content .gdpr-inner p {
    padding-right: 0.625rem;
    margin-bottom: 0;
  }
}
.gdpr-content .gdpr-inner a {
  font-size: 0.875rem;
  height: 2.25rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.categories-holder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}
.categories-holder .categories-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 4.25rem;
}
.categories-holder ul {
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.categories-holder ul li + li {
  border-top: 1px solid var(--color-info);
}
.categories-holder ul li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: currentColor;
  text-decoration: none;
  padding: 0.625rem 0.9375rem;
}
.categories-holder ul li a .count {
  font-size: 0.75rem;
  margin-left: auto;
  padding-left: 0.75rem;
  opacity: 0.4;
}

.mobile-bottom-menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  color: var(--color-text-description);
  background-color: var(--color-body);
  border-top: 1px solid var(--color-info-dark);
  padding: 0.625rem 1.25rem;
  z-index: 999;
}
@media screen and (min-width: 768px) {
  .mobile-bottom-menu {
    display: none;
  }
}
.mobile-bottom-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}
.mobile-bottom-menu ul li {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}
.mobile-bottom-menu ul li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.5625rem;
  font-weight: 600;
  text-transform: uppercase;
  color: currentColor;
  text-decoration: none;
}
.mobile-bottom-menu ul li a i {
  font-size: 1.375rem;
}
.mobile-bottom-menu ul li.active > a {
  color: var(--color-primary);
}

.page-not-found {
  text-align: center;
  max-width: 47.9375rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .page-not-found {
    padding-top: 10rem;
    padding-bottom: 6.875rem;
  }
}
.page-not-found .error-image {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 576px) {
  .page-not-found .error-image {
    max-width: 26.25rem;
    margin-bottom: 2.75rem;
  }
}
.page-not-found .entry-subtitle {
  font-size: 1.5rem;
  font-weight: 700;
}
@media screen and (min-width: 992px) {
  .page-not-found .entry-subtitle {
    font-size: 2.5rem;
  }
}
.page-not-found .entry-description {
  margin-bottom: 1.875rem;
}

body.categories-active .categories-holder {
  opacity: 1;
  visibility: visible;
}
body.categories-active .mobile-bottom-menu .categories-button::before, body.categories-active .mobile-bottom-menu .categories-button:after {
  content: "";
  position: absolute;
  width: 1.375rem;
  height: 1px;
  top: 1.5rem;
  background-color: currentColor;
}
body.categories-active .mobile-bottom-menu .categories-button::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
body.categories-active .mobile-bottom-menu .categories-button::after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
body.categories-active .mobile-bottom-menu .categories-button i {
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .top-margin {
    margin-top: -150px;
  }
}

/*------------------------------------ 
    05 - Plugins 
------------------------------------*/
/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 10px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 10px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 6px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 6px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
/* Slick Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.select2-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fff), to(#eee));
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eee), to(#ccc));
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(50%, #eee));
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eee), to(#fff));
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
} /* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.white-popup {
  position: relative;
  background: #FFF;
  padding: 1.25rem;
  width: auto;
  max-width: 64rem;
  margin: 1.25rem auto;
  border-radius: getSize(border-radius);
}
@media screen and (min-width: 1024px) {
  .white-popup {
    padding: 2.5rem;
  }
}

.mfp-close {
  font-size: 0;
  opacity: 1 !important;
  background-color: var(--color-desc) !important;
  color: #fff !important;
  top: 0.625rem !important;
  right: 0.625rem !important;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 38px;
}
@media screen and (min-width: 1024px) {
  .mfp-close {
    top: 0.9375rem !important;
    right: 0.9375rem !important;
  }
}

.mfp-close:before {
  font-family: "klbtheme";
  font-size: 15px;
  content: "\e845";
}

/*------------------------------------ 
    06 - Form 
------------------------------------*/
input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week], textarea, select, .form-control, .form-select {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: var(--theme-form-font-size);
  font-weight: var(--theme-form-font-weight);
  width: 100%;
  height: var(--theme-form-height);
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  color: var(--color-text-primary);
  border: 1px solid var(--color-form-border);
  -webkit-box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.09);
          box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.09);
  border-radius: var(--theme-form-radius);
}
input[type=color]::-webkit-input-placeholder, input[type=date]::-webkit-input-placeholder, input[type=datetime-local]::-webkit-input-placeholder, input[type=datetime]::-webkit-input-placeholder, input[type=email]::-webkit-input-placeholder, input[type=month]::-webkit-input-placeholder, input[type=number]::-webkit-input-placeholder, input[type=password]::-webkit-input-placeholder, input[type=search]::-webkit-input-placeholder, input[type=tel]::-webkit-input-placeholder, input[type=text]::-webkit-input-placeholder, input[type=time]::-webkit-input-placeholder, input[type=url]::-webkit-input-placeholder, input[type=week]::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder, .form-control::-webkit-input-placeholder, .form-select::-webkit-input-placeholder {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1;
  color: var(--color-form-placeholder);
}
input[type=color]::-moz-placeholder, input[type=date]::-moz-placeholder, input[type=datetime-local]::-moz-placeholder, input[type=datetime]::-moz-placeholder, input[type=email]::-moz-placeholder, input[type=month]::-moz-placeholder, input[type=number]::-moz-placeholder, input[type=password]::-moz-placeholder, input[type=search]::-moz-placeholder, input[type=tel]::-moz-placeholder, input[type=text]::-moz-placeholder, input[type=time]::-moz-placeholder, input[type=url]::-moz-placeholder, input[type=week]::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder, .form-control::-moz-placeholder, .form-select::-moz-placeholder {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1;
  color: var(--color-form-placeholder);
}
input[type=color]:-ms-input-placeholder, input[type=date]:-ms-input-placeholder, input[type=datetime-local]:-ms-input-placeholder, input[type=datetime]:-ms-input-placeholder, input[type=email]:-ms-input-placeholder, input[type=month]:-ms-input-placeholder, input[type=number]:-ms-input-placeholder, input[type=password]:-ms-input-placeholder, input[type=search]:-ms-input-placeholder, input[type=tel]:-ms-input-placeholder, input[type=text]:-ms-input-placeholder, input[type=time]:-ms-input-placeholder, input[type=url]:-ms-input-placeholder, input[type=week]:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder, .form-control:-ms-input-placeholder, .form-select:-ms-input-placeholder {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1;
  color: var(--color-form-placeholder);
}
input[type=color]::-ms-input-placeholder, input[type=date]::-ms-input-placeholder, input[type=datetime-local]::-ms-input-placeholder, input[type=datetime]::-ms-input-placeholder, input[type=email]::-ms-input-placeholder, input[type=month]::-ms-input-placeholder, input[type=number]::-ms-input-placeholder, input[type=password]::-ms-input-placeholder, input[type=search]::-ms-input-placeholder, input[type=tel]::-ms-input-placeholder, input[type=text]::-ms-input-placeholder, input[type=time]::-ms-input-placeholder, input[type=url]::-ms-input-placeholder, input[type=week]::-ms-input-placeholder, textarea::-ms-input-placeholder, select::-ms-input-placeholder, .form-control::-ms-input-placeholder, .form-select::-ms-input-placeholder {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1;
  color: var(--color-form-placeholder);
}
input[type=color]::placeholder, input[type=date]::placeholder, input[type=datetime-local]::placeholder, input[type=datetime]::placeholder, input[type=email]::placeholder, input[type=month]::placeholder, input[type=number]::placeholder, input[type=password]::placeholder, input[type=search]::placeholder, input[type=tel]::placeholder, input[type=text]::placeholder, input[type=time]::placeholder, input[type=url]::placeholder, input[type=week]::placeholder, textarea::placeholder, select::placeholder, .form-control::placeholder, .form-select::placeholder {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1;
  color: var(--color-form-placeholder);
}
input[type=color]:focus, input[type=color]:active, input[type=date]:focus, input[type=date]:active, input[type=datetime-local]:focus, input[type=datetime-local]:active, input[type=datetime]:focus, input[type=datetime]:active, input[type=email]:focus, input[type=email]:active, input[type=month]:focus, input[type=month]:active, input[type=number]:focus, input[type=number]:active, input[type=password]:focus, input[type=password]:active, input[type=search]:focus, input[type=search]:active, input[type=tel]:focus, input[type=tel]:active, input[type=text]:focus, input[type=text]:active, input[type=time]:focus, input[type=time]:active, input[type=url]:focus, input[type=url]:active, input[type=week]:focus, input[type=week]:active, textarea:focus, textarea:active, select:focus, select:active, .form-control:focus, .form-control:active, .form-select:focus, .form-select:active {
  outline: none;
  border-color: var(--color-form-border);
  -webkit-box-shadow: 0 0 0 3px var(--color-form-active-shadow);
          box-shadow: 0 0 0 3px var(--color-form-active-shadow);
}

textarea {
  min-height: 150px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

label {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.select2.select2-container {
  width: 100% !important;
}
.select2 .select2-selection {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: var(--theme-form-height);
  color: var(--color-text-primary);
  border-color: var(--color-form-border);
  -webkit-box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.09);
          box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.09);
  border-radius: var(--theme-form-radius);
}
.select2 .select2-selection.select2-selection--single.select2-selection--clearable .select2-selection__clear {
  all: unset;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  margin-left: auto;
  margin-right: 2.5rem;
}
.select2 .select2-selection.select2-selection--single .select2-selection__rendered {
  font-size: var(--theme-form-font-size);
  font-weight: var(--theme-form-font-weight);
  padding-left: 0.9375rem;
  padding-right: 1.875rem;
}
.select2 .select2-selection.select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  font-size: 92%;
  line-height: 1;
  color: var(--color-form-placeholder);
}
.select2 .select2-selection.select2-selection--single .select2-selection__arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: transparent url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 9L12 15L18 9' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>") calc(100% - 8px) 13px no-repeat;
  background-size: 22px 16px;
  width: 1.875rem;
  height: 100%;
  top: 0;
}
.select2 .select2-selection.select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-dropdown {
  background-color: var(--color-body);
  border-color: var(--color-form-border);
  border-radius: var(--theme-form-radius);
}
.select2-dropdown .select2-search--dropdown {
  padding: 0;
  border-bottom: 1px solid var(--color-form-border);
}
.select2-dropdown .select2-search--dropdown input {
  width: 100%;
  height: 2.375rem;
  padding: 0 0.9375rem;
  border: 0 !important;
  border-radius: 0;
  background-color: var(--color-info-light);
}
.select2-dropdown .select2-search--dropdown input::-webkit-input-placeholder {
  font-weight: 400;
}
.select2-dropdown .select2-search--dropdown input::-moz-placeholder {
  font-weight: 400;
}
.select2-dropdown .select2-search--dropdown input:-ms-input-placeholder {
  font-weight: 400;
}
.select2-dropdown .select2-search--dropdown input::-ms-input-placeholder {
  font-weight: 400;
}
.select2-dropdown .select2-search--dropdown input::placeholder {
  font-weight: 400;
}
.select2-dropdown .select2-search--dropdown input:focus, .select2-dropdown .select2-search--dropdown input:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
.select2-dropdown .select2-results .select2-results__options .select2-results__option {
  font-size: 0.875rem;
  padding: 0.5rem 0.9375rem;
}
.select2-dropdown .select2-results .select2-results__options .select2-results__option.select2-results__option--highlighted {
  color: var(--color-primary);
  background-color: var(--color-primary-lighter);
}

button, .button, input[type=submit], .submit, .btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-family: var(--theme-primary-font);
  font-size: var(--theme-form-font-size);
  font-weight: 600;
  height: var(--theme-form-height);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: var(--theme-form-radius);
}
button:not(.link), .button:not(.link), input[type=submit]:not(.link), .submit:not(.link), .btn:not(.link) {
  color: var(--color-text-primary);
  background-color: var(--color-info);
}
button:not(.link):hover, .button:not(.link):hover, input[type=submit]:not(.link):hover, .submit:not(.link):hover, .btn:not(.link):hover {
  border-color: transparent;
  background-color: var(--color-info-dark);
}
button i, .button i, input[type=submit] i, .submit i, .btn i {
  font-size: 90%;
  margin-left: 0.625rem;
}
button.primary, button.wpcf7-submit, .button.primary, .button.wpcf7-submit, input[type=submit].primary, input[type=submit].wpcf7-submit, .submit.primary, .submit.wpcf7-submit, .btn.primary, .btn.wpcf7-submit {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
button.primary:hover, button.wpcf7-submit:hover, .button.primary:hover, .button.wpcf7-submit:hover, input[type=submit].primary:hover, input[type=submit].wpcf7-submit:hover, .submit.primary:hover, .submit.wpcf7-submit:hover, .btn.primary:hover, .btn.wpcf7-submit:hover {
  background-color: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
}
button.secondary, .button.secondary, input[type=submit].secondary, .submit.secondary, .btn.secondary {
  color: #fff;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
button.secondary:hover, .button.secondary:hover, input[type=submit].secondary:hover, .submit.secondary:hover, .btn.secondary:hover {
  background-color: var(--color-secondary-dark);
  border-color: var(--color-secondary-dark);
}
button.success, .button.success, input[type=submit].success, .submit.success, .btn.success {
  color: #fff;
  background-color: var(--color-success);
  border-color: var(--color-success);
}
button.success:hover, .button.success:hover, input[type=submit].success:hover, .submit.success:hover, .btn.success:hover {
  background-color: var(--color-success-dark);
  border-color: var(--color-success-dark);
}
button.danger, .button.danger, input[type=submit].danger, .submit.danger, .btn.danger {
  color: #fff;
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}
button.danger:hover, .button.danger:hover, input[type=submit].danger:hover, .submit.danger:hover, .btn.danger:hover {
  background-color: var(--color-danger-dark);
  border-color: var(--color-danger-dark);
}
button.link, .button.link, input[type=submit].link, .submit.link, .btn.link {
  font-size: 0.8125rem;
  height: auto;
  padding: 0;
  border: 0;
}
button.link i, .button.link i, input[type=submit].link i, .submit.link i, .btn.link i {
  font-size: 0.75rem;
}
button.link:hover, .button.link:hover, input[type=submit].link:hover, .submit.link:hover, .btn.link:hover {
  color: var(--color-primary);
}

label.form-label {
  display: block;
  font-size: 0.8125rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 0.9375rem;
}

.color-radio {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.color-radio label {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-bottom: 0;
}
.color-radio input {
  display: none;
}
.color-radio input:checked + label {
  border: 2px solid #fff;
  -webkit-box-shadow: 0 0 0 2px var(--color-text-description);
          box-shadow: 0 0 0 2px var(--color-text-description);
}

/*------------------------------------ 
    07 - Theme Menus 
------------------------------------*/
.klbth-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.klbth-menu li {
  white-space: nowrap;
}
.klbth-menu .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.klbth-menu-wrapper.horizontal .klbth-menu {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.klbth-menu-wrapper.horizontal .klbth-menu .sub-menu {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 100%;
  left: 0;
  list-style: none;
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  pointer-events: none;
  will-change: transform, opacity;
  -webkit-transition: all 0.23s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.23s cubic-bezier(0.28, 0.12, 0.22, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.klbth-menu-wrapper.horizontal .klbth-menu .menu-item-description {
  font-weight: 400;
}
.klbth-menu-wrapper.horizontal .klbth-menu li:not(.mega-menu) {
  position: relative;
}
.klbth-menu-wrapper.horizontal .klbth-menu li.mega-menu {
  position: static;
}
.klbth-menu-wrapper.horizontal .klbth-menu li a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.klbth-menu-wrapper.horizontal .klbth-menu .menu-item-has-children > a::after {
  content: "";
  font-family: "klbtheme";
}
.klbth-menu-wrapper.horizontal .klbth-menu .menu-item-has-children:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  pointer-events: auto;
}
.klbth-menu-wrapper.horizontal .klbth-menu > .menu-item-has-children > a::after {
  content: "\e9a2";
  font-size: 75%;
  margin-left: 0.4375rem;
}
.klbth-menu-wrapper.vertical .klbth-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.klbth-menu-wrapper .menu-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.125rem;
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.625rem;
}

/*------------------------------------ 
    08 - Header 
------------------------------------*/
.site-header {
  position: relative;
}
.site-header a {
  color: inherit;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header.shadow-enable {
  -webkit-box-shadow: 0 2px 3px rgba(33, 37, 41, 0.07);
          box-shadow: 0 2px 3px rgba(33, 37, 41, 0.07);
}
.site-header .header-border {
  position: relative;
  display: block;
  width: 100%;
  height: 6px;
  background-color: #EF233C;
}
.site-header .header-inner {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-color: inherit;
  width: 100%;
  z-index: 1;
}
.site-header .header-row {
  position: relative;
}
.site-header .header-row.border-top-full {
  border-top-width: 1px;
  border-top-style: solid;
}
.site-header .header-row.border-bottom-full {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.site-header .header-row.border-top-boxed .header-inner {
  border-top-width: 1px;
  border-top-style: solid;
}
.site-header .header-row.border-bottom-boxed .header-inner {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.site-header .klbth-menu-wrapper.topbar {
  height: 100%;
}
.site-header .klbth-menu-wrapper.topbar.shadow-enable .sub-menu {
  -webkit-box-shadow: 0 4px 6px -1px rgba(33, 37, 41, 0.1), 0 2px 4px -1px rgba(33, 37, 41, 0.06);
          box-shadow: 0 4px 6px -1px rgba(33, 37, 41, 0.1), 0 2px 4px -1px rgba(33, 37, 41, 0.06);
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu {
  height: 100%;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu > li {
  height: 100%;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu > li + li {
  margin-left: 1.25rem;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu > li > a {
  font-weight: 500;
  height: 100%;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu > li > a:hover {
  color: #EF233C;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu > li:hover > a {
  color: #EF233C;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu .sub-menu {
  border-color: #E9ECEF;
  background-color: #fff;
  border-radius: var(--theme-theme-radius-small);
  left: -1.3125rem;
  padding: 0.5rem 0;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu .sub-menu li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 0 0.3125rem;
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu .sub-menu li a {
  font-weight: 500;
  width: 100%;
  padding: 0.3125rem 0.9375rem;
  border-radius: var(--theme-theme-radius-small);
}
.site-header .klbth-menu-wrapper.topbar .klbth-menu .sub-menu li a:hover {
  color: #EF233C;
  background-color: #FFF4F6;
}
.site-header .klbth-menu-wrapper.primary {
  height: 100%;
  z-index: 101;
}
.site-header .klbth-menu-wrapper.primary .klbth-menu {
  position: static;
  height: 100%;
}
.site-header .klbth-menu-wrapper.primary .klbth-menu > .menu-item {
  height: 100%;
}
.site-header .klbth-menu-wrapper.primary .klbth-menu > .menu-item > a {
  font-family: "Krub", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #212529;
  height: 100%;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .klbth-menu-wrapper.primary .klbth-menu > .menu-item > a:hover {
  color: #EF233C;
}
.site-header .klbth-menu-wrapper.primary .klbth-menu > .menu-item:hover > a {
  color: #EF233C;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children > a::after {
  position: relative;
  font-size: 80%;
  content: "\e995";
  line-height: 1;
  margin-left: 0.5rem;
  top: -1px;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children:not(.mega-menu) .sub-menu {
  min-width: 13.75rem;
  left: -0.625rem;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children:not(.mega-menu) .sub-menu .menu-item-has-children > a::after {
  content: "\e99e";
  font-size: 85%;
  margin-left: auto;
  top: 0;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children:not(.mega-menu) .sub-menu .sub-menu {
  top: 0;
  left: 100%;
  margin-top: -0.725rem;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  padding: 1.5625rem 0.5rem;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu > .menu-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu > .menu-item > a {
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 1.25rem;
  pointer-events: none;
  color: #c8cdd3;
  margin-bottom: 0.3125rem;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu > .menu-item > a::after {
  display: none;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu > .menu-item > a:hover {
  color: #c8cdd3;
  background-color: transparent;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu > .menu-item:hover > a {
  color: #c8cdd3;
  background-color: transparent;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children.mega-menu > .sub-menu .sub-menu {
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  opacity: 1;
  visibility: visible;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children .sub-menu {
  background-color: #fff;
  border-radius: var(--theme-theme-radius-base);
  border-color: #E9ECEF;
  padding: 0.75rem 0;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children .sub-menu > .menu-item {
  padding: 0 0.5rem;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children .sub-menu .menu-item a {
  font-size: 0.8125rem;
  font-weight: 500;
  width: 100%;
  padding: 0.375rem 0.9375rem;
  border-radius: var(--theme-theme-radius-small);
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children .sub-menu .menu-item a:hover {
  color: #EF233C;
  background-color: #FFF4F6;
}
.site-header .klbth-menu-wrapper.primary .menu-item-has-children .sub-menu .menu-item:hover > a {
  color: #EF233C;
  background-color: #FFF4F6;
}
.site-header .klbth-menu-wrapper.primary.shadow-enable .sub-menu {
  -webkit-box-shadow: 0 20px 25px -5px rgba(33, 37, 41, 0.1), 0 10px 10px -5px rgba(33, 37, 41, 0.04);
          box-shadow: 0 20px 25px -5px rgba(33, 37, 41, 0.1), 0 10px 10px -5px rgba(33, 37, 41, 0.04);
}
.site-header .search-form-wrapper {
  width: 100%;
}
.site-header .search-form-wrapper .search-form {
  position: relative;
}
.site-header .search-form-wrapper .search-form .btn {
  all: unset;
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.5rem;
  color: #212529;
  width: 3.875rem;
  height: 3.125rem;
  top: 0;
  right: 0;
  cursor: pointer;
}
.site-header .search-form-wrapper .search-form .btn i {
  text-shadow: 0 0 0;
  -webkit-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}
.site-header .search-form-wrapper .search-form input {
  height: 3.125rem;
  color: #212529;
  border-color: #CED4DA;
  background-color: #fff;
  padding-left: 1.125rem;
  padding-right: 3.875rem;
}
.site-header .search-form-wrapper .search-form input::-webkit-input-placeholder {
  color: #ADB5BD;
}
.site-header .search-form-wrapper .search-form input::-moz-placeholder {
  color: #ADB5BD;
}
.site-header .search-form-wrapper .search-form input:-ms-input-placeholder {
  color: #ADB5BD;
}
.site-header .search-form-wrapper .search-form input::-ms-input-placeholder {
  color: #ADB5BD;
}
.site-header .search-form-wrapper .search-form input::placeholder {
  color: #ADB5BD;
}
.site-header .search-form-wrapper .search-form input:focus, .site-header .search-form-wrapper .search-form input:active {
  border-color: #EF233C;
  -webkit-box-shadow: 0 0 0 1px #EF233C;
          box-shadow: 0 0 0 1px #EF233C;
}
.site-header .search-form-wrapper .search-form input:focus + .btn, .site-header .search-form-wrapper .search-form input:active + .btn {
  color: #EF233C;
}
.site-header .quick-button {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  white-space: nowrap;
}
.site-header .quick-button + .quick-button {
  margin-left: 0.625rem;
}
.site-header .quick-button > *:not(.header-dropdown-wrapper) {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.5rem;
  line-height: 1;
  color: currentColor;
  min-width: 2.625rem;
  height: 2.625rem;
  cursor: pointer;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .quick-button.toggle-button .quick-button-inner {
  min-width: 2.375rem;
}
.site-header .quick-button.toggle-button .quick-button-inner .quick-icon {
  width: 100%;
}
.site-header .quick-button.custom-button .quick-button-inner {
  width: 2.875rem;
  height: 2.875rem;
}
.site-header .quick-button.custom-button .quick-button-inner .quick-icon {
  width: 2.875rem;
  background-color: #F1F3F5;
  border-radius: var(--theme-theme-radius-base);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .quick-button.custom-button:hover .quick-icon {
  background-color: #E9ECEF;
}
.site-header .quick-button.custom-button + .search-form-wrapper {
  margin-left: 0.625rem;
}
.site-header .quick-button .quick-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 2.625rem;
  height: 100%;
}
.site-header .quick-button .quick-icon + .quick-text {
  margin-left: 0.625rem;
}
.site-header .quick-button .quick-text {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.site-header .quick-button .quick-text .count-text {
  display: block;
  font-size: 0.6875rem;
  margin-bottom: 0.375rem;
}
.site-header .quick-button .quick-text .sub-text {
  font-size: 0.75rem;
}
.site-header .quick-button .quick-text .primary-text {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.375rem;
}
.site-header .quick-button .quick-text .price {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}
.site-header .quick-button .count {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.625rem;
  font-weight: 600;
  min-width: 1rem;
  height: 1rem;
  top: 0.25rem;
  right: 0;
  padding: 0 0.125rem;
  color: #fff;
  background-color: #EF233C;
  border-radius: 50%;
}
.site-header .quick-button .count a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  color: currentColor;
  pointer-events: none;
}
.site-header .quick-button .count .wishlist_products_counter_text {
  display: none;
}
.site-header .quick-button .count .wishlist_products_counter_number {
  display: block;
  font-size: 0.625rem;
}
.site-header .quick-button .arrow {
  position: relative;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-size: 0.6875rem;
  margin-left: 0.75rem;
  top: 13%;
}
.site-header .quick-button i.klbth-icon-search {
  font-size: 1.75rem;
  line-height: normal;
}
.site-header .quick-button i.klbth-icon-profile-round {
  position: relative;
  font-size: 1.875rem;
  top: 1px;
}
.site-header .quick-button i.klbth-icon-shopping-bag-large {
  font-size: 1.5625rem;
}
.site-header .quick-button i.klbth-icon-heart-round {
  position: relative;
  font-size: 1.8125rem;
  text-shadow: 0 0 0;
  top: -1px;
}
.site-header .quick-button .login-dropdown {
  position: absolute;
  font-size: inherit;
  min-width: 17.5rem;
  max-width: 17.5rem;
  height: auto;
  cursor: inherit;
  top: 100%;
  right: 0;
  background-color: #fff;
  border-radius: var(--theme-theme-radius-base);
  border: 1px solid #E9ECEF;
  -webkit-box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
          box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.site-header .quick-button .login-dropdown .login-dropdown-wrapper {
  width: 100%;
  padding: 1.25rem;
  text-align: center;
}
.site-header .quick-button .login-dropdown .login-dropdown-wrapper .login-text {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  white-space: initial;
}
.site-header .quick-button .login-dropdown .login-dropdown-wrapper a {
  width: 100%;
}
.site-header .quick-button .login-dropdown .login-dropdown-wrapper .new-customer {
  font-size: 0.875rem;
  margin-top: 0.9375rem;
}
.site-header .quick-button .login-dropdown .login-dropdown-wrapper .new-customer a {
  font-weight: 600;
  text-decoration: underline;
  color: var(--color-secondary);
}
.site-header .quick-button .cart-dropdown {
  position: absolute;
  font-size: inherit;
  min-width: 20rem;
  height: auto;
  cursor: inherit;
  top: 100%;
  right: 0;
  background-color: #fff;
  border-radius: var(--theme-theme-radius-base);
  border: 1px solid #E9ECEF;
  -webkit-box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
          box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper {
  width: 100%;
  padding: 1.25rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .woocommerce-mini-cart__total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0.9375rem;
  margin-bottom: 0.875rem;
  border-top: 1px solid var(--color-info);
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .woocommerce-mini-cart__total strong {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-text-description);
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .woocommerce-mini-cart__total span {
  font-weight: 700;
  margin-left: auto;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .woocommerce-mini-cart__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.375rem;
  margin-bottom: 0;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .woocommerce-mini-cart__buttons a {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 0.875rem;
  height: 2.5rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products {
  max-height: 242px;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products.ps--active-y {
  padding-right: 0.625rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .ps__rail-y {
  margin-bottom: 0;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product {
  position: relative;
  margin-bottom: 0.3125rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product + .product {
  padding-top: 0.75rem;
  border-top: 1px solid var(--color-info);
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product .thumbnail-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3.875rem;
          flex: 0 0 3.875rem;
  max-width: 3.875rem;
  margin-bottom: 0;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product .content-wrapper {
  padding-right: 1.25rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product .content-wrapper .product-title {
  white-space: initial;
  margin-bottom: 0.3125rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product .content-wrapper .price {
  font-size: 0.875rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product .remove_from_cart_button {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.625rem;
  font-weight: 600;
  text-shadow: 0 0 0;
  width: 1.0625rem;
  height: 1.0625rem;
  border: 1px solid var(--color-info-dark);
  border-radius: 50%;
  top: 0;
  right: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .products .product .remove_from_cart_button:hover {
  color: #fff;
  background-color: var(--color-danger);
  border-color: transparent;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .klbth-free-shipping {
  padding: 0.9375rem;
  margin-bottom: 0.75rem;
  background-color: var(--color-info-light);
  border-radius: var(--theme-theme-radius-base);
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .klbth-free-shipping .free-shipping-notice {
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .klbth-free-shipping .free-shipping-notice .woocommerce-Price-amount {
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 700;
  color: var(--color-danger);
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .klbth-free-shipping .shipping-progress {
  position: relative;
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: #fff;
  border-radius: 0.9375rem;
}
.site-header .quick-button .cart-dropdown .cart-dropdown-wrapper .klbth-free-shipping .shipping-progress span {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--color-danger);
  border-radius: 0.9375rem;
}
.site-header .quick-button .cart-dropdown .cart-empty {
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.site-header .quick-button .cart-dropdown .cart-empty .empty-icon {
  max-width: 4.125rem;
  margin: auto;
  color: var(--color-info-dark);
}
.site-header .quick-button .cart-dropdown .cart-empty .empty-icon svg {
  width: 100%;
  fill: currentColor;
}
.site-header .quick-button .cart-dropdown .cart-empty .empty-text {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.9375rem;
}
.site-header .quick-button.cart-button:hover .cart-dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.site-header .quick-button.login-button:hover .login-dropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.site-header .dropdown-cats {
  position: relative;
  width: 100%;
}
.site-header .dropdown-cats > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 3rem;
  color: #fff;
  background-color: #EF233C;
  border-top-left-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.site-header .dropdown-cats > a::after {
  display: none;
}
.site-header .dropdown-cats > a.show {
  cursor: pointer;
  pointer-events: none;
}
.site-header .dropdown-cats > a .text {
  font-size: 0.9375rem;
  font-weight: 500;
}
.site-header .dropdown-cats > a .icon {
  font-size: 0.8125rem;
  margin-left: auto;
  padding-left: 0.9375rem;
}
.site-header .dropdown-cats .dropdown-menu {
  width: 100%;
  padding: 0.625rem 0;
  border-color: var(--color-info);
  background-color: var(--color-body);
  border-radius: var(--theme-theme-radius-base);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: 1px 2px 5px rgba(134, 142, 150, 0.12);
          box-shadow: 1px 2px 5px rgba(134, 142, 150, 0.12);
  z-index: 9;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.8125rem;
  font-weight: 500;
  color: currentColor;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu a .menu-icon {
  margin-right: 0.9375rem;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu a:hover {
  color: var(--color-primary);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu .sub-menu {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 100%;
  padding: 0.9375rem 0.625rem;
  border: 1px solid var(--color-info);
  background-color: var(--color-body);
  border-radius: var(--theme-theme-radius-base);
  border-top-left-radius: 0;
  -webkit-box-shadow: 1px 2px 5px rgba(134, 142, 150, 0.12);
          box-shadow: 1px 2px 5px rgba(134, 142, 150, 0.12);
  -webkit-transform: translateX(-0.5rem);
          transform: translateX(-0.5rem);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.23s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.23s cubic-bezier(0.28, 0.12, 0.22, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu .sub-menu a {
  position: relative;
  padding: 0.5rem 1.25rem;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu .sub-menu a::after {
  font-family: "klbtheme";
  font-size: 0.625rem;
  content: "\e96f";
  color: var(--color-primary);
  margin-left: 0.75rem;
  -webkit-transform: translateX(-0.75rem);
          transform: translateX(-0.75rem);
  opacity: 0;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu .sub-menu a:hover::after {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu .sub-menu .menu-item-has-title {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.9375rem 1.25rem 0.625rem;
  opacity: 0.3;
  pointer-events: none;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item > a {
  min-height: 2.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  border-radius: var(--theme-theme-radius-small);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item > a:hover {
  background-color: var(--color-primary-lighter);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item > a .badge {
  margin-left: auto;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item.menu-item-has-children {
  position: static;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item.menu-item-has-children > a::after {
  font-family: "klbtheme";
  font-size: 75%;
  content: "\e9ab";
  margin-left: auto;
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item.menu-item-has-children:hover > a {
  color: var(--color-primary);
  background-color: var(--color-primary-lighter);
}
.site-header .dropdown-cats .dropdown-menu .klbth-menu > .menu-item.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.site-header .mega-items {
  height: 100%;
}
.site-header .mega-items .mega-item {
  height: 100%;
}
.site-header .mega-items .mega-item > a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Krub", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #212529;
  height: 100%;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .mega-items .mega-item > a::after {
  position: relative;
  font-family: "klbtheme";
  font-size: 75%;
  content: "\e995";
  line-height: 1;
  margin-left: 0.5rem;
}
.site-header .mega-items .mega-item > a .menu-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.site-header .mega-items .mega-item .sub-item {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #E9ECEF;
  border-radius: var(--theme-theme-radius-base);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  -webkit-box-shadow: 0 20px 25px -5px rgba(33, 37, 41, 0.1), 0 10px 10px -5px rgba(33, 37, 41, 0.04);
          box-shadow: 0 20px 25px -5px rgba(33, 37, 41, 0.1), 0 10px 10px -5px rgba(33, 37, 41, 0.04);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  pointer-events: none;
  will-change: transform, opacity;
  -webkit-transition: all 0.23s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.23s cubic-bezier(0.28, 0.12, 0.22, 1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 102;
}
.site-header .mega-items .mega-item:hover > a {
  color: #EF233C;
}
.site-header .mega-items .mega-item:hover > .sub-item {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.site-header .notice-button {
  margin-left: auto;
  padding-left: 0.625rem;
}
.site-header .notice-button .notice-link {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #EF233C;
}
.site-header .notice-button .notice-link .notice-icon {
  margin-right: 0.5rem;
}
.site-header .notice-button .notice-link .notice-text {
  font-family: "Krub", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.site-header .header-notify {
  color: #fff;
  background: linear-gradient(-45deg, #ed1c66, #6a23d5, #23a6d5, #23d5ab, #f03e3e, #e8890c, #e73c7e);
  background-size: 400% 400%;
  -webkit-animation: gradient 15s ease infinite;
          animation: gradient 15s ease infinite;
}
.site-header .header-notify .header-inner {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.site-header .header-notify .header-notify-messages ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-header .header-notify .header-notify-messages ul li {
  font-size: 0.8125rem;
  line-height: normal;
}
.site-header .header-notify .header-notify-messages ul li strong {
  font-weight: 600;
}
.site-header .header-notify .header-notify-messages ul li + li {
  margin-left: 0.625rem;
  padding-left: 0.625rem;
  border-left: 1px solid currentColor;
}
.site-header .header-notify .counter-text {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: 500;
  margin-right: 0.5rem;
}
.site-header .header-notify .klbth-countdown-wrapper .kblth-countdown {
  font-size: 0.8125rem;
  line-height: normal;
}
.site-header .header-notify .klbth-countdown-wrapper .separator {
  margin: 0 2px;
}
.site-header .header-notify .klbth-countdown-wrapper .count-item {
  width: 1.625rem;
  height: 1.5625rem;
  background-color: rgba(0, 0, 0, 0.2);
}
.site-header .header-topbar {
  font-size: 12px;
  color: #212529;
  background-color: #fff;
  border-color: #E9ECEF;
  z-index: 10;
}
.site-header .header-topbar .header-inner {
  height: 42px;
  border-color: #E9ECEF;
}
.site-header .header-topbar .header-inner > .column {
  height: 100%;
}
.site-header .header-topbar .header-notice p {
  font-size: inherit;
  margin-bottom: 0;
}
.site-header .header-topbar .header-notice p span {
  color: #868E96;
}
.site-header .header-topbar .header-notice p strong {
  font-weight: 600;
}
.site-header .header-topbar .header-notice p a {
  font-weight: 600;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.site-header .header-topbar .header-notice p a:hover {
  color: #EF233C;
}
.site-header .header-topbar .header-notice + .header-switcher {
  padding-left: 1.25rem;
  margin-left: 1.25rem;
  border-left: 1px solid #E9ECEF;
}
.site-header .header-topbar .header-switcher {
  height: 100%;
}
.site-header .header-topbar .header-switcher .klbth-menu > li > a {
  font-weight: 600;
}
.site-header .header-topbar .header-switcher .klbth-menu > li:last-child .sub-menu {
  left: auto !important;
  right: -0.9375rem;
}
.site-header .header-topbar .header-switcher .klbth-menu .menu-item-description {
  color: #868E96;
  margin-right: 0.3125rem;
  pointer-events: none;
}
.site-header .header-sub {
  font-size: 12px;
  color: #212529;
  background-color: #fff;
  border-color: #E9ECEF;
  z-index: 10;
}
.site-header .header-sub .header-inner {
  height: 48px;
  border-color: #E9ECEF;
}
.site-header .header-sub .header-inner > .column {
  height: 100%;
}
.site-header .header-sub .klbth-menu-wrapper.primary .klbth-menu > li:first-child > a {
  padding-left: 0.5rem;
}
.site-header .header-sub .klbth-menu-wrapper.primary .klbth-menu > li > a {
  font-size: 0.8125rem;
  line-height: normal;
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
}
.site-header .header-sub .header-switcher {
  height: 100%;
}
.site-header .header-sub .header-switcher .klbth-menu > li > a {
  font-weight: 600;
}
.site-header .header-sub .header-switcher .klbth-menu > li:last-child .sub-menu {
  left: auto !important;
  right: -0.9375rem;
}
.site-header .header-sub .header-switcher .klbth-menu .menu-item-description {
  color: #868E96;
  margin-right: 0.3125rem;
  pointer-events: none;
}
.site-header .header-main {
  color: #212529;
  background-color: #fff;
  z-index: 9;
}
.site-header .header-main.spacing {
  padding-top: 20px;
  padding-bottom: 20px;
}
.site-header .header-main.height .header-inner {
  height: 82px;
}
.site-header .header-main.height .header-inner .klbth-menu-wrapper .klbth-menu > li > a {
  height: 82px;
}
.site-header .header-main .site-brand img {
  height: 40px;
}
.site-header .header-main .site-brand + .klbth-menu-wrapper {
  margin-left: 1.875rem;
}
.site-header .header-main .column.center {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.site-header .header-main .toggle-button {
  margin-right: 1.25rem;
}
.site-header .header-nav {
  color: #212529;
  background-color: #fff;
  z-index: 8;
}
.site-header .header-nav .column {
  height: 3rem;
}
.site-header .header-nav .column.left:nth-child(1) {
  padding-right: 0.9375rem;
}
.site-header .header-nav .column.left:nth-child(2) {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.site-header .header-mobile {
  color: #212529;
  background-color: #fff;
}
.site-header .header-mobile .header-inner {
  height: 3.375rem;
}
.site-header .header-mobile .header-inner .column.center {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.site-header .header-mobile .header-inner .quick-button.toggle-button {
  margin-right: 0;
}
.site-header .header-mobile .site-brand img {
  height: 36px;
}
.site-header .header-mobile .site-brand span {
  font-size: 1.75rem;
}
@media screen and (min-width: 992px) {
  .site-header.header-type-2 .quick-button + .quick-button {
    margin-left: 0.3125rem;
  }
}

/*------------------------------------ 
    09 - Drawer 
------------------------------------*/
.site-drawer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #212529;
  background-color: #fff;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  will-change: transform;
  z-index: 1001;
}
@media screen and (min-width: 320px) {
  .site-drawer {
    max-width: 21.25rem;
  }
}
.site-drawer-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0.625rem 1.25rem;
}
@media screen and (min-width: 576px) {
  .site-drawer-row {
    padding: 0.75rem 1.5rem;
  }
}
.site-drawer-row.site-drawer-header {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (min-width: 576px) {
  .site-drawer-row.site-drawer-header {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}
.site-drawer-row.site-drawer-header .site-close a {
  font-size: 0.8125rem;
  width: 1.875rem;
  height: 1.875rem;
}
.site-drawer-row.site-drawer-body {
  border-top: 1px solid getDrawer(border);
}
.site-drawer-row.site-drawer-footer {
  padding-top: 1.875rem;
}
@media screen and (min-width: 576px) {
  .site-drawer-row.site-drawer-footer {
    padding-top: 2.5rem;
  }
}
.site-drawer a {
  color: currentColor;
}
.site-drawer .site-brand span {
  font-size: 1.875rem;
  font-weight: 700;
}
.site-drawer .site-brand img {
  height: 36px;
}
.site-drawer .drawer-heading {
  font-size: 0.6875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0.9375rem;
  margin-bottom: 0.625rem;
  opacity: 0.3;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  font-weight: 600;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item a .badge {
  margin-left: auto;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item.menu-item-has-title {
  display: none;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item .menu-dropdown {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 1.75rem;
  height: 100%;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  cursor: pointer;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item .menu-dropdown svg {
  width: 1rem;
  pointer-events: none;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item .sub-menu {
  position: relative;
  width: 100%;
  height: 0;
  padding-left: 0.9375rem;
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
.site-drawer .klbth-menu-wrapper .klbth-menu .menu-item .sub-menu a {
  font-size: 87.5%;
  font-weight: 500;
  padding-top: 0.325rem;
  padding-bottom: 0.325rem;
}
.site-drawer .klbth-menu-wrapper .klbth-menu > .menu-item + .menu-item {
  border-top: 1px solid #E9ECEF;
}
.site-drawer .klbth-menu-wrapper .klbth-menu > .menu-item > .sub-menu > .menu-item:last-child > a {
  padding-bottom: 0.625rem;
}
.site-drawer .klbth-menu-wrapper + .drawer-heading {
  margin-top: 1.875rem;
}
.site-drawer .drawer-contacts ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-drawer .drawer-contacts ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.site-drawer .drawer-contacts ul li + li {
  margin-top: 1.25rem;
}
.site-drawer .drawer-contacts ul li .contact-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.125rem;
  line-height: 1;
  width: 1.25rem;
  margin-right: 0.625rem;
  margin-bottom: 0.1875rem;
}
.site-drawer .drawer-contacts ul li .contact-detail {
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: 0.1875rem;
}
.site-drawer .drawer-contacts ul li .contact-description {
  font-size: 0.75rem;
  font-weight: 500;
  width: 100%;
  opacity: 0.5;
}
.site-drawer .site-copyright {
  font-size: 0.75rem;
}
.site-drawer .site-copyright strong {
  font-weight: 600;
}
.site-drawer .site-copyright a {
  font-weight: 500;
  text-decoration: underline;
  color: #EF233C;
}

/*------------------------------------ 
    10 - Modules 
------------------------------------*/
.klbth-module {
  position: relative;
}
.klbth-module.overflow {
  overflow: hidden;
}
.klbth-module-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
.klbth-module-header.with-border {
  padding-bottom: 1.125rem;
  border-bottom: 1px solid var(--color-info-dark);
}
.klbth-module-header.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 992px) {
  .klbth-module-header.align-center {
    margin-bottom: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .klbth-module-header.align-center {
    margin-bottom: 5rem;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-header.align-center .entry-title {
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0.625rem;
  }
}
.klbth-module-header.align-center .entry-description {
  max-width: 47.5rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .klbth-module-header.align-center .entry-description {
    font-size: 0.9375rem;
    line-height: normal;
  }
}
.klbth-module-header .entry-title {
  font-size: 1.0625rem;
  line-height: normal;
  font-weight: 600;
  margin-right: 1.25rem;
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .klbth-module-header .entry-title {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
@media screen and (max-width: 991.98px) {
  .klbth-module-header .entry-title {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.klbth-module-header .klbth-countdown-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 575.98px) {
  .klbth-module-header .klbth-countdown-wrapper .countdown-description {
    font-size: 0.6875rem !important;
  }
}
@media screen and (max-width: 991.98px) {
  .klbth-module-header .klbth-countdown-wrapper {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    width: 100%;
    margin-bottom: 0.625rem;
  }
}
.klbth-module-header .klbth-countdown-wrapper .kblth-countdown {
  font-size: 1rem;
  height: 2.25rem;
  color: #fff;
  background: rgb(232, 89, 12);
  background: linear-gradient(45deg, rgb(232, 89, 12) 0%, rgb(240, 62, 62) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e8590c",endColorstr="#f03e3e",GradientType=1);
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  margin-right: 0.625rem;
  border-radius: var(--theme-theme-radius-base);
}
.klbth-module-header .klbth-countdown-wrapper .kblth-countdown .separator {
  line-height: 1;
  margin: 0;
}
.klbth-module-header .klbth-countdown-wrapper .kblth-countdown .count-item {
  background-color: transparent;
}
.klbth-module-header .klbth-countdown-wrapper .countdown-description {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-light);
  margin-bottom: 0;
}
.klbth-module-header .entry-description {
  width: 100%;
  font-size: 0.8125rem;
  line-height: normal;
  color: var(--color-text-light);
}
@media screen and (min-width: 992px) {
  .klbth-module-header .entry-description {
    width: auto;
  }
}
@media screen and (max-width: 991.98px) {
  .klbth-module-header .entry-description {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    margin-top: 0.125rem;
  }
}
.klbth-module-header .entry-description p {
  margin-bottom: 0;
}
.klbth-module-header .btn {
  margin-left: auto;
}
@media screen and (max-width: 991.98px) {
  .klbth-module-header .btn {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
.klbth-module-header .klbt-module-tab-links {
  display: none;
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
.klbth-module-header .klbt-module-tab-links ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links.style-1 {
    padding-left: 1.25rem;
    border-left: 1px solid var(--color-info-dark);
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links.style-1 li {
    margin-right: 1.875rem;
  }
  .klbth-module-header .klbt-module-tab-links.style-1 li:last-child {
    margin-right: 0;
  }
}
.klbth-module-header .klbt-module-tab-links.style-1 li a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.9375rem;
  line-height: normal;
  font-weight: 500;
  height: 1.5625rem;
  color: var(--color-text-description);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module-header .klbt-module-tab-links.style-1 li a:hover {
  color: var(--color-text-primary);
}
.klbth-module-header .klbt-module-tab-links.style-1 li.active a {
  color: var(--color-primary);
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links.style-1 li.active a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -1.1875rem;
    left: 0;
    background-color: currentColor;
  }
}
.klbth-module-header .klbt-module-tab-links.style-2 {
  background-color: var(--color-info-light);
  padding: 3px;
  border-radius: var(--theme-theme-radius-base);
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links.style-2 {
    margin-left: 1.25rem;
  }
}
.klbth-module-header .klbt-module-tab-links.style-2 .tab-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.8125rem;
  line-height: 1.9230769231;
  font-weight: 600;
  color: var(--color-text-primary);
  border-radius: var(--theme-theme-radius-small);
  padding: 0.3125rem 0.75rem;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module-header .klbt-module-tab-links.style-2 .tab-link:hover {
  background-color: var(--color-info-dark);
}
@media screen and (min-width: 992px) {
  .klbth-module-header .klbt-module-tab-links.style-2 .tab-item + .tab-item {
    margin-left: 2px;
  }
}
.klbth-module-header .klbt-module-tab-links.style-2 .tab-item.active .tab-link {
  color: #fff;
  background: var(--color-gradient-1);
}
.klbth-module-header.color-danger {
  border-bottom-width: 2px;
  border-bottom-color: var(--color-danger);
}
.klbth-module-header.color-danger .entry-title {
  color: var(--color-danger);
}
@media screen and (max-width: 991.98px) {
  .klbth-module-header.counter-active .entry-title {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .klbth-module-header.counter-active .btn {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

.klbth-slider-wrapper {
  position: relative;
  min-height: 12.5rem;
}
.klbth-slider-wrapper .klbth-slider-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-slider-wrapper .klbth-slider {
  opacity: 0;
  visibility: hidden;
  will-change: opacity;
  -webkit-transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-slider-wrapper.slider-loaded {
  min-height: auto;
}
.klbth-slider-wrapper.slider-loaded .klbth-slider-loader {
  opacity: 0;
  visibility: hidden;
}
.klbth-slider-wrapper.slider-loaded .klbth-slider {
  opacity: 1;
  visibility: visible;
}
.klbth-slider-wrapper .slider-no-radius:not(.klbth-carousel) .entry-media {
  border-radius: 0 !important;
}
.klbth-slider-wrapper .width-full .entry-wrapper {
  max-width: 80rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 992px) {
  .klbth-slider-wrapper .width-full .entry-wrapper {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .klbth-slider-wrapper .width-full .entry-wrapper .badge {
    font-size: 0.8125rem;
    line-height: normal;
    height: 1.875rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.klbth-slider-wrapper .slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: auto;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  gap: 0.5rem;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}
.klbth-slider-wrapper .slick-dots li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.klbth-slider-wrapper .slick-dots li button {
  all: unset;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  text-indent: -9999px;
  background-color: var(--color-text-lighter);
  -webkit-transition: background-color 0.15s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: background-color 0.15s cubic-bezier(0.28, 0.12, 0.22, 1);
  cursor: pointer;
}
.klbth-slider-wrapper .slick-dots li button:hover {
  background-color: var(--color-text-light);
}
.klbth-slider-wrapper .slick-dots li.slick-active button {
  background-color: var(--color-primary);
}
.klbth-slider-wrapper .slick-arrow {
  all: unset;
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 2rem;
  height: 2.375rem;
  top: 50%;
  background-color: var(--color-info);
  border-radius: var(--theme-theme-radius-xsmall);
  z-index: 1;
  cursor: pointer;
}
.klbth-slider-wrapper .slick-arrow.slick-prev {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.klbth-slider-wrapper .slick-arrow.slick-prev svg {
  left: -2px;
}
.klbth-slider-wrapper .slick-arrow.slick-next {
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.klbth-slider-wrapper .slick-arrow.slick-next svg {
  right: -2px;
}
.klbth-slider-wrapper .slick-arrow svg {
  position: relative;
  width: 80%;
  height: 80%;
}
.klbth-slider-wrapper .slick-arrow [stroke-width] {
  stroke-width: 1.2;
}
.klbth-slider-wrapper .klbth-slider.arrow-size-sm .slick-arrow {
  width: 1.5rem;
  height: 1.875rem;
}
.klbth-slider-wrapper .klbth-slider.arrow-size-md .slick-arrow {
  width: 2rem;
  height: 2.375rem;
}
.klbth-slider-wrapper .klbth-slider.arrow-size-lg .slick-arrow {
  width: 2.375rem;
  height: 3.25rem;
  border-radius: var(--theme-theme-radius-base);
}
.klbth-slider-wrapper .klbth-slider.arrow-size-lg .slick-arrow.slick-prev {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.klbth-slider-wrapper .klbth-slider.arrow-size-lg .slick-arrow.slick-next {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.klbth-slider-wrapper .klbth-slider.arrow-size-xlg .slick-arrow {
  width: 2.875rem;
  height: 4.125rem;
  border-radius: var(--theme-theme-radius-base);
}
.klbth-slider-wrapper .klbth-slider.arrow-size-xlg .slick-arrow.slick-prev {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.klbth-slider-wrapper .klbth-slider.arrow-size-xlg .slick-arrow.slick-next {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.klbth-slider-wrapper .klbth-slider.arrows-light .slick-arrow {
  background-color: var(--color-body);
  -webkit-box-shadow: 0 1px 3px 0 rgba(33, 37, 41, 0.1), 0 1px 2px 0 rgba(33, 37, 41, 0.06);
          box-shadow: 0 1px 3px 0 rgba(33, 37, 41, 0.1), 0 1px 2px 0 rgba(33, 37, 41, 0.06);
}
.klbth-slider-wrapper .klbth-slider.arrows-animate .slick-arrow {
  opacity: 0;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.3s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-slider-wrapper .klbth-slider.arrows-animate .slick-arrow.slick-prev {
  -webkit-transform: translateX(-0.625rem);
          transform: translateX(-0.625rem);
}
.klbth-slider-wrapper .klbth-slider.arrows-animate .slick-arrow.slick-next {
  -webkit-transform: translateX(0.625rem);
          transform: translateX(0.625rem);
}
.klbth-slider-wrapper .klbth-slider.arrows-animate:hover .slick-arrow {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.klbth-slider-wrapper .klbth-slider.dots-style-filled .slick-dots {
  margin-top: -0.875rem;
  margin-bottom: 0.125rem;
  padding: 0.625rem;
  background-color: var(--color-body);
  -webkit-box-shadow: 0 1px 3px 0 rgba(33, 37, 41, 0.1), 0 1px 2px 0 rgba(33, 37, 41, 0.06);
          box-shadow: 0 1px 3px 0 rgba(33, 37, 41, 0.1), 0 1px 2px 0 rgba(33, 37, 41, 0.06);
  border-radius: var(--theme-theme-radius-large);
}
@media screen and (min-width: 992px) {
  .klbth-slider-wrapper .klbth-slider.dots-style-filled .slick-dots {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 991.98px) {
  .klbth-slider-wrapper .klbth-slider.dots-style-filled .slick-dots {
    position: relative;
    z-index: 1;
  }
}
.klbth-slider-wrapper .klbth-slider.dots-style-def .slick-dots {
  margin-top: 1.25rem;
}
@media screen and (min-width: 992px) {
  .klbth-slider-wrapper .klbth-slider.dots-style-def .slick-dots {
    margin-top: 1.875rem;
  }
}
@media screen and (min-width: 992px) {
  .klbth-slider-wrapper .klbth-slider.dots-align-right .slick-dots {
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;
  }
}
.klbth-slider-wrapper .klbth-slider.dots-align-center .slick-dots {
  position: relative;
  margin-top: -0.875rem;
  margin-bottom: 2px;
  z-index: 2;
}
.klbth-slider-wrapper .klbth-slider.slider-radius .slick-list {
  border-radius: var(--theme-theme-radius-base);
}
.klbth-slider-wrapper .klbth-slider.slider-overflow {
  overflow: hidden;
}
.klbth-slider-wrapper .klbth-slider[data-margin="10"] .slick-list {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}
.klbth-slider-wrapper .klbth-slider[data-margin="10"] .slick-list .slider-item {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
.klbth-slider-wrapper .klbth-slider[data-margin="20"] .slick-list {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}
.klbth-slider-wrapper .klbth-slider[data-margin="20"] .slick-list .slider-item {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .klbth-slider-wrapper .klbth-slider[data-margin="20"] .slick-list {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .klbth-slider-wrapper .klbth-slider[data-margin="20"] .slick-list .slider-item {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.klbth-slider-wrapper .klbth-slider[data-margin="30"] .slick-list {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}
.klbth-slider-wrapper .klbth-slider[data-margin="30"] .slick-list .slider-item {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}
@media screen and (min-width: 768px) {
  .klbth-slider-wrapper .klbth-slider[data-margin="30"] .slick-list {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .klbth-slider-wrapper .klbth-slider[data-margin="30"] .slick-list .slider-item {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
.klbth-slider-wrapper .klbth-slider.klbth-carousel .slick-arrow {
  width: 36px;
  height: 44px;
  border-radius: var(--theme-theme-radius-small);
  padding: 0;
}
.klbth-slider-wrapper .klbth-slider.klbth-carousel .slick-arrow.slick-prev {
  left: -1.375rem;
}
.klbth-slider-wrapper .klbth-slider.klbth-carousel .slick-arrow.slick-next {
  right: -1.375rem;
}
.klbth-slider-wrapper .klbth-slider.klbth-carousel.dots-align-def .slick-dots {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  max-width: 100%;
  padding-top: 1.25rem;
  border-top: 1px solid var(--color-info);
}
@media screen and (min-width: 992px) {
  .klbth-slider-wrapper .klbth-slider.klbth-carousel.dots-align-def .slick-dots {
    padding-top: 1.875rem;
  }
}
.klbth-slider-wrapper .klbth-slider.klbth-carousel.arrows-light .slick-arrow {
  -webkit-box-shadow: 0 20px 25px -5px rgba(33, 37, 41, 0.1), 0 10px 10px -5px rgba(33, 37, 41, 0.04);
          box-shadow: 0 20px 25px -5px rgba(33, 37, 41, 0.1), 0 10px 10px -5px rgba(33, 37, 41, 0.04);
  border: 1px solid var(--color-info);
}
.klbth-slider-wrapper .klbth-slider.klbth-carousel.arrows-light .slick-arrow:hover {
  background-color: var(--color-info-light);
}

.klbth-banner {
  position: relative;
  overflow: hidden;
}
.klbth-banner .entry-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}
.klbth-banner .entry-wrapper .entry-inner {
  position: relative;
  max-width: 100%;
  z-index: 1;
}
@media screen and (max-width: 767.98px) {
  .klbth-banner .entry-wrapper .entry-inner.w-50 {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .klbth-banner .entry-wrapper .entry-inner.w-50 {
    max-width: 50%;
  }
}
@media screen and (max-width: 767.98px) {
  .klbth-banner .entry-wrapper .entry-inner.w-60 {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .klbth-banner .entry-wrapper .entry-inner.w-60 {
    max-width: 60%;
  }
}
@media screen and (max-width: 767.98px) {
  .klbth-banner .entry-wrapper .entry-inner.w-70 {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .klbth-banner .entry-wrapper .entry-inner.w-70 {
    max-width: 70%;
  }
}
@media screen and (min-width: 768px) {
  .klbth-banner .entry-wrapper .entry-inner.w-80 {
    max-width: 80%;
  }
}
@media screen and (min-width: 768px) {
  .klbth-banner .entry-wrapper .entry-inner.w-90 {
    max-width: 90%;
  }
}
.klbth-banner.style-inner .entry-wrapper {
  padding: 2.5rem 1.875rem;
  z-index: 2;
}
@media screen and (min-width: 420px) {
  .klbth-banner.style-inner .entry-wrapper {
    padding: 3.125rem 2.5rem;
  }
}
@media screen and (min-width: 992px) {
  .klbth-banner.style-inner .entry-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 1.875rem;
  }
}
.klbth-banner.style-inner .entry-wrapper:before {
  border-radius: var(--theme-theme-radius-base);
}
.klbth-banner.style-inner .entry-wrapper.container {
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (min-width: 992px) {
  .klbth-banner.style-inner .entry-wrapper.container {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
  }
}
.klbth-banner.style-inner .entry-media::before {
  z-index: 1;
}
@media screen and (min-width: 992px) {
  .klbth-banner.style-inner .entry-media {
    position: relative;
  }
}
@media screen and (max-width: 991.98px) {
  .klbth-banner.style-inner .entry-media {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .klbth-banner.style-inner .entry-media img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.klbth-banner.style-inner .entry-media img {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .klbth-banner.style-inner.space-md .entry-wrapper {
    padding: 2.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .klbth-banner.style-inner.space-lg .entry-wrapper {
    padding: 3.125rem;
  }
}
@media screen and (min-width: 1024px) {
  .klbth-banner.style-inner.space-xlg .entry-wrapper {
    padding: 3.125rem;
  }
}
@media screen and (min-width: 1200px) {
  .klbth-banner.style-inner.space-xlg .entry-wrapper {
    padding: 3.75rem;
  }
}
.klbth-banner.color-scheme-light {
  color: #fff;
}
.klbth-banner.align-top .entry-wrapper {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.klbth-banner.align-center .entry-wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.klbth-banner.align-bottom .entry-wrapper {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.klbth-banner.justify-start .entry-wrapper {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.klbth-banner.justify-center .entry-wrapper {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.klbth-banner.justify-center .entry-wrapper .entry-heading {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.klbth-banner.justify-center .entry-wrapper .entry-footer .price {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.klbth-banner.justify-end .entry-wrapper {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.klbth-banner.hover-zoom .entry-media img {
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: transform 0.5s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: transform 0.5s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-transform 0.5s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-banner.hover-zoom:hover .entry-media img {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}
.klbth-banner .entry-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.25rem;
}
@media screen and (min-width: 992px) {
  .klbth-banner .entry-footer {
    gap: 1.875rem;
    margin-top: 1.25rem;
  }
}
.klbth-banner .entry-footer.horizontal {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.klbth-banner .entry-footer.vertical {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.klbth-banner .entry-footer.vertical > * {
  width: 100%;
}
.klbth-banner .entry-footer .btn.link {
  color: currentColor;
}
.klbth-banner .entry-footer.slider-footer .price {
  font-size: 1.625rem;
  line-height: 1.1538461538;
}
.klbth-banner .entry-footer.slider-footer .price del {
  color: currentColor;
  opacity: 0.7;
}
.klbth-banner .entry-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 0.3125rem;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .klbth-banner .entry-heading {
    gap: 0.625rem;
  }
}
.klbth-banner .entry-heading > * {
  margin-bottom: 0;
}
.klbth-banner .badge {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
  height: 1.25rem;
  background-color: var(--color-primary);
  border-radius: 1.875rem;
}
.klbth-banner .entry-sale-percentage {
  font-size: 3rem;
  line-height: 1.0833333333;
  font-weight: 600;
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .klbth-banner .entry-sale-percentage {
    font-size: 4.125rem;
    line-height: 1.0909090909;
    margin-bottom: 0.9375rem;
  }
}
.klbth-banner .entry-sale-percentage span {
  font-size: 70%;
}
.klbth-banner .entry-small {
  font-size: 0.8125rem;
  font-weight: 600;
}
.klbth-banner .entry-excerpt {
  opacity: 0.7;
}
.klbth-banner .price {
  color: currentColor;
}
.klbth-banner .price ins {
  font-weight: 600;
  color: inherit;
}

.klbth-module-deals .deal-item {
  position: relative;
  padding: 1.25rem;
  border: 1px solid var(--color-info-dark);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  border-radius: var(--theme-theme-radius-base);
}
@media screen and (min-width: 992px) {
  .klbth-module-deals .deal-item {
    padding: 2.1875rem;
  }
}
.klbth-module-deals .deal-item:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 3px solid var(--color-danger);
  border-radius: var(--theme-theme-radius-base);
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module-deals .deal-item:hover::before {
  opacity: 1;
}
.klbth-module-deals .deal-item .deal-sale-count {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.125rem;
  line-height: normal;
  font-weight: 600;
  width: 3.875rem;
  height: 3.875rem;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 2;
  pointer-events: none;
  background-color: var(--color-danger);
  border-top-left-radius: var(--theme-theme-radius-base);
  border-bottom-right-radius: var(--theme-theme-radius-base);
}
.klbth-module-deals .deal-item .product .product-content .product-title {
  font-size: 0.9375rem;
  line-height: 1.5333333333;
}
@media screen and (min-width: 992px) {
  .klbth-module-deals .deal-item .product .product-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .klbth-module-deals .deal-item .product .product-content .thumbnail-wrapper {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 12.5rem;
            flex: 0 0 12.5rem;
    max-width: 12.5rem;
    margin-bottom: 0;
    margin-right: 1.875rem;
  }
  .klbth-module-deals .deal-item .product .product-content .content-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .klbth-module-deals .deal-item .product .product-content .product-title {
    font-size: 1rem;
    line-height: 1.5;
  }
}
.klbth-module-deals .deal-item .product .product-content .price {
  font-size: 1.375rem;
  line-height: normal;
  margin-bottom: 0.9375rem;
}
.klbth-module-deals .deal-item .product .product-content .product-progress {
  height: 0.5rem;
  margin-bottom: 0.625rem;
}
.klbth-module-deals .deal-item .product .product-content .product-progress-count {
  font-size: 0.8125rem;
  line-height: normal;
}
.klbth-module-deals .deal-item .deal-counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid var(--color-info);
}
@media screen and (min-width: 992px) {
  .klbth-module-deals .deal-item .deal-counter {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .klbth-module-deals .deal-item .deal-counter .cell {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .klbth-module-deals .deal-item .deal-counter .cell:nth-child(2) {
    -webkit-box-flex: 0;
        -ms-flex: 0 auto;
            flex: 0 auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-left: 1.875rem;
  }
}
.klbth-module-deals .deal-item .deal-counter .entry-title {
  font-size: 1.125rem;
  color: var(--color-danger);
  margin-bottom: 0;
}
.klbth-module-deals .deal-item .deal-counter .entry-description {
  font-size: 0.75rem;
  color: var(--color-text-description);
}
.klbth-module-deals .deal-item .deal-counter .entry-description p {
  margin-bottom: 0;
}
.klbth-module-deals .deal-item .deal-counter .klbth-countdown-wrapper {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .klbth-module-deals .deal-item .deal-counter .klbth-countdown-wrapper {
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-deals .deal-item .deal-counter .klbth-countdown-wrapper .kblth-countdown {
    font-size: 1rem;
  }
}
.klbth-module-deals .deal-item .deal-counter .klbth-countdown-wrapper .kblth-countdown .count-item {
  color: #fff;
  background: var(--color-gradient-1);
  width: 2rem;
  height: 2rem;
}
@media screen and (min-width: 992px) {
  .klbth-module-deals .deal-item .deal-counter .klbth-countdown-wrapper .kblth-countdown .count-item {
    width: 2.25rem;
    height: 2.375rem;
  }
}
.klbth-module-deals .deal-item.red-border::before {
  opacity: 1;
}

.klbth-coupon-banner {
  position: relative;
}
.klbth-coupon-banner a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  padding: 1.25rem;
  color: var(--color-danger);
  background-color: var(--color-danger-light);
  border: 1px dashed var(--color-danger);
  border-radius: var(--theme-theme-radius-base);
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .klbth-coupon-banner a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;
    text-align: inherit;
    padding: 1.25rem 2.5rem;
  }
}
.klbth-coupon-banner.style-1 a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.klbth-coupon-banner.style-1 .coupon-sale {
  font-size: 3.25rem;
  line-height: normal;
  font-weight: 700;
}
.klbth-coupon-banner.style-1 .coupon-sale span {
  font-size: 70%;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .klbth-coupon-banner.style-1 .coupon-sale {
    font-size: 2.875rem;
    line-height: normal;
    margin-right: 2.5rem;
  }
}
.klbth-coupon-banner.style-1 .coupon-title {
  font-size: 1rem;
  line-height: normal;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .klbth-coupon-banner.style-1 .coupon-title {
    font-size: 1.25rem;
    line-height: normal;
  }
}
.klbth-coupon-banner.style-1 .coupon-description {
  font-size: 0.8125rem;
  line-height: normal;
  color: var(--color-text-description);
}
.klbth-coupon-banner.style-1 .coupon-code {
  font-size: 1.375rem;
  line-height: normal;
  font-weight: 700;
  margin-top: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .klbth-coupon-banner.style-1 .coupon-code {
    margin-left: auto;
    margin-top: 0;
  }
}
.klbth-coupon-banner.style-1 .sale-overflow {
  position: absolute;
  font-size: 15rem;
  line-height: normal;
  font-weight: 700;
  opacity: 0.1;
  right: 15%;
}
@media screen and (max-width: 991.98px) {
  .klbth-coupon-banner.style-1 .sale-overflow {
    display: none;
  }
}
.klbth-coupon-banner.style-1 .sale-overflow span {
  font-size: 70%;
  font-weight: 600;
}
.klbth-coupon-banner.style-2 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.klbth-coupon-banner.style-2 a {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.klbth-coupon-banner.style-2 .coupon-title {
  font-size: 1rem;
  line-height: normal;
  font-weight: 600;
}
@media screen and (min-width: 992px) {
  .klbth-coupon-banner.style-2 .coupon-title {
    font-size: 1.25rem;
    line-height: normal;
  }
}
.klbth-coupon-banner.style-2 .coupon-code {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  height: 2.3125rem;
  padding: 0 0.9375rem;
  color: #fff;
  background-color: var(--color-danger);
  border-radius: 2.5rem;
  margin: 0.9375rem 0;
}
@media screen and (min-width: 992px) {
  .klbth-coupon-banner.style-2 .coupon-code {
    margin: 0 0.9375rem;
  }
}
.klbth-coupon-banner.style-2 .coupon-description {
  font-size: 0.8125rem;
  line-height: normal;
  color: var(--color-text-description);
}

.klbth-module-category-products {
  position: relative;
}
.klbth-module-category-products .klbth-module-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.klbth-module-category-products .klbth-module-wrapper .block-column {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.klbth-module-category-products.style-1 .klbth-module-wrapper .block-column.banner-column {
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .klbth-module-category-products.style-1 .klbth-module-wrapper {
    border: 1px solid var(--color-info);
    border-radius: var(--theme-theme-radius-base);
    -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
            box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .banner-column {
    margin-bottom: 0 !important;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .banner-column .entry-media {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 22.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .banner-column .entry-media img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item {
    padding: 1.25rem;
    margin-bottom: 0;
    border-bottom: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item:nth-child(2n+1) {
    border-right: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item:nth-child(7), .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item:nth-child(8) {
    border-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-category-products.style-1 .klbth-module-wrapper {
    position: relative;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .banner-column {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .banner-column .klbth-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .banner-column .klbth-banner .entry-media {
    display: block;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: var(--theme-theme-radius-base);
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .products-column {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item {
    border-bottom: 0;
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item:nth-of-type(odd) {
    border-right: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item:nth-child(-n+4) {
    border-bottom: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-1 .klbth-module-wrapper .column-item:nth-child(4n+2) {
    border-right: 1px solid var(--color-info);
  }
}
.klbth-module-category-products.style-2 .klbth-module-wrapper .block-column.banner-column {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 768px) {
  .klbth-module-category-products.style-2 .klbth-module-wrapper {
    border: 1px solid var(--color-info);
    border-radius: var(--theme-theme-radius-base);
    -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
            box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column {
    height: 17.5rem;
    margin-top: 0 !important;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column .klbth-banner {
    height: 100%;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column .klbth-banner .entry-media {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column .klbth-banner .entry-media img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .column-item {
    padding: 1.25rem;
    margin-bottom: 0;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .column-item:nth-child(2n+1) {
    border-right: 1px solid var(--color-info);
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-category-products.style-2 .klbth-module-wrapper {
    position: relative;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    height: auto;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column .klbth-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .banner-column .klbth-banner .entry-media {
    display: block;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--theme-theme-radius-base);
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .products-column {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .products-column .products {
    height: 100%;
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .column-item:nth-of-type(odd) {
    border-right: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-2 .klbth-module-wrapper .column-item:nth-child(4n+2) {
    border-right: 1px solid var(--color-info);
  }
}
@media screen and (min-width: 768px) {
  .klbth-module-category-products.style-3 .klbth-module-wrapper {
    border: 1px solid var(--color-info);
    border-radius: var(--theme-theme-radius-base);
    -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
            box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .banner-column .entry-media {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 22.5rem;
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .banner-column .entry-media img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .column-item {
    padding: 1.25rem;
    margin-bottom: 0;
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .column-item + .column-item {
    border-left: 1px solid var(--color-info);
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-category-products.style-3 .klbth-module-wrapper {
    position: relative;
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .banner-column {
    position: relative;
    min-height: 15rem;
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .banner-column .klbth-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .klbth-module-category-products.style-3 .klbth-module-wrapper .banner-column .klbth-banner .entry-media {
    display: block;
    height: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.klbth-module-category-products.style-4 .klbth-module-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .klbth-module-category-products.style-4 .klbth-module-wrapper {
    border: 1px solid var(--color-info);
    border-radius: var(--theme-theme-radius-base);
    -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
            box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .banner-column .entry-media {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 22.5rem;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .banner-column .entry-media img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .column-item {
    padding: 1.25rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-category-products.style-4 .klbth-module-wrapper {
    position: relative;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .banner-column {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .banner-column .klbth-banner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .banner-column .klbth-banner .entry-media {
    display: block;
    height: 100%;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .products-column {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .products-column .products {
    height: 100%;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .products-column .column-item:nth-child(-n+3) {
    border-bottom: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .products-column .column-item:nth-child(3n+2) {
    border-left: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper .products-column .column-item:nth-child(3n+3) {
    border-left: 1px solid var(--color-info);
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper.image-left .klbth-banner .entry-media {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .klbth-module-category-products.style-4 .klbth-module-wrapper.image-right .klbth-banner .entry-media {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.klbth-module-categories {
  position: relative;
}
.klbth-module-categories.style-1 .categories {
  border: 1px solid var(--color-info-dark);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  border-radius: var(--theme-theme-radius-base);
}
.klbth-module-categories .categories.grid-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: center;
}
.klbth-module-categories .categories.grid-style .category-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 50%;
          flex: 1 0 50%;
  padding: 1.25rem;
}
@media screen and (min-width: 992px) {
  .klbth-module-categories .categories.grid-style .category-item + .category-item {
    border-left: 1px solid var(--color-info-dark);
  }
}
.klbth-module-categories .categories.grid-style .category-item .entry-media {
  border: 1px solid var(--color-info);
  margin-bottom: 0.625rem;
}
.klbth-module-categories .categories.grid-style .category-item .entry-media img {
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module-categories .categories.grid-style .category-item .category-name {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module-categories .categories.grid-style .category-item .counter {
  font-size: 0.75rem;
  color: var(--color-text-description);
}
@media screen and (min-width: 768px) {
  .klbth-module-categories .categories.grid-style.column-3 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 33.3333333%;
            flex: 1 0 33.3333333%;
  }
}
@media screen and (min-width: 768px) {
  .klbth-module-categories .categories.grid-style.column-4 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 33.3333333%;
            flex: 1 0 33.3333333%;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-categories .categories.grid-style.column-4 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 25%;
            flex: 1 0 25%;
  }
}
@media screen and (min-width: 768px) {
  .klbth-module-categories .categories.grid-style.column-5 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 33.3333333%;
            flex: 1 0 33.3333333%;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-categories .categories.grid-style.column-5 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 20%;
            flex: 1 0 20%;
  }
}
@media screen and (min-width: 768px) {
  .klbth-module-categories .categories.grid-style.column-6 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 33.3333333%;
            flex: 1 0 33.3333333%;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-categories .categories.grid-style.column-6 .category-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 16.6666666%;
            flex: 1 0 16.6666666%;
  }
}
.klbth-module-categories .categories .category-inner {
  color: currentColor;
}
.klbth-module-categories .categories .category-inner:hover .category-name {
  color: var(--color-primary);
}
.klbth-module-categories .categories .category-inner:hover .entry-media img {
  opacity: 0.8;
}
.klbth-module-categories .categories.list-style .category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 1.25rem;
  border: 1px solid var(--color-info-dark);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  border-radius: var(--theme-theme-radius-base);
}
@media screen and (min-width: 992px) {
  .klbth-module-categories .categories.list-style .category {
    padding: 1.25rem 1.875rem;
  }
}
.klbth-module-categories .categories.list-style .category .entry-media {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 6rem;
          flex: 0 0 6rem;
  max-width: 6rem;
  border: 1px solid var(--color-info);
  margin-right: 1.25rem;
}
@media screen and (min-width: 992px) {
  .klbth-module-categories .categories.list-style .category .entry-media {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.125rem;
            flex: 0 0 8.125rem;
    max-width: 8.125rem;
  }
}
.klbth-module-categories .categories.list-style .category .entry-detail {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.klbth-module-categories .categories.list-style .category .entry-detail .category-name {
  font-size: 0.9375rem;
  font-weight: 600;
}
.klbth-module-categories .categories.list-style .category .entry-detail .sub-categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.klbth-module-categories .categories.list-style .category .entry-detail .sub-categories ul li + li {
  margin-top: 2px;
}
.klbth-module-categories .categories.list-style .category .entry-detail .sub-categories ul li a {
  font-size: 0.8125rem;
  line-height: normal;
  color: var(--color-text-description);
  -webkit-transition: all 0.15s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.15s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module-categories .categories.list-style .category .entry-detail .sub-categories ul li a:hover {
  color: var(--color-primary);
}
.klbth-module-categories .categories.list-style .category .entry-detail > .btn {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 1.25rem;
  color: var(--color-primary);
}

.klbth-module-testimonial {
  position: relative;
}
@media screen and (min-width: 992px) {
  .klbth-module-testimonial .klbth-module-header.align-center {
    margin-bottom: 5rem;
  }
}
.klbth-module-testimonial.style-1 .slick-list {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.klbth-module-testimonial.style-1 .testimonial {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.klbth-module-testimonial.style-1 .testimonial .testimonial-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.klbth-module-testimonial.style-1 .testimonial .customer-avatar {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 5.375rem;
          flex: 0 0 5.375rem;
  max-width: 5.375rem;
  height: 5.375rem;
  border-radius: 50%;
  margin-right: 1.25rem;
  overflow: hidden;
}
.klbth-module-testimonial.style-1 .testimonial .customer-detail .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: 2px;
  font-size: 0.75rem;
  color: var(--color-review);
  margin-bottom: 0.3125rem;
}
.klbth-module-testimonial.style-1 .testimonial .customer-detail .customer-name {
  font-size: 1rem;
  font-weight: 600;
}
.klbth-module-testimonial.style-1 .testimonial .customer-detail .customer-mission {
  display: block;
  font-size: 0.6875rem;
  color: var(--color-text-description);
  margin-top: -2px;
  margin-bottom: 0.3125rem;
}
.klbth-module-testimonial.style-1 .testimonial .customer-detail .customer-description {
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  color: var(--color-text-description);
}

.klbth-module-grid {
  position: relative;
}
.klbth-module-grid .blog-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.klbth-module-grid .blog-posts .post {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100%;
          flex: 1 0 100%;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  margin-bottom: 1.25rem;
}
.klbth-module-grid.grid-4 .post .entry-title {
  font-size: 1rem;
}
.klbth-module-grid.grid-4 .post .entry-excerpt {
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}
@media screen and (min-width: 768px) {
  .klbth-module-grid.grid-4 .post {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
  }
}
@media screen and (min-width: 1024px) {
  .klbth-module-grid.grid-4 .post {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 25%;
            flex: 1 0 25%;
  }
}
@media screen and (min-width: 768px) {
  .klbth-module-grid.grid-3 .post {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-grid.grid-3 .post {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 33.3333%;
            flex: 1 0 33.3333%;
  }
}

.post .entry-media {
  position: relative;
  margin-bottom: 1.25rem;
}
.post .entry-media .entry-categories {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  z-index: 2;
}
@media screen and (min-width: 992px) {
  .post .entry-media .entry-categories {
    top: 0.9375rem;
    left: 0.9375rem;
  }
}
.post .entry-media .entry-categories a {
  color: var(--color-text-primary);
  background-color: var(--color-body);
}
.post .entry-media > a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  will-change: opacity;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.post .entry-media > a:hover::before {
  opacity: 1;
}
.post .entry-excerpt {
  font-size: 0.875rem;
  color: var(--color-text-description);
}
.post .entry-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.post .entry-title a {
  color: currentColor;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.post .entry-title a:hover {
  color: var(--color-primary);
}
.post .entry-categories {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 2px;
}
.post .entry-categories a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.625rem;
  color: #fff;
  background-color: var(--color-secondary);
  border-radius: 2.5rem;
}
.post .entry-published {
  margin-bottom: 0.375rem;
}
.post .entry-published a {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-primary);
}
.post .entry-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 1.875rem;
}
.post .entry-meta .meta-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-size: 0.75rem;
}
.post .entry-meta .meta-item > span {
  color: var(--color-text-light);
}
.post .entry-meta .meta-item a {
  font-weight: 500;
  color: currentColor;
}
.post .entry-meta .meta-item a.author span {
  font-style: italic;
  color: var(--color-text-light);
}

.blog-posts .post {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 992px) {
  .blog-posts .post {
    margin-bottom: 2.5rem;
  }
  .blog-posts .post:last-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .blog-posts .post .entry-title {
    font-size: 2.25rem;
    line-height: 1.1666666667;
  }
}
@media screen and (min-width: 992px) {
  .blog-posts .post .entry-excerpt {
    font-size: 1rem;
    line-height: 1.625;
  }
}
.blog-posts .post .entry-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.9375rem;
  margin-top: 1.875rem;
}
@media screen and (min-width: 992px) {
  .blog-posts .post .entry-footer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.single-post .entry-header {
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .single-post .entry-header {
    margin-bottom: 1.25rem;
  }
}
.single-post .entry-header .entry-categories {
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .single-post .entry-header .entry-categories {
    margin-bottom: 0.9375rem;
  }
}
.single-post .entry-header .entry-title {
  font-size: 1.25rem;
}
@media screen and (min-width: 992px) {
  .single-post .entry-header .entry-title {
    font-size: 2.375rem;
    line-height: 1.2105263158;
  }
}
.single-post .entry-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .single-post .entry-footer {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 1.5rem;
  }
}
.single-post .entry-content {
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
@media screen and (min-width: 992px) {
  .single-post .entry-content {
    font-size: 1rem;
    line-height: 1.625;
  }
}
.single-post .entry-content + .entry-footer {
  border-top: 1px solid var(--color-info-dark);
  padding-top: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .single-post .entry-content + .entry-footer {
    margin-top: 1.25rem;
    padding-top: 1.5rem;
    margin-bottom: 1.875rem;
  }
}
.single-post .entry-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.625rem;
}
@media screen and (min-width: 992px) {
  .single-post .entry-tags {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.single-post .entry-tags > span {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-description);
}
.single-post .entry-tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-post .entry-tags ul li a {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-text-primary);
  border: 1px solid var(--color-info-dark);
  padding: 0.4375rem 0.75rem;
  border-radius: var(--theme-theme-radius-xsmall);
  -webkit-box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
          box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
}
.single-post .post-author-bio {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.625rem;
  background-color: #F1F3F5;
  border: 1px solid var(--color-info);
  border-radius: var(--theme-theme-radius-base);
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 992px) {
  .single-post .post-author-bio {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
.single-post .post-author-bio .author-avatar {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 7.5rem;
          flex: 0 0 7.5rem;
  max-width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .single-post .post-author-bio .author-avatar {
    margin-right: 1.25rem;
  }
}
@media screen and (min-width: 992px) {
  .single-post .post-author-bio .author-detail {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.single-post .post-author-bio .author-detail > span {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-light);
  margin-bottom: 5px;
}
.single-post .post-author-bio .author-detail .author-name {
  font-size: 1.125rem;
  font-weight: 600;
}
.single-post .post-author-bio .author-detail > p {
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  color: var(--color-text-description);
  margin-bottom: 0;
}
.single-post .post-comments > .entry-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 5px;
}
@media screen and (min-width: 992px) {
  .single-post .post-comments > .entry-title {
    font-size: 1.5rem;
  }
}
.single-post .post-comments .entry-subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-description);
}
.single-post .post-comments .comment-list {
  margin: 0.9375rem 0 0;
  padding: 0.9375rem 0 0;
  list-style: none;
  border-top: 1px solid var(--color-info-dark);
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-list {
    margin: 1.5rem 0 0;
    padding: 1.5rem 0 0;
  }
}
.single-post .post-comments .comment-list .comment-body {
  padding: 1px;
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-list .comment-body {
    margin-bottom: 3rem;
  }
}
.single-post .post-comments .comment-list .comment-body .comment-meta {
  margin-bottom: 1rem;
}
.single-post .post-comments .comment-list .comment-body .comment-meta .avatar {
  float: left;
  position: relative;
  width: 4.75rem;
  height: 4.75rem;
  border-radius: 50%;
  margin-right: 1.25rem;
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-list .comment-body .comment-meta .avatar {
    margin-right: 2.5rem;
  }
}
.single-post .post-comments .comment-list .comment-body .comment-meta .comment-author b {
  float: left;
  font-size: 0.875rem;
  font-weight: 600;
}
.single-post .post-comments .comment-list .comment-body .comment-meta .comment-author b a {
  color: currentColor;
}
.single-post .post-comments .comment-list .comment-body .comment-meta .comment-metadata {
  float: left;
  line-height: 1.2;
}
.single-post .post-comments .comment-list .comment-body .comment-meta .comment-metadata a {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-light);
  margin-left: 0.9375rem;
}
.single-post .post-comments .comment-list .comment-body .comment-content {
  margin-top: 1.875rem;
  padding-left: 7.25rem;
}
.single-post .post-comments .comment-list .comment-body .comment-content p {
  font-size: 0.8125rem;
  line-height: 1.3125rem;
}
.single-post .post-comments .comment-list .comment-body .reply {
  padding-left: 7.25rem;
}
.single-post .post-comments .comment-list .comment-body .reply a {
  font-size: 0.8125rem;
  font-weight: 600;
  color: currentColor;
}
.single-post .post-comments .comment-list .comment-body .reply a i {
  position: relative;
  font-size: 1.125rem;
  top: 2px;
}
.single-post .post-comments .comment-respond {
  padding-top: 1.875rem;
  border-top: 1px solid var(--color-info-dark);
}
.single-post .post-comments .comment-respond .entry-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 5px;
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-respond .entry-title {
    font-size: 1.5rem;
  }
}
.single-post .post-comments .comment-respond .comment-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.single-post .post-comments .comment-respond .comment-form > p {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-respond .comment-form > p.comment-form-author, .single-post .post-comments .comment-respond .comment-form > p.comment-form-email {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}
.single-post .post-comments .comment-respond .comment-form > .comment-notes {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-description);
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-respond .comment-form input {
    height: 2.875rem;
  }
}
@media screen and (min-width: 992px) {
  .single-post .post-comments .comment-respond .comment-form .form-submit > * {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.klbth-module {
  position: relative;
}
.klbth-module .logos-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.klbth-module .logos-wrapper .logo-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 33.3333%;
          flex: 1 0 33.3333%;
}
.klbth-module .logos-wrapper .logo-item img {
  opacity: 0.5;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.klbth-module .logos-wrapper a:hover img {
  opacity: 1;
}
.klbth-module.grayscale-enable img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.klbth-module.grid-4 .logos-wrapper {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .klbth-module.grid-4 .logos-wrapper .logo-item {
    max-width: 170px;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 25%;
            flex: 1 0 25%;
  }
}
.klbth-module.grid-5 .logos-wrapper {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .klbth-module.grid-5 .logos-wrapper .logo-item {
    max-width: 150px;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 20%;
            flex: 1 0 20%;
  }
}
.klbth-module.grid-6 .logos-wrapper {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .klbth-module.grid-6 .logos-wrapper .logo-item {
    max-width: 130px;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 16.6666%;
            flex: 1 0 16.6666%;
  }
}

.search-holder {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.search-holder.active {
  opacity: 1;
  visibility: visible;
}
.search-holder .search-holder-inner {
  position: relative;
  height: 100%;
}
.search-holder .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.search-holder .container .search-holder-item {
  width: 100%;
}
.search-holder .container .search-holder-header {
  text-align: center;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 768px) {
  .search-holder .container .search-holder-header {
    margin-bottom: 1.25rem;
  }
}
.search-holder .container .search-holder-header span {
  text-align: center;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .search-holder .container .search-holder-header span {
    font-size: 1.5rem;
  }
}
.search-holder .container .search-holder-header .site-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
}
@media screen and (min-width: 992px) {
  .search-holder .container .search-holder-header .site-close {
    top: 1.875rem;
    right: 2.5rem;
  }
}
.search-holder .container .search-form {
  position: relative;
}
@media screen and (min-width: 992px) {
  .search-holder .container .search-form input {
    height: 3.5rem;
  }
}
.search-holder .container .search-form button {
  position: absolute;
  font-size: 1.25rem;
  background-color: transparent;
  top: 0;
  right: 0;
}
@media screen and (min-width: 992px) {
  .search-holder .container .search-form button {
    font-size: 1.875rem;
    height: 3.5rem;
  }
}
.search-holder .container .popular-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 0.8125rem;
  line-height: normal;
  margin-top: 1.25rem;
}
@media screen and (min-width: 992px) {
  .search-holder .container .popular-tags {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.search-holder .container .popular-tags > span {
  position: relative;
  color: var(--color-text-description);
  margin-right: 0.625rem;
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 992px) {
  .search-holder .container .popular-tags > span {
    margin-bottom: 0;
  }
}
.search-holder .container .popular-tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 3px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.search-holder .container .popular-tags ul li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 500;
  height: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: var(--color-text-description);
  background-color: var(--color-info-light);
  border-radius: var(--theme-theme-radius-xsmall);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.search-holder .container .popular-tags ul li a:hover {
  color: var(--color-text-primary);
  background-color: var(--color-info);
}

.klbth-module-search {
  color: #fff;
  background: var(--color-gradient-1);
  border: 2px solid var(--color-primary);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 0 4px 6px -1px rgba(33, 37, 41, 0.1), 0 2px 4px -1px rgba(33, 37, 41, 0.06);
          box-shadow: 0 4px 6px -1px rgba(33, 37, 41, 0.1), 0 2px 4px -1px rgba(33, 37, 41, 0.06);
  padding: 1.25rem;
}
@media screen and (min-width: 992px) {
  .klbth-module-search {
    padding: 2.5rem;
    border-radius: var(--theme-theme-radius-large);
    border: 3px solid var(--color-primary);
  }
}
.klbth-module-search .module-search-header {
  margin-bottom: 1.875rem;
  padding-bottom: 0.9375rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.klbth-module-search .service-search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (min-width: 992px) {
  .klbth-module-search .service-search-form {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .klbth-module-search .service-search-form > * {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .klbth-module-search .service-search-form > *.button-column {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .klbth-module-search .service-search-form .select2 .select2-selection {
    height: 2.875rem;
  }
  .klbth-module-search .service-search-form .select2 .select2-selection__arrow {
    top: 2px;
    pointer-events: none;
  }
  .klbth-module-search .service-search-form button {
    height: 2.875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.klbth-module-search .service-search-form button {
  background-color: transparent;
  border: 2px solid #fff;
}
.klbth-module-search .service-search-form button:hover {
  color: var(--color-primary);
  background-color: #fff;
}
.klbth-module-search .service-description {
  font-size: 0.8125rem;
  margin-top: 0.9375rem;
}
.klbth-module-search .service-description p {
  margin-bottom: 0;
}

/*------------------------------------ 
    11 - WooCommerce 
------------------------------------*/
.shop-container {
  margin-top: 0.9375rem;
}

.price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 0.625rem;
}
.price del {
  font-size: 80%;
  color: var(--color-text-description);
}
.price ins {
  font-weight: 600;
  text-decoration: none;
  color: var(--color-danger);
}

.product-rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.625rem;
}
.product-rating .rating-count {
  font-size: 0.75rem;
  line-height: normal;
  font-weight: 600;
  margin-left: 0.625rem;
}

.star-rating {
  position: relative;
  font-size: 0.75rem;
  line-height: normal;
  width: 68px;
  overflow: hidden;
}
.star-rating::before {
  font-family: "klbtheme";
  content: "\e853\e853\e853\e853\e853";
  letter-spacing: 3px;
  color: var(--color-info);
}
.star-rating span {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.star-rating span::before {
  font-family: "klbtheme";
  content: "\e853\e853\e853\e853\e853";
  letter-spacing: 3px;
  color: var(--color-review);
}

.product-seller {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  line-height: normal;
}
.product-seller span {
  font-weight: 500;
  margin-right: 3px;
  color: var(--color-text-light);
}
.product-seller a {
  font-weight: 600;
  color: var(--color-text-primary);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.product-seller a:hover {
  color: var(--color-primary);
}

.content-switcher {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 0.9375rem;
}
.content-switcher .switcher-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  transition: -webkit-transform 0.3s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  transition: transform 0.3s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  transition: transform 0.3s cubic-bezier(0.17, 0.62, 0.44, 0.99), -webkit-transform 0.3s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  will-change: transform;
}
@media screen and (min-width: 992px) {
  .content-switcher .switcher-wrapper {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@media screen and (max-width: 991.98px) {
  .content-switcher .switcher-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}
@media screen and (min-width: 992px) {
  .content-switcher .switcher-wrapper > * {
    margin-bottom: 0;
  }
  .content-switcher .switcher-wrapper > *:nth-child(2) {
    position: absolute;
    bottom: -100%;
  }
}
.content-switcher .product-seller a {
  color: var(--color-primary);
}

.product-stock {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
  line-height: normal;
}
.product-stock i {
  font-size: 0.875rem;
  line-height: normal;
}
.product-stock span {
  font-weight: 600;
  margin-left: 0.5rem;
}
.product-stock.in-stock {
  color: var(--color-success-dark);
}
.product-stock.out-of-stock {
  color: var(--color-danger-dark);
}

.product-badges {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  z-index: 1;
  pointer-events: none;
}
.product-badges .badge {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 1.5rem;
}
.product-badges .badge:not(.sale) {
  font-size: 0.6875rem;
  line-height: 0;
  text-transform: uppercase;
}
.product-badges .badge.sale {
  background-color: var(--color-danger);
}
.product-badges .badge.feature {
  background-color: var(--color-text-description);
}
.product-badges.style-2 {
  left: -4px;
}
.product-badges.style-2 > * {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.product-badges.style-3 {
  left: -0.625rem;
}
.product-badges.style-3 > * {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.product-badges.style-3 > *::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 5px 0;
  border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  pointer-events: none;
}
.product-badges.style-3 > *::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 5px 0;
  border-color: transparent var(--color-info-dark) transparent transparent;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  pointer-events: none;
}
.product-badges.style-3 > *.sale::after {
  border-right-color: var(--color-danger-dark);
}

.product-buttons {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.4375rem;
  top: 0.75rem;
  right: 0.75rem;
}
.product-buttons > * {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  color: currentColor;
  background-color: #fff;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  border: 1px solid var(--color-info);
  -webkit-box-shadow: 0 1px 3px 0 rgba(33, 37, 41, 0.1);
          box-shadow: 0 1px 3px 0 rgba(33, 37, 41, 0.1);
  -webkit-transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), background-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), border-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), -webkit-box-shadow 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), background-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), border-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), -webkit-box-shadow 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), background-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), border-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), box-shadow 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), background-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), border-color 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), box-shadow 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99), -webkit-box-shadow 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity, transform;
}
@media screen and (min-width: 992px) {
  .product-buttons > * {
    font-size: 1.125rem;
    width: 2rem;
    height: 2rem;
  }
}
.product-buttons > * i {
  position: relative;
}
.product-buttons > * i.klbth-icon-heart-round {
  top: 1px;
  text-shadow: 0 0 0;
}
@media screen and (min-width: 992px) {
  .product-buttons > * i.klbth-icon-repeat {
    font-size: 1rem;
    text-shadow: 0 0 0;
  }
}
.product-buttons > * i.klbth-icon-eye-empty {
  font-size: 1.0625rem;
  text-shadow: 0 0 0;
}
@media screen and (min-width: 992px) {
  .product-buttons > * i.klbth-icon-eye-empty {
    font-size: 1.25rem;
  }
}
.product-buttons > * svg {
  width: 1.125rem;
}
.product-buttons > *.compare {
  opacity: 0;
  -webkit-transform: translateX(0.625rem);
          transform: translateX(0.625rem);
}
.product-buttons > *.quickview {
  opacity: 0;
  -webkit-transform: translateX(0.625rem);
          transform: translateX(0.625rem);
  -webkit-transition-delay: 0.08s;
          transition-delay: 0.08s;
}
.product-buttons > *:hover {
  color: currentColor;
  background-color: var(--color-info-light);
  border-color: var(--color-info-dark);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.product-buttons > *:hover.wishlist {
  color: #F03E3E;
  border-color: #ecd8d8;
  background-color: #FFF5F5;
}
.product-buttons > *:hover.snow {
  border-color: #cfe6e9;
  background-color: #e4f7f9;
}
.product-buttons > *:hover.sun {
  border-color: #f4e5ce;
  background-color: #fdf5e9;
}

.product-progress-wrapper {
  position: relative;
}
.product-progress-wrapper .product-progress {
  margin-bottom: 0.4375rem;
}
.product-progress-wrapper .product-progress-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.75rem;
  line-height: normal;
  color: var(--color-text-light);
}
.product-progress-wrapper .product-progress-count strong {
  color: var(--color-text-primary);
}

.product-progress {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.375rem;
  border-radius: 1.875rem;
  background: -webkit-gradient(linear, left top, right top, from(#F03E3E), color-stop(#F76028), color-stop(#FFD43B), to(#12B886));
  background: linear-gradient(to right, #F03E3E, #F76028, #FFD43B, #12B886);
  overflow: hidden;
}
.product-progress span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
}
.product-progress span.progress {
  background-color: transparent;
}
.product-progress span.not-progress {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: var(--color-info);
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
}

.product {
  position: relative;
}
.product .product-wrapper {
  position: relative;
  z-index: 1;
}
.product .thumbnail-wrapper {
  border: 1px solid var(--color-info);
  margin-bottom: 1.25rem;
}
.product .thumbnail-wrapper .hover-slider-indicator {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 4px;
  border-radius: var(--theme-theme-radius-xsmall);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.375rem;
  bottom: 0.375rem;
  left: 0.5rem;
}
.product .thumbnail-wrapper .hover-slider-indicator .hover-slider-indicator-dot {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 0.4375rem;
  height: 0.4375rem;
  border-radius: 50%;
  background-color: var(--color-info-dark);
}
.product .thumbnail-wrapper .hover-slider-indicator .hover-slider-indicator-dot.active {
  background-color: var(--color-review-dark);
}
.product .thumbnail-wrapper .product-thumbnail {
  display: block;
  width: 100%;
  height: 100%;
}
.product .product-title {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
.product .product-title a {
  position: relative;
  color: currentColor;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.product .product-title a:hover {
  color: var(--color-primary);
}
.product .price {
  font-size: 1.125rem;
  line-height: normal;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .product .price {
    font-size: 1.25rem;
    line-height: normal;
  }
}
.product .product-cart-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.625rem;
}
.product .product-cart-form .price {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 0.125rem;
  padding-right: 0.75rem;
  margin-bottom: 0;
}
.product .product-cart-form a.button {
  font-size: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 2rem;
  height: 2rem;
  color: currentColor;
  border-radius: var(--theme-theme-radius-small);
  background-color: var(--color-info-light);
  padding: 0;
  margin-left: auto;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
@media screen and (min-width: 992px) {
  .product .product-cart-form a.button {
    width: 2.375rem;
    height: 2.375rem;
    border-radius: var(--theme-theme-radius-base);
  }
}
.product .product-cart-form a.button i {
  font-size: 1.125rem;
  line-height: normal;
  margin-left: 0;
}
@media screen and (min-width: 992px) {
  .product .product-cart-form a.button i {
    font-size: 1.375rem;
    line-height: normal;
  }
}
.product .product-cart-form a.button:hover {
  color: #fff;
  background-color: var(--color-primary);
}
.product .product-footer {
  position: relative;
  left: 0;
  right: 0;
}
@media screen and (min-width: 992px) {
  .product .product-footer {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    will-change: opacity;
    -webkit-transition: all 0.15s cubic-bezier(0.17, 0.62, 0.44, 0.99);
    transition: all 0.15s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  }
}
.product .product-footer .product-footer-details {
  font-size: 0.75rem;
}
.product .product-footer .product-footer-details ul {
  -webkit-padding-start: 1.25rem;
          padding-inline-start: 1.25rem;
}
.product .product-footer .product-footer-details ul li {
  font-weight: 500;
}
.product .product-footer .add_to_cart_button {
  font-size: 0.875rem;
  width: 100%;
  height: 2.375rem;
}
.product .product-footer .product-footer-inner {
  padding-top: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .product .product-footer .product-footer-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 0.9375rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    border-top: 1px solid var(--color-info);
  }
}
.product .product-content-fade {
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  background-color: var(--color-body);
  border: 1px solid var(--color-info);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
          box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
  pointer-events: none;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: all 0.15s cubic-bezier(0.17, 0.62, 0.44, 0.99);
  transition: all 0.15s cubic-bezier(0.17, 0.62, 0.44, 0.99);
}
@media screen and (max-width: 575.98px) {
  .product .product-content-fade {
    display: none;
  }
}
.products .product:hover.custom-hover {
  z-index: 11;
}
.products .product:hover .product-buttons > * {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.products .product:hover .content-switcher .switcher-wrapper {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
@media screen and (min-width: 992px) {
  .products .product:hover .product-footer, .products .product:hover .product-content-fade {
    opacity: 1;
    visibility: visible;
  }
}

.products {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.products:not(.klbth-slider) {
  margin-left: -5px;
  margin-right: -5px;
}
.products > *:not(.slick-list) {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 1.875rem;
}
.products.strech {
  height: 100%;
}
.products.hover-opacity .product {
  -webkit-transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-filter 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-filter 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), filter 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: opacity 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), filter 0.1s cubic-bezier(0.28, 0.12, 0.22, 1), -webkit-filter 0.1s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity, filter;
}
.products.hover-opacity:hover .product {
  opacity: 0.4;
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
.products.hover-opacity:hover .product:hover {
  opacity: 1;
  -webkit-filter: blur(0);
          filter: blur(0);
}
.products.featured-box {
  margin-left: 0;
  margin-right: 0;
  border: 1px solid var(--color-info);
  border-radius: var(--theme-theme-radius-small);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  padding: 1.5rem;
}
@media screen and (min-width: 992px) {
  .products.featured-box {
    border-radius: var(--theme-theme-radius-base);
  }
}
.products.featured-box .product {
  padding: 0;
  margin-bottom: 0;
}
.products.featured-box .product .product-progress-wrapper {
  margin-top: 0.5rem;
}
.products.featured-box .product .product-progress-wrapper .product-progress {
  height: 0.5rem;
}
.products.featured-box .feature-description {
  font-size: 0.75rem;
  color: var(--color-text-description);
  padding: 0;
  margin-top: 0.625rem;
  margin-bottom: 0;
}
.products.featured-box .feature-description p {
  margin-bottom: 0;
}
.products.list-style {
  margin-left: 0;
  margin-right: 0;
}
.products.list-style .product {
  padding-left: 0;
  padding-right: 0;
}
.products.list-style.small-list .product .product-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.products.list-style.small-list .product .product-content .thumbnail-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 6rem;
          flex: 0 0 6rem;
  max-width: 6rem;
  margin-right: 1.25rem;
}
.products.list-style.small-list .product .product-content .content-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
          flex: 1 0 0;
}
.products.list-style.small-list .product .product-content .content-wrapper .product-title {
  font-size: 0.8125rem;
  line-height: normal;
}
.products.list-style.small-list .product .product-content .content-wrapper .price {
  font-size: 1.0625rem;
  line-height: normal;
}
.products.list-style.small-list .product .product-content .product-details {
  font-size: 0.6875rem;
  line-height: normal;
  color: var(--color-text-description);
}
.products.list-style.small-list .product .product-content .product-details ul {
  padding-left: 0.9375rem;
}
.products.list-style.small-list .product .product-content .product-details ul li + li {
  margin-top: 0.3125rem;
}
.products.list-style.small-list .product .klbth-countdown-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.products.list-style.small-list .product .klbth-countdown-wrapper .kblth-countdown {
  font-size: 0.75rem;
}
.products.list-style.small-list .product .klbth-countdown-wrapper .kblth-countdown .count-item {
  width: 1.625rem;
  height: 1.75rem;
  background-color: var(--color-info-light);
}
.products.list-style.small-list .product .klbth-countdown-wrapper .countdown-description {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 0.625rem;
  color: var(--color-text-description);
  margin-bottom: 0;
  margin-left: 0.75rem;
}
.products.list-style.small-list.bordered .product {
  margin-bottom: 1.375rem;
}
.products.list-style.small-list.bordered .product + .product {
  padding-top: 1.375rem;
  border-top: 1px solid var(--color-info);
}
@media screen and (min-width: 992px) {
  .products.list-style.small-list.medium-image .thumbnail-wrapper {
    -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 9.0625rem !important;
            flex: 0 0 9.0625rem !important;
    max-width: 9.0625rem !important;
  }
}
@media screen and (min-width: 992px) {
  .products.list-style.small-list.medium-image .product-title {
    font-size: 0.875rem;
    line-height: normal;
  }
}
@media screen and (min-width: 992px) {
  .products.list-style.small-list.large-space.bordered .product {
    margin-bottom: 2.5rem;
  }
  .products.list-style.small-list.large-space.bordered .product + .product {
    padding-top: 2.5rem;
    border-top: 1px solid var(--color-info);
  }
}
@media screen and (min-width: 768px) {
  .products.large-list > * {
    -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 0.9375rem !important;
  }
  .products.large-list > * + * > * {
    padding-top: 1.875rem;
    border-top: 1px solid var(--color-info);
  }
  .products.large-list > * .product-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .products.large-list > * .product-wrapper .product-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
  }
  .products.large-list > * .product-wrapper .product-content .thumbnail-wrapper {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
            flex: 0 0 35%;
    max-width: 35%;
    margin-right: 1.25rem;
  }
  .products.large-list > * .product-wrapper .product-content .content-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .products.large-list > * .product-wrapper .product-content .content-wrapper .product-title {
    font-size: 1.25rem;
  }
  .products.large-list > * .product-wrapper .product-content .content-wrapper .price {
    font-size: 1.375rem;
  }
  .products.large-list > * .product-wrapper .product-footer {
    position: relative;
    opacity: 1;
    visibility: visible;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .products.large-list > * .product-wrapper .product-footer .product-footer-inner {
    margin: 0;
    padding: 0 0 0 1.875rem;
    border: 0;
  }
  .products.large-list > * .product-content-fade {
    display: none;
  }
}
@media screen and (min-width: 320px) {
  .products.mobile-2 > * {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
    max-width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .products.column-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .products.column-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333%;
            flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}
@media screen and (min-width: 992px) {
  .products.column-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
}
@media screen and (min-width: 992px) {
  .products.column-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
}
@media screen and (min-width: 992px) {
  .products.gutter-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .products.gutter-0 > * {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 576px) {
  .products.gutter-10 {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem;
  }
  .products.gutter-10 > * {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
}
@media screen and (min-width: 992px) {
  .products.gutter-20 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .products.gutter-20 > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media screen and (min-width: 1024px) {
  .products.gutter-30 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .products.gutter-30 > * {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 1024px) {
  .products.gutter-40 {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .products.gutter-40 > * {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
@media screen and (min-width: 1280px) {
  .products.gutter-40 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .products.gutter-40 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.offer-product-wrapper {
  border: 2px solid var(--color-danger);
  border-radius: var(--theme-theme-radius-small);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  padding: 1.25rem;
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.offer-product-wrapper:hover {
  -webkit-box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
          box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper {
    border-width: 4px;
    border-radius: var(--theme-theme-radius-base);
    padding: 2.5rem;
  }
}
.offer-product-wrapper .offer-product-header {
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .offer-product-header {
    margin-bottom: 1.25rem;
  }
}
.offer-product-wrapper .offer-product-header .entry-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--color-danger);
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .offer-product-header .entry-title {
    font-size: 1.375rem;
  }
}
.offer-product-wrapper .offer-product-header .entry-description {
  font-size: 0.75rem;
  line-height: normal;
  color: var(--color-text-description);
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .offer-product-header .klbth-countdown-wrapper .kblth-countdown {
    font-size: 1.125rem;
  }
}
.offer-product-wrapper .offer-product-header .klbth-countdown-wrapper .kblth-countdown .separator {
  color: var(--color-text-light);
}
.offer-product-wrapper .offer-product-header .klbth-countdown-wrapper .kblth-countdown .count-item {
  color: #fff;
  background: var(--color-gradient-1);
  width: 2rem;
  height: 2rem;
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .offer-product-header .klbth-countdown-wrapper .kblth-countdown .count-item {
    width: 2.375rem;
    height: 2.5rem;
  }
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .product .product-title {
    font-size: 1rem;
    line-height: 1.5;
  }
}
.offer-product-wrapper .product .product-progress {
  margin-top: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .product .product-progress {
    height: 0.5rem;
  }
}
@media screen and (min-width: 992px) {
  .offer-product-wrapper .product .product-progress-count {
    font-size: 0.8125rem;
    line-height: normal;
  }
}

@media screen and (min-width: 768px) {
  .klbth-module-product-grid.boxed {
    border: 3px solid var(--color-danger);
    border-radius: var(--theme-theme-radius-base);
  }
  .klbth-module-product-grid.boxed .klbth-module-header {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.9375rem;
    margin-bottom: 0;
  }
  .klbth-module-product-grid.boxed .column-item {
    padding: 1.25rem;
  }
}
@media screen and (min-width: 992px) {
  .klbth-module-product-grid.boxed .column-item + .column-item {
    border-left: 1px solid var(--color-info);
  }
}

.woocommerce-breadcrumb, .site-breadcrumb {
  margin-bottom: 0.9375rem;
}
.woocommerce-breadcrumb ul, .site-breadcrumb ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.woocommerce-breadcrumb ul li, .site-breadcrumb ul li {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
}
.woocommerce-breadcrumb ul li::after, .site-breadcrumb ul li::after {
  font-family: "klbtheme";
  font-size: 0.625rem;
  content: "\e9ab";
  color: var(--color-text-light);
  margin: 0 7px;
}
.woocommerce-breadcrumb ul li:last-child::after, .site-breadcrumb ul li:last-child::after {
  display: none;
}
.woocommerce-breadcrumb ul li a, .site-breadcrumb ul li a {
  color: var(--color-text-light);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.woocommerce-breadcrumb ul li a:hover, .site-breadcrumb ul li a:hover {
  color: var(--color-text-description);
}

.site-breadcrumb {
  margin-top: 0.9375rem;
  margin-bottom: 1.875rem;
}

.quantity {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 32px;
  padding-right: 32px;
  margin-right: 7px !important;
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
}
.quantity .minus, .quantity .plus {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 32px;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  color: var(--color-text);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.quantity .minus:after, .quantity .plus:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: currentColor;
  position: absolute;
  top: 50%;
  left: 13px;
}
.quantity .plus {
  left: auto;
  right: 0;
}
.quantity .plus::before {
  content: "";
  display: block;
  width: 2px;
  height: 10px;
  background: currentColor;
  position: absolute;
  top: 19px;
  left: 13px;
}
.quantity .plus:after {
  left: auto;
  right: 13px;
}
.quantity .qty {
  width: 42px;
  border: 0;
  margin-bottom: 0;
  height: 2.875rem;
  padding-left: 0;
  padding-right: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  font-size: 0.9375rem;
  text-align: center;
}

.single-product-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 0;
  }
  .single-product-wrapper .product-gallery {
    padding-right: 6.875rem;
  }
  .single-product-wrapper .product-detail {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin-left: -1.875rem;
  }
}
.single-product-wrapper .product-gallery .entry-media {
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-base);
}
.single-product-wrapper .product-gallery .product-thumbnails-wrapper .slider-item {
  cursor: pointer;
}
.single-product-wrapper .product-gallery.thumbnails-bottom .product-thumbnails-wrapper {
  margin-top: 0.375rem;
}
.single-product-wrapper .product-gallery.thumbnails-bottom .product-thumbnails-wrapper .slick-list {
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;
}
.single-product-wrapper .product-gallery.thumbnails-bottom .product-thumbnails-wrapper .slick-list .slick-track {
  margin: 0;
}
.single-product-wrapper .product-gallery.thumbnails-bottom .product-thumbnails-wrapper .slick-list .slick-track .slider-item {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
}
.single-product-wrapper .product-gallery.thumbnails-bottom .product-thumbnails-wrapper .slick-list .slick-track .slider-item.slick-current .entry-media {
  border-color: var(--color-text-primary);
}
.single-product-wrapper .product-gallery.thumbnails-left {
  position: relative;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-gallery.thumbnails-left {
    padding-left: 5.375rem;
  }
  .single-product-wrapper .product-gallery.thumbnails-left .product-thumbnails-wrapper {
    position: absolute;
    max-width: 4.75rem;
    left: 0;
    top: 0;
  }
  .single-product-wrapper .product-gallery.thumbnails-left .product-thumbnails-wrapper .slider-item.slick-current .entry-media {
    border-color: var(--color-text-primary);
  }
}
.single-product-wrapper .product-gallery .product-extra-details {
  margin-top: 1.875rem;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-gallery .product-extra-details {
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-detail .product_title {
    margin-bottom: 1.25rem;
  }
}
.single-product-wrapper .product-detail .product-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.9375rem;
}
.single-product-wrapper .product-detail .product-meta .product-rating {
  margin-bottom: 0;
}
.single-product-wrapper .product-detail .product-meta .product-rating .star-rating {
  font-size: 0.8125rem;
  width: 72px;
}
.single-product-wrapper .product-detail .product-meta .product-rating .rating-count {
  font-size: 0.8125rem;
}
.single-product-wrapper .product-detail .product-meta .product-sku {
  padding-left: 0.625rem;
  margin-left: 0.625rem;
  border-left: 1px solid var(--color-info-dark);
  margin-right: 0.9375rem;
}
.single-product-wrapper .product-detail .product-meta .product-sku > span {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: 500;
  color: var(--color-text-description);
}
.single-product-wrapper .product-detail .product-meta .product-stock {
  padding: 0.375rem 0.8125rem;
  border-radius: var(--theme-theme-radius-small);
}
@media screen and (max-width: 767.98px) {
  .single-product-wrapper .product-detail .product-meta .product-stock {
    margin-top: 0.625rem;
  }
}
.single-product-wrapper .product-detail .product-meta .product-stock.in-stock {
  background-color: var(--color-success-lighter);
}
.single-product-wrapper .product-detail .product-meta .product-stock.out-of-stock {
  background-color: var(--color-danger-lighter);
}
.single-product-wrapper .product-detail .product-meta.bottom {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-top: 1px solid var(--color-info-dark);
  padding-top: 0.9375rem;
  margin-top: 0.625rem;
}
.single-product-wrapper .product-detail .product-meta.bottom > * {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.single-product-wrapper .product-detail .product-meta.bottom > * > span {
  font-size: 0.8125rem;
  color: var(--color-text-description);
  margin-right: 0.375rem;
}
.single-product-wrapper .product-detail .product-meta.bottom > * a {
  font-size: 0.8125rem;
  font-weight: 500;
  color: currentColor;
}
.single-product-wrapper .product-detail .product-meta.bottom > * + * {
  margin-top: 0.9375rem;
}
.single-product-wrapper .product-detail .product-meta.bottom > *.product-tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  gap: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-product-wrapper .product-detail .product-meta.bottom > *.product-tags ul li a {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-text-primary);
  border: 1px solid var(--color-info-dark);
  padding: 0.3125rem 0.625rem;
  border-radius: var(--theme-theme-radius-xsmall);
  -webkit-box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
          box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
}
.single-product-wrapper .product-detail .product-meta.bottom .entry-social {
  margin-top: 1.875rem;
}
.single-product-wrapper .product-detail .product-meta.bottom .entry-social a {
  color: #fff;
}
.single-product-wrapper .product-detail .price {
  font-size: 1.875rem;
  margin-bottom: 0.75rem;
}
.single-product-wrapper .product-detail .product-description {
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  color: var(--color-text-description);
}
.single-product-wrapper .product-detail .product-description p {
  line-height: inherit;
}
.single-product-wrapper .product-detail .product-delivery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content .icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.125rem;
          flex: 0 0 2.125rem;
  max-width: 2.125rem;
  height: 2.125rem;
  margin-right: 0.9375rem;
  color: #fff;
  background-color: var(--color-secondary);
  border-radius: 50%;
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content .icon svg {
  width: 1.125rem;
  height: 1.125rem;
  fill: currentColor;
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content .detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content .detail span {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-description);
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content .detail p {
  font-size: 0.8125rem;
  font-weight: 600;
  margin-bottom: 0;
}
.single-product-wrapper .product-detail .product-delivery .product-delivery-content .detail p a {
  font-size: 0.75rem;
  color: var(--color-secondary);
  margin-left: 0.9375rem;
}
.single-product-wrapper .product-detail form {
  margin-bottom: 0.9375rem;
}
.single-product-wrapper .product-detail form.cart {
  border-top: 1px solid var(--color-info-dark);
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}
.single-product-wrapper .product-detail form.cart table.variations {
  width: 100%;
  margin-bottom: 0.9375rem;
}
.single-product-wrapper .product-detail form.cart table.variations tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.single-product-wrapper .product-detail form.cart table.variations .label {
  color: var(--color-text-description);
}
.single-product-wrapper .product-detail form.cart table.variations .label strong {
  color: var(--color-text-primary);
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-detail form.cart table.variations .label label {
    margin-bottom: 0;
  }
}
.single-product-wrapper .product-detail form.cart table.variations .value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  gap: 0.625rem;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-detail form.cart table.variations .value {
    padding-left: 1.25rem;
  }
}
.single-product-wrapper .product-detail form .add_to_cart_button {
  height: 2.875rem;
  margin-right: 7px;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-detail form .add_to_cart_button {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
.single-product-wrapper .product-detail form .add_to_cart_button i {
  font-size: 1.25rem;
  margin-right: 0.625rem;
  margin-left: 0;
}
.single-product-wrapper .product-detail form .add_to_cart_button span {
  line-height: 1;
}
.single-product-wrapper .product-detail form .product-low-stock {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  min-width: 170px;
  background-color: #F1F3F5;
  border-radius: var(--theme-theme-radius-base);
  padding: 6px 8px 6px 14px;
}
@media screen and (max-width: 767.98px) {
  .single-product-wrapper .product-detail form .product-low-stock {
    margin-top: 0.625rem;
  }
}
.single-product-wrapper .product-detail form .product-low-stock .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 1.125rem;
          flex: 0 0 1.125rem;
  max-width: 1.125rem;
  margin-right: 0.625rem;
}
.single-product-wrapper .product-detail form .product-low-stock .info {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.single-product-wrapper .product-detail form .product-low-stock .info span {
  display: block;
  font-size: 0.625rem;
  color: var(--color-text-description);
}
.single-product-wrapper .product-detail form .product-low-stock .info p {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--color-danger);
  margin-bottom: 0;
}
.single-product-wrapper .product-detail .product-wishlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767.98px) {
  .single-product-wrapper .product-detail .product-wishlist {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
}
.single-product-wrapper .product-detail .product-wishlist p {
  font-size: 0.75rem;
  padding-left: 0.9375rem;
  margin-bottom: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media screen and (max-width: 767.98px) {
  .single-product-wrapper .product-detail .product-wishlist p {
    padding-left: 0;
    padding-right: 0.9375rem;
  }
}
.single-product-wrapper .product-detail .product-wishlist a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.125rem;
  width: 2.25rem;
  height: 2.25rem;
  color: var(--color-text-primary);
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.single-product-wrapper .product-detail .product-wishlist a i {
  text-shadow: 0 0 0;
}
.single-product-wrapper .product-detail .product-wishlist a:hover {
  color: var(--color-danger);
  background-color: var(--color-danger-light);
  border-color: transparent;
}
.single-product-wrapper .product-detail .product-assistant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.25rem;
}
.single-product-wrapper .product-detail .product-assistant + .product-iconboxes {
  margin-top: 1.875rem;
}
.single-product-wrapper .product-detail .product-assistant .assistant-avatar {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3.375rem;
          flex: 0 0 3.375rem;
  max-width: 3.375rem;
  margin-right: 1.25rem;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-detail .product-assistant .assistant-avatar {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 3.875rem;
            flex: 0 0 3.875rem;
    max-width: 3.875rem;
  }
}
.single-product-wrapper .product-detail .product-assistant .assistant-content {
  line-height: 1;
}
.single-product-wrapper .product-detail .product-assistant .assistant-content span {
  display: block;
  font-size: 0.8125rem;
  line-height: normal;
  color: var(--color-text-description);
  margin-bottom: 0.625rem;
}
.single-product-wrapper .product-detail .product-assistant .assistant-content p {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0;
}
@media screen and (max-width: 991.98px) {
  .single-product-wrapper .product-detail .product-assistant .assistant-content p {
    font-size: 1rem;
  }
}
.single-product-wrapper .product-detail .product-assistant .assistant-content p a {
  font-size: 0.875rem;
  color: var(--color-secondary);
  margin-left: 0.625rem;
}
@media screen and (max-width: 991.98px) {
  .single-product-wrapper .product-detail .product-assistant .assistant-content p a {
    font-size: 0.75rem;
  }
}
.single-product-wrapper .product-detail .product-iconboxes {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.single-product-wrapper .product-detail .product-iconboxes > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
@media screen and (min-width: 992px) {
  .single-product-wrapper .product-detail .product-iconboxes > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}
.single-product-wrapper .product-detail .product-iconboxes .iconbox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-bottom: 0.9375rem;
}
.single-product-wrapper .product-detail .product-iconboxes .iconbox .icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 1.25rem;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 2.5rem;
          flex: 0 0 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.9375rem;
  border: 1px solid var(--color-info);
  border-radius: 50%;
}
.single-product-wrapper .product-detail .product-iconboxes .iconbox .entry-title {
  font-size: 0.8125rem;
  font-weight: 600;
  margin-bottom: 5px;
}
.single-product-wrapper .product-detail .product-iconboxes .iconbox p {
  font-size: 0.75rem;
  color: var(--color-text-description);
}
.single-product-wrapper .product-detail .product-extra-details {
  margin-bottom: 1.875rem;
}

.product-extra-details {
  position: relative;
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  overflow: hidden;
}
.product-extra-details table {
  font-size: 0.875rem;
  margin: 0;
}
.product-extra-details table tr th {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
}
.product-extra-details table tr td {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 1.25rem;
}
.product-extra-details table tr:nth-of-type(even) > * {
  background-color: #f6f6f8;
}
.product-extra-details table tr:last-child > * {
  border-bottom: 0;
}

.woocommerce-tabs {
  margin-top: 1.875rem;
}
@media screen and (min-width: 992px) {
  .woocommerce-tabs {
    margin-top: 3.75rem;
  }
}
.woocommerce-tabs ul.wc-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0 0 0.9375rem;
  list-style: none;
  border-bottom: 1px solid var(--color-info-dark);
}
@media screen and (min-width: 992px) {
  .woocommerce-tabs ul.wc-tabs {
    padding-bottom: 1.5rem;
  }
}
.woocommerce-tabs ul.wc-tabs li {
  margin-right: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .woocommerce-tabs ul.wc-tabs li {
    margin-right: 1.875rem;
  }
}
.woocommerce-tabs ul.wc-tabs li a {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-text-light);
}
@media screen and (min-width: 992px) {
  .woocommerce-tabs ul.wc-tabs li a {
    font-size: 1.125rem;
  }
}
.woocommerce-tabs ul.wc-tabs li.active > a {
  color: var(--color-text-primary);
}
.woocommerce-tabs .woocommerce-Tabs-panel {
  margin-top: 1.25rem;
}
.woocommerce-tabs .woocommerce-Tabs-panel.woocommerce-Tabs-panel--description {
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.woocommerce-tabs .woocommerce-Tabs-panel.woocommerce-Tabs-panel--description > h2 {
  display: none;
}
@media screen and (min-width: 992px) {
  .woocommerce-tabs .woocommerce-Tabs-panel.woocommerce-Tabs-panel--description {
    font-size: 1rem;
    line-height: 1.625;
  }
}

.shop-page-wrapper .woocommerce-breadcrumb + .klbth-banner {
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .shop-page-wrapper .woocommerce-breadcrumb + .klbth-banner {
    margin-bottom: 1.25rem;
  }
}

.before-shop-loop {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 0.9375rem;
  background-color: rgba(241, 243, 245, 0.5);
  border-radius: var(--theme-theme-radius-base);
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .before-shop-loop {
    padding: 1.25rem 1.875rem;
  }
}
.before-shop-loop .filter-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.8125rem;
  font-weight: 600;
  margin-right: auto;
}
.before-shop-loop .filter-button a {
  color: currentColor;
}
.before-shop-loop .woocommerce-result-count {
  font-size: 0.8125rem;
  margin-bottom: 0;
  margin-right: auto;
}
.before-shop-loop .sorting-products, .before-shop-loop .per-page-products {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.before-shop-loop .sorting-products > span, .before-shop-loop .per-page-products > span {
  font-size: 0.8125rem;
  color: var(--color-text-description);
  margin-right: 0.375rem;
}
.before-shop-loop .sorting-products select, .before-shop-loop .per-page-products select {
  font-size: 0.8125rem;
  font-weight: 600;
  padding-left: 0;
  height: auto;
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.before-shop-loop .sorting-products .select2-container, .before-shop-loop .per-page-products .select2-container {
  top: 1px;
}
.before-shop-loop .sorting-products .select2-selection, .before-shop-loop .per-page-products .select2-selection {
  height: auto;
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.before-shop-loop .sorting-products .select2-selection .select2-selection__clear, .before-shop-loop .per-page-products .select2-selection .select2-selection__clear {
  opacity: 0 !important;
  pointer-events: none !important;
}
.before-shop-loop .sorting-products .select2-selection .select2-selection__rendered, .before-shop-loop .per-page-products .select2-selection .select2-selection__rendered {
  font-size: 0.8125rem;
  font-weight: 600;
  padding-left: 0;
}
.before-shop-loop .sorting-products .select2-selection .select2-selection__arrow, .before-shop-loop .per-page-products .select2-selection .select2-selection__arrow {
  top: -0.5rem;
}
.before-shop-loop .per-page-products {
  margin-left: 0.9375rem;
  padding-left: 0.9375rem;
  border-left: 1px solid var(--color-info-dark);
}
@media screen and (max-width: 767.98px) {
  .before-shop-loop .per-page-products {
    display: none;
  }
}
.before-shop-loop .product-views-buttons {
  margin-left: 0.9375rem;
}
.before-shop-loop .product-views-buttons > * {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  color: var(--color-text-description);
  background-color: var(--color-body);
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-small);
  -webkit-box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
          box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.before-shop-loop .product-views-buttons > *:hover {
  color: var(--color-text-primary);
}
.before-shop-loop .product-views-buttons > * + * {
  margin-left: 3px;
}
.before-shop-loop .product-views-buttons > *.active {
  color: var(--color-text-primary);
  background-color: var(--color-info);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.before-shop-loop .product-views-type {
  margin-left: 0.9375rem;
}
@media screen and (max-width: 767.98px) {
  .before-shop-loop .product-views-type {
    display: none;
  }
}
.before-shop-loop .product-views-type span {
  display: block;
  font-size: 0.625rem;
  color: var(--color-text-light);
  margin-bottom: -2px;
}
.before-shop-loop .product-views-type p {
  font-size: 0.6875rem;
  color: var(--color-text-description);
  margin-bottom: 0;
}
.before-shop-loop .filter-wide-button {
  position: static;
  margin-left: 1.25rem;
  padding-left: 1.25rem;
  border-left: 1px solid var(--color-info-dark);
  max-height: 1.5rem;
}
.before-shop-loop .filter-wide-button > a {
  font-size: 0.8125rem;
  font-weight: 600;
  color: currentColor;
}
.before-shop-loop .filter-wide-button > a::after {
  margin-left: 0.375rem;
}
.before-shop-loop .filter-wide-button > a svg {
  position: relative;
  width: 1.375rem;
  margin-right: 0.375rem;
  top: -2px;
}
.before-shop-loop .filter-wide-button .filter-holder {
  width: 100%;
  top: 1.25rem !important;
  right: -1.875rem !important;
  border-radius: var(--theme-theme-radius-base);
  border-color: var(--color-info);
  -webkit-box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
          box-shadow: 0 25px 50px -12px rgba(33, 37, 41, 0.25);
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 1.875rem 2.5rem;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-bottom: 0;
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget.widget_price_filter {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 100%;
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  margin-top: -0.625rem;
  margin-bottom: 1.875rem;
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget.widget_price_filter .widget-body {
  padding-bottom: 0.9375rem;
  border-bottom: 1px solid var(--color-info-dark);
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget.widget_price_filter .price_slider_amount {
  margin-bottom: 0.9375rem;
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget.widget_price_filter .widget-title {
  display: none;
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget.widget_price_filter .button {
  font-size: 0.875rem;
  font-weight: 700;
  background-color: transparent;
  height: auto;
  padding: 0;
}
.before-shop-loop .filter-wide-button .filter-holder .filter-holder-wrapper .widget.widget_price_filter .price_label {
  font-weight: 700;
}

.cart-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (min-width: 1200px) {
  .cart-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .cart-wrapper .woocommerce-cart-form, .cart-wrapper #customer_details {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
  .cart-wrapper .cart-collaterals {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20rem;
            flex: 0 0 20rem;
    max-width: 20rem;
    margin-left: 1.875rem;
  }
  .cart-wrapper .order-review-wrapper {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25rem;
            flex: 0 0 25rem;
    max-width: 25rem;
    margin-left: 1.875rem;
  }
}

.woocommerce-cart-form {
  position: relative;
}
.woocommerce-cart-form table th {
  font-size: 0.75rem;
  font-weight: 600;
  border-top: 0;
  border-bottom: 1px solid var(--color-info-dark);
  color: var(--color-text-light);
}
@media screen and (max-width: 575.98px) {
  .woocommerce-cart-form table th.product-price, .woocommerce-cart-form table th.product-subtotal, .woocommerce-cart-form table th.product-remove {
    display: none !important;
  }
}
.woocommerce-cart-form table td {
  font-size: 0.875rem;
  vertical-align: middle;
}
.woocommerce-cart-form table td a {
  color: currentColor;
  text-decoration: none;
}
@media screen and (max-width: 575.98px) {
  .woocommerce-cart-form table td.product-remove {
    padding: 0 !important;
    width: 0 !important;
  }
  .woocommerce-cart-form table td.product-remove a {
    position: absolute;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    color: #fff;
    background-color: var(--color-danger);
    font-size: 0.625rem;
    left: 0;
    margin-top: -10px;
  }
}
.woocommerce-cart-form table td.product-thumbnail img {
  max-width: 4.375rem;
}
.woocommerce-cart-form table td .amount {
  font-weight: 500;
}
.woocommerce-cart-form table td.product-price .amount {
  color: var(--color-text-light);
}
@media screen and (max-width: 575.98px) {
  .woocommerce-cart-form table td.product-price {
    display: none !important;
  }
}
@media screen and (max-width: 575.98px) {
  .woocommerce-cart-form table td.product-subtotal {
    display: none !important;
  }
}
.woocommerce-cart-form table td .quantity {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
}
.woocommerce-cart-form table td .quantity .minus:after {
  left: 10px;
}
.woocommerce-cart-form table td .quantity .plus::before {
  top: 11px;
  left: 14px;
}
.woocommerce-cart-form table td .quantity .plus:after {
  right: 10px;
}
.woocommerce-cart-form table td .quantity-button {
  width: 1.875rem;
  height: 1.875rem;
}
.woocommerce-cart-form table td .qty {
  font-size: 0.875rem;
  width: 1.875rem;
  height: 1.875rem;
}
.woocommerce-cart-form table td.actions {
  padding-left: 0;
  padding-right: 0;
}
.woocommerce-cart-form table tbody {
  position: relative;
}
.woocommerce-cart-form table tbody tr {
  position: relative;
}
.woocommerce-cart-form table tbody td:first-child {
  padding-left: 0;
}
.woocommerce-cart-form table tbody td:last-child {
  padding-right: 0;
}
.woocommerce-cart-form .actions-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
@media screen and (min-width: 768px) {
  .woocommerce-cart-form .actions-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.woocommerce-cart-form .actions-wrapper .empty-cart-button {
  display: none;
}
.woocommerce-cart-form .actions-wrapper .coupon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.woocommerce-cart-form .actions-wrapper .coupon label {
  display: none;
}
.woocommerce-cart-form .actions-wrapper .coupon #coupon_code {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media screen and (min-width: 576px) {
  .woocommerce-cart-form .actions-wrapper .coupon #coupon_code {
    min-width: 17.5rem;
  }
}
.woocommerce-cart-form .actions-wrapper .coupon .button {
  white-space: nowrap;
  margin-left: 0.625rem;
  border: 0;
}
.woocommerce-cart-form .actions-wrapper input {
  height: 2.75rem;
}
.woocommerce-cart-form .actions-wrapper .button {
  font-size: 0.75rem;
  height: 2.75rem;
}
.woocommerce-cart-form .actions-wrapper .button[name=update_cart] {
  color: #fff;
  background-color: var(--color-secondary);
  border-color: transparent;
}
.woocommerce-cart-form .actions-wrapper .button[name=update_cart]:disabled {
  color: var(--color-text-light);
  background-color: transparent;
  opacity: 0.6;
}
@media screen and (max-width: 575.98px) {
  .woocommerce-cart-form .actions-wrapper .button[name=update_cart] {
    margin-top: 0.625rem;
    text-align: center;
  }
}

.cart-collaterals {
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-small);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  padding: 1.25rem;
}
.cart-collaterals .cart_totals > h2 {
  font-family: getFont(secondary);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 0.9375rem;
  border-bottom: 0.9375rem;
  border-bottom: 1px solid var(--color-info);
}
.cart-collaterals .shop_table {
  width: 100%;
}
.cart-collaterals .shop_table th {
  font-size: 0.8125rem;
  font-weight: 600;
}
.cart-collaterals .shop_table td {
  text-align: right;
}
.cart-collaterals .shop_table .amount {
  font-size: 0.9375rem;
}
.cart-collaterals .shop_table .cart-subtotal th, .cart-collaterals .shop_table .cart-subtotal td {
  padding-top: 0.4375rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid var(--color-info-dark);
}
.cart-collaterals .shop_table .order-total th, .cart-collaterals .shop_table .order-total td {
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}
.cart-collaterals .shop_table .order-total th strong, .cart-collaterals .shop_table .order-total td strong {
  font-weight: 600;
}
.cart-collaterals .shop_table .order-total .amount {
  font-size: 1.25rem;
}
.cart-collaterals .checkout-button {
  width: 100%;
}

.wc-proceed-to-checkout {
  padding-top: 0.9375rem;
  border-top: 1px solid var(--color-info-dark);
}
.wc-proceed-to-checkout .checkout-button {
  height: 3rem;
}

@media screen and (min-width: 1024px) {
  #customer_details {
    padding-right: 1.875rem;
  }
}
#customer_details .col-1, #customer_details .col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
#customer_details h3 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-info-dark);
}
#customer_details .woocommerce-billing-fields__field-wrapper, #customer_details .woocommerce-additional-fields__field-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -10px;
}
#customer_details .woocommerce-billing-fields__field-wrapper .form-row, #customer_details .woocommerce-additional-fields__field-wrapper .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 0;
  margin-right: 0;
}
#customer_details .woocommerce-billing-fields__field-wrapper .form-row label, #customer_details .woocommerce-additional-fields__field-wrapper .form-row label {
  font-size: 0.8125rem;
}
#customer_details .woocommerce-billing-fields__field-wrapper .form-row .woocommerce-input-wrapper > input, #customer_details .woocommerce-additional-fields__field-wrapper .form-row .woocommerce-input-wrapper > input {
  height: 2.875rem;
}
#customer_details .woocommerce-billing-fields__field-wrapper .form-row .woocommerce-input-wrapper > textarea, #customer_details .woocommerce-additional-fields__field-wrapper .form-row .woocommerce-input-wrapper > textarea {
  min-height: 120px;
  resize: none;
}
@media screen and (min-width: 768px) {
  #customer_details .woocommerce-billing-fields__field-wrapper .form-row#billing_first_name_field, #customer_details .woocommerce-billing-fields__field-wrapper .form-row#billing_last_name_field, #customer_details .woocommerce-billing-fields__field-wrapper .form-row#billing_phone_field, #customer_details .woocommerce-billing-fields__field-wrapper .form-row#billing_email_field, #customer_details .woocommerce-additional-fields__field-wrapper .form-row#billing_first_name_field, #customer_details .woocommerce-additional-fields__field-wrapper .form-row#billing_last_name_field, #customer_details .woocommerce-additional-fields__field-wrapper .form-row#billing_phone_field, #customer_details .woocommerce-additional-fields__field-wrapper .form-row#billing_email_field {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
}
#customer_details .woocommerce-additional-fields {
  margin-top: 1.875rem;
}

.order-review-wrapper {
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  padding: 0.9375rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media screen and (min-width: 1024px) {
  .order-review-wrapper {
    padding: 1.875rem;
  }
}
@media screen and (max-width: 767.98px) {
  .order-review-wrapper {
    margin-top: 1.25rem;
  }
}
.order-review-wrapper h3 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-info-dark);
}
.order-review-wrapper .shop_table {
  width: 100%;
  font-size: 0.8125rem;
}
.order-review-wrapper .shop_table .amount {
  font-size: 0.9375rem;
}
.order-review-wrapper .shop_table th {
  font-weight: 600;
}
.order-review-wrapper .shop_table thead th {
  color: var(--color-text-light);
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--color-info-dark);
}
.order-review-wrapper .shop_table thead th:last-child {
  text-align: right;
}
.order-review-wrapper .shop_table tbody td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.order-review-wrapper .shop_table tbody td.product-name {
  font-size: 0.8125rem;
  padding-right: 1.25rem;
}
.order-review-wrapper .shop_table tbody td:last-child {
  text-align: right;
}
.order-review-wrapper .shop_table tfoot tr th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--color-text-light);
  border-top: 1px solid var(--color-info-dark);
}
.order-review-wrapper .shop_table tfoot tr td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: right;
  border-top: 1px solid var(--color-info-dark);
}
.order-review-wrapper .shop_table tfoot tr.order-total .amount {
  font-size: 1.25rem;
  font-weight: 600;
}
.order-review-wrapper .woocommerce-checkout-payment {
  padding-top: 0.9375rem;
  border-top: 1px solid var(--color-info-dark);
}
.order-review-wrapper .woocommerce-checkout-payment ul.payment_methods {
  margin: 0;
  padding: 0;
  list-style: none;
}
.order-review-wrapper .woocommerce-checkout-payment ul.payment_methods label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 1.25rem;
  color: var(--color-primary);
}
.order-review-wrapper .woocommerce-checkout-payment ul.payment_methods .payment_box p {
  font-size: 0.75rem;
  line-height: 1.6;
  color: var(--color-text-light);
}
.order-review-wrapper .woocommerce-checkout-payment .woocommerce-privacy-policy-text {
  margin-top: 0.9375rem;
}
.order-review-wrapper .woocommerce-checkout-payment .woocommerce-privacy-policy-text p {
  font-size: 0.75rem;
  line-height: 1.6;
}
.order-review-wrapper .woocommerce-checkout-payment .woocommerce-privacy-policy-text p a {
  color: var(--color-danger);
  font-weight: 500;
  text-decoration: underline;
}
.order-review-wrapper .woocommerce-checkout-payment .validate-required {
  margin-left: 0;
  margin-right: 0;
}
.order-review-wrapper .woocommerce-checkout-payment .validate-required .woocommerce-form__label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.75rem;
}
.order-review-wrapper .woocommerce-checkout-payment .validate-required .woocommerce-form__label input {
  position: relative;
  top: -9px;
}
.order-review-wrapper .woocommerce-checkout-payment .validate-required .woocommerce-form__label span {
  position: relative;
  line-height: 1.5;
  padding-left: 0.625rem;
}
.order-review-wrapper .woocommerce-checkout-payment .validate-required .woocommerce-form__label span a {
  font-weight: 500;
  color: var(--color-danger);
  text-decoration: underline;
}
.order-review-wrapper .woocommerce-checkout-payment #place_order {
  width: 100%;
  height: 3rem;
  color: #fff;
  background-color: var(--color-danger);
  border-color: transparent;
}
.order-review-wrapper .woocommerce-checkout-payment #place_order:hover {
  background-color: var(--color-danger-dark);
}

.my-account-wrapper .my-account-title {
  padding: 1.25rem 0;
  border-bottom: 1px solid var(--color-info-dark);
}
.my-account-wrapper .my-account-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-top: 1.25rem;
}
@media screen and (min-width: 992px) {
  .my-account-wrapper .my-account-inner {
    padding-top: 1.875rem;
  }
}
.my-account-wrapper .my-account-navigation {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  border: 1px solid var(--color-info-dark);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  border-radius: var(--theme-theme-radius-base);
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .my-account-wrapper .my-account-navigation {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 300px;
            flex: 0 0 300px;
    max-width: 300px;
  }
}
.my-account-wrapper .my-account-navigation .account-toggle-menu {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid var(--color-info-dark);
  height: 36px;
  padding: 4px 15px;
  margin-top: 0.625rem;
}
.my-account-wrapper .my-account-navigation .account-toggle-menu svg {
  margin-right: 8px;
}
@media screen and (min-width: 1024px) {
  .my-account-wrapper .my-account-navigation .account-toggle-menu {
    display: none;
  }
}
.my-account-wrapper .my-account-navigation .woocommerce-MyAccount-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.my-account-wrapper .my-account-navigation .woocommerce-MyAccount-navigation ul li + li {
  border-top: 1px solid var(--color-info-dark);
}
.my-account-wrapper .my-account-navigation .woocommerce-MyAccount-navigation ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 600;
  padding: 0.625rem 1.25rem;
  color: var(--color-text-primary);
  -webkit-transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.my-account-wrapper .my-account-navigation .woocommerce-MyAccount-navigation ul li a:hover {
  background-color: var(--color-info-light);
}
.my-account-wrapper .my-account-navigation .woocommerce-MyAccount-navigation ul li.is-active a {
  color: #fff;
  background-color: var(--color-primary);
}
.my-account-wrapper .woocommerce-MyAccount-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .my-account-wrapper .woocommerce-MyAccount-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
    margin-left: auto;
  }
}
.my-account-wrapper .woocommerce-MyAccount-content a {
  font-weight: 500;
  text-decoration: none;
  color: var(--color-primary);
}
.my-account-wrapper .my-account-user {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.my-account-wrapper .my-account-user .user-avatar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 3.25rem;
          flex: 0 0 3.25rem;
  max-width: 3.25rem;
  height: 3.25rem;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: var(--theme-theme-radius-small);
  background-color: var(--color-info);
  margin-right: 0.9375rem;
}
.my-account-wrapper .my-account-user .user-detail span {
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--color-text-light);
  margin-bottom: -2px;
}
.my-account-wrapper .my-account-user .user-detail p {
  font-weight: 600;
  margin-bottom: 0;
}
.my-account-wrapper .my-account-content {
  padding-top: 1.875rem;
  margin-top: 1.875rem;
  border-top: 1px solid var(--color-info-dark);
}

/*------------------------------------ 
    12 - Sidebar 
------------------------------------*/
.widget {
  margin-bottom: 2.5rem;
}
.widget:last-child {
  margin-bottom: 0;
}
.widget .widget-title {
  font-size: 0.9375rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.widget_nav_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget_nav_menu ul li {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.widget_nav_menu ul li a {
  font-size: 0.875rem;
  color: currentColor;
}
.widget_nav_menu ul li a:hover {
  text-decoration: underline;
}

.widget_contact .company-phone {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .widget_contact .company-phone {
    font-size: 1.5rem;
  }
}
.widget_contact .company-works {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.9375rem;
}
.widget_contact .company-works p {
  margin-bottom: 0;
  opacity: 0.6;
}
.widget_contact .company-mail {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-primary);
}
.widget_contact .company-mail:hover {
  text-decoration: underline;
}

.widget_about_company .company-logo {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 992px) {
  .widget_about_company .company-logo {
    margin-bottom: 1.875rem;
  }
}
.widget_about_company .company-logo img {
  height: 2.375rem;
}
.widget_about_company .company-content .entry-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .widget_about_company .company-content .entry-title {
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
  }
}
.widget_about_company .company-content .entry-description {
  font-size: 0.875rem;
}
.widget_about_company .company-content .entry-description p {
  opacity: 0.6;
}
.widget_about_company .company-content a {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--color-primary);
}
.widget_about_company .company-content a:hover {
  text-decoration: underline;
}

.widget_iconbox ul {
  border: 1px solid var(--color-info-dark);
  border-radius: var(--theme-theme-radius-base);
  -webkit-box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
          box-shadow: 1px 2px 4px rgba(33, 37, 41, 0.05);
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget_iconbox ul li {
  padding: 1.375rem 1.25rem;
}
.widget_iconbox ul li + li {
  border-top: 1px solid var(--color-info);
}

.widget_testimonial .widget-body {
  background-color: var(--color-review-lighter);
  border-radius: var(--theme-theme-radius-base);
  padding: 1.25rem;
}
@media screen and (min-width: 992px) {
  .widget_testimonial .widget-body {
    padding: 1.875rem;
  }
}
.widget_testimonial .widget-body .slick-dots {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-left: 0;
}
.widget_testimonial .widget-body .testimonial-inner {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.widget_testimonial .widget-body .testimonial-inner .customer-avatar {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 5.375rem;
          flex: 0 0 5.375rem;
  max-width: 5.375rem;
  margin-right: 0.9375rem;
  border-radius: 50%;
  margin-bottom: 0.9375rem;
  overflow: hidden;
}
.widget_testimonial .widget-body .testimonial-inner .customer-detail .stars {
  font-size: 0.8125rem;
  line-height: normal;
  color: var(--color-review-dark);
  margin-bottom: 0.1875rem;
}
.widget_testimonial .widget-body .testimonial-inner .customer-detail .customer-name {
  display: block;
  font-size: 0.9375rem;
  font-weight: 600;
  margin-bottom: -3px;
}
.widget_testimonial .widget-body .testimonial-inner .customer-detail .customer-mission {
  font-size: 0.75rem;
  opacity: 0.5;
  margin-bottom: 0.5rem;
}
.widget_testimonial .widget-body .testimonial-inner .customer-detail .customer-description {
  font-size: 0.8125rem;
  line-height: 1.6153846154;
}

.widget_posts ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget_posts ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
.widget_posts ul li:last-child {
  margin-bottom: 0;
}
.widget_posts ul li .entry-media {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 4.125rem;
          flex: 0 0 4.125rem;
  max-width: 4.125rem;
  height: 4.125rem;
  margin-right: 0.9375rem;
  margin-bottom: 0;
}
.widget_posts ul li .entry-media span {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  background-color: var(--color-secondary);
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 1;
}
.widget_posts ul li .entry-media a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.widget_posts ul li .entry-media a img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.widget_posts ul li .entry-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.widget_posts ul li .entry-wrapper .entry-title {
  font-size: 0.875rem;
}

.widget_social_media .social-media li + li {
  margin-top: 0.1875rem;
}
.widget_social_media .social-media a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 0.9375rem;
  border-radius: var(--theme-theme-radius-small);
}
.widget_social_media .social-media a .social-icon {
  width: 1.25rem;
  margin-right: 0.625rem;
}
.widget_social_media .social-media a .social-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 0.75rem;
  font-weight: 600;
}

.sidebar-mobile-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1.875rem;
  padding-bottom: 1.75rem;
  border-bottom: 1px solid var(--color-info-dark);
}
@media screen and (min-width: 1024px) {
  .sidebar-mobile-header {
    display: none;
  }
}
.sidebar-mobile-header .entry-title {
  font-size: 0.875rem;
  margin-bottom: 0;
}
.sidebar-mobile-header .close-sidebar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.9375rem;
  width: 1.875rem;
  height: 1.875rem;
  color: var(--color-text-primary);
  border: 1px solid var(--color-info-dark);
  border-radius: 50%;
}

.widget-checkbox-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget-checkbox-list ul li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}
.widget-checkbox-list ul li + li {
  margin-top: 0.5rem;
}
.widget-checkbox-list ul li a {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 0.8125rem;
  color: var(--color-text);
  text-decoration: none;
}
.widget-checkbox-list ul li a input[type=checkbox] {
  display: none;
}
.widget-checkbox-list ul li a input[type=checkbox]:checked + label {
  color: var(--color-primary);
  font-weight: 500;
}
.widget-checkbox-list ul li a input[type=checkbox]:checked + label span {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.widget-checkbox-list ul li a input[type=checkbox]:checked + label span::before {
  opacity: 1;
}
.widget-checkbox-list ul li a label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  font-size: 0.875rem;
  cursor: pointer;
  margin-bottom: 0;
}
.widget-checkbox-list ul li a label span {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 1.125rem;
          flex: 0 0 1.125rem;
  max-width: 1.125rem;
  width: 100%;
  height: 1.125rem;
  margin-right: 0.9375rem;
  top: -1px;
  border-radius: var(--theme-theme-radius-xsmall);
  border: 1px solid var(--color-form-border);
  -webkit-box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
          box-shadow: 0 1px 2px 0 rgba(33, 37, 41, 0.05);
}
.widget-checkbox-list ul li a label span::before {
  position: relative;
  content: "✔";
  font-size: 0.75rem;
  line-height: 1;
  left: 0.5px;
  text-shadow: 0px 0px 0px;
  opacity: 0;
}
.widget-checkbox-list ul li a::after {
  display: none;
}

.price_slider_wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.price_slider_wrapper .price_slider_amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-bottom: 1.25rem;
}
.price_slider_wrapper .price_slider_amount .price_label {
  font-size: 0.875rem;
  color: var(--color-text-primary);
  margin-right: auto;
}
.price_slider_wrapper .price_slider_amount .price_label span {
  color: var(--color-text-primary);
}
.price_slider_wrapper .price_slider_amount button {
  font-size: 0.6875rem;
  text-transform: uppercase;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  height: 2.125rem;
  background-color: #f2f3f5;
  border-color: transparent;
  color: var(--color-text-primary);
}

.ui-widget-content {
  position: relative;
  height: 2px;
  background-color: var(--color-info-light);
  margin-bottom: 1.5625rem;
  border-radius: 2px;
}
.ui-widget-content .ui-slider-range {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border: 0;
  background-color: var(--color-primary);
  border-radius: 8px;
  z-index: 1;
}
.ui-widget-content .ui-slider-handle {
  left: 0%;
  position: absolute;
  z-index: 2;
  outline: 0;
  cursor: pointer;
  background-color: var(--color-primary);
  border-radius: 100%;
  border: 0;
  height: 14px;
  top: -6px;
  width: 14px;
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}
.ui-widget-content .ui-slider-handle:last-child {
  left: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

/* filtered sidebar */
@media screen and (max-width: 1023.98px) {
  .filtered-sidebar {
    position: fixed !important;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 90%;
            flex: 0 0 90%;
    max-width: 90%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    margin-top: 0;
    padding: 1.25rem 1.25rem 0;
    background-color: #fff;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    z-index: 10001;
  }
  .filtered-sidebar::before {
    display: none;
  }
}

.widget_search_service .entry-description {
  font-size: 0.75rem;
  color: var(--color-text-description);
}

/*------------------------------------ 
    13 - Footer 
------------------------------------*/
.site-footer {
  position: relative;
  margin-top: 2.5rem;
}
@media screen and (min-width: 992px) {
  .site-footer {
    margin-top: 5.625rem;
  }
}
.site-footer .footer-row.border-full {
  border-top: 1px solid;
}
.site-footer .footer-row.border-boxed .footer-inner {
  border-top: 1px solid;
}
.site-footer .footer-row.dark {
  color: var(--color-text-primary);
  border-color: rgba(0, 0, 0, 0.1);
}
.site-footer .footer-row.dark .footer-inner {
  border-color: rgba(0, 0, 0, 0.1);
}
.site-footer .footer-row.dark .custom-column {
  border-right-color: rgba(0, 0, 0, 0.1) !important;
}
.site-footer .footer-row.dark .sub-banners ul li + li {
  border-top-color: rgba(0, 0, 0, 0.1);
  border-left-color: rgba(0, 0, 0, 0.1);
}
.site-footer .footer-row.light {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.15);
}
.site-footer .footer-row.light .footer-inner {
  border-color: rgba(255, 255, 255, 0.15);
}
.site-footer .footer-row.light .custom-column {
  border-right-color: rgba(255, 255, 255, 0.15) !important;
}
.site-footer .footer-row.light .sub-banners ul li + li {
  border-top-color: rgba(255, 255, 255, 0.15);
  border-left-color: rgba(255, 255, 255, 0.15);
}
.site-footer .footer-row.custom-background-light {
  background-color: #F6F7F9;
}
.site-footer .footer-row.custom-background-dark {
  background-color: #212529;
}
.site-footer .klbth-newsletter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 992px) {
  .site-footer .klbth-newsletter {
    -ms-flex-flow: wrap;
        flex-flow: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .site-footer .klbth-newsletter .column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .site-footer .klbth-newsletter .column:nth-child(1) {
    padding-right: 1.25rem;
  }
  .site-footer .klbth-newsletter .column:nth-child(2) {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-left: 1.25rem;
  }
}
.site-footer .klbth-newsletter .klbth-newsletter-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.site-footer .klbth-newsletter .klbth-newsletter-text .text-icon {
  display: none;
  font-size: 3.75rem;
  line-height: 1.1;
  color: var(--color-text-light);
  margin-right: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .site-footer .klbth-newsletter .klbth-newsletter-text .text-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 3.75rem;
    line-height: 1.1;
    margin-right: 1.875rem;
  }
}
.site-footer .klbth-newsletter .klbth-newsletter-text .text-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.site-footer .klbth-newsletter .klbth-newsletter-text .text-body .entry-subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 0.625rem;
}
.site-footer .klbth-newsletter .klbth-newsletter-text .text-body .entry-title {
  font-size: 1.375rem;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .site-footer .klbth-newsletter .klbth-newsletter-text .text-body .entry-title {
    font-size: 1.625rem;
    line-height: normal;
  }
}
.site-footer .klbth-newsletter .klbth-newsletter-text .text-body .entry-description {
  font-size: 0.875rem;
  max-width: 360px;
  color: var(--color-text-description);
}
.site-footer .klbth-newsletter .klbth-newsletter-form form {
  position: relative;
}
@media screen and (min-width: 992px) {
  .site-footer .klbth-newsletter .klbth-newsletter-form form {
    max-width: 480px;
  }
}
.site-footer .klbth-newsletter .klbth-newsletter-form form input {
  height: 3.25rem;
}
.site-footer .klbth-newsletter .klbth-newsletter-form form button {
  position: absolute;
  font-size: 0.875rem;
  height: 2.75rem;
  top: 4px;
  right: 4px;
  border-radius: var(--theme-theme-radius-small);
}
.site-footer .klbth-newsletter .klbth-newsletter-form .privacy-policy {
  display: block;
  font-size: 0.75rem;
  margin-top: 0.75rem;
}
.site-footer .klbth-newsletter .klbth-newsletter-form .privacy-policy a {
  font-weight: 500;
  text-decoration: underline;
  color: var(--color-primary);
}
.site-footer .footer-widgets .footer-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-widgets .footer-sidebar {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.site-footer .footer-widgets .footer-sidebar > * {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-widgets .footer-sidebar > *.custom-column {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 380px;
            flex: 0 0 380px;
    max-width: 380px;
    border-right: 1px solid;
    padding-right: 2.5rem;
  }
}
.site-footer .footer-widgets .footer-sidebar > *.custom-column + .widgets-column {
  margin-top: 1.25rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-widgets .footer-sidebar > *.custom-column + .widgets-column {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-top: 0;
    padding-left: 4.6875rem;
  }
}
@media screen and (max-width: 991.98px) {
  .site-footer .footer-widgets .footer-sidebar .widgets-column .widget {
    margin-bottom: 1.875rem;
  }
}
.site-footer .subfooter .sub-banners ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-footer .subfooter .sub-banners ul li {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .site-footer .subfooter .sub-banners ul li {
    width: auto;
  }
}
.site-footer .subfooter .sub-banners ul li + li {
  border-top: 1px solid;
}
@media screen and (min-width: 992px) {
  .site-footer .subfooter .sub-banners ul li + li {
    border-top: 0;
    border-left: 1px solid;
    padding-left: 1.875rem;
    margin-left: 1.875rem;
  }
}
.site-footer .footer-copyright .footer-inner {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-copyright .footer-inner {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}
.site-footer .footer-copyright .copyright-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-copyright .copyright-row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .site-footer .footer-copyright .copyright-row:nth-child(3) {
    margin-top: 1.875rem !important;
  }
}
.site-footer .footer-copyright .copyright-row + .copyright-row {
  margin-top: 0.3125rem;
}
.site-footer .footer-copyright .copyright-row .column {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-copyright .copyright-row .column {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
            flex: 0 0 45%;
    max-width: 45%;
  }
  .site-footer .footer-copyright .copyright-row .column:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.site-footer .footer-copyright .footer-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.site-footer .footer-copyright .footer-menu ul li {
  margin-right: 1.25rem;
}
.site-footer .footer-copyright .footer-menu ul li:last-child {
  margin-right: 0;
}
.site-footer .footer-copyright .footer-menu ul li a {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: 500;
  color: currentColor;
}
.site-footer .footer-copyright .footer-menu ul li a:hover {
  text-decoration: underline;
}
.site-footer .footer-copyright .site-copyright {
  font-size: 0.8125rem;
  line-height: normal;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-copyright .site-copyright {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.site-footer .footer-copyright .site-copyright p {
  opacity: 0.5;
  margin-bottom: 0;
}
.site-footer .footer-copyright .site-copyright p a {
  font-weight: 600;
  color: currentColor;
}
.site-footer .footer-copyright .mobile-app-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 1.875rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-copyright .mobile-app-content {
    margin-top: 0;
  }
}
.site-footer .footer-copyright .mobile-app-content span {
  font-size: 0.8125rem;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 992px) {
  .site-footer .footer-copyright .mobile-app-content span {
    margin-right: 0.625rem;
    margin-bottom: 0;
  }
}

/*------------------------------------ 
    14 - Social Media 
------------------------------------*/
.social-media {
  margin: 0;
  padding: 0;
  list-style: none;
}
.social-media.social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 3px;
}
.social-media.social-share li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
}
.social-media a {
  -webkit-transition: all 0.15s cubic-bezier(0.28, 0.12, 0.22, 1);
  transition: all 0.15s cubic-bezier(0.28, 0.12, 0.22, 1);
}
.social-media.colored a {
  color: #fff;
}
.social-media.colored a.facebook {
  background-color: #1877f2;
}
.social-media.colored a.facebook:hover {
  background-color: #0b5fcc;
}
.social-media.colored a.twitter {
  background-color: #1da1f2;
}
.social-media.colored a.twitter:hover {
  background-color: #0c85d0;
}
.social-media.colored a.twitch {
  background-color: #9146ff;
}
.social-media.colored a.twitch:hover {
  background-color: #7313ff;
}
.social-media.colored a.fivehundredpx {
  background-color: #0099e5;
}
.social-media.colored a.fivehundredpx:hover {
  background-color: #0077b2;
}
.social-media.colored a.airbnb {
  background-color: #fd5c63;
}
.social-media.colored a.airbnb:hover {
  background-color: #fc2a33;
}
.social-media.colored a.amazon {
  background-color: #ff9900;
}
.social-media.colored a.amazon:hover {
  background-color: #cc7a00;
}
.social-media.colored a.behance {
  background-color: #1769ff;
}
.social-media.colored a.behance:hover {
  background-color: #0050e3;
}
.social-media.colored a.blogger {
  background-color: #f57d00;
}
.social-media.colored a.blogger:hover {
  background-color: #c26300;
}
.social-media.colored a.deviantart {
  background-color: #05cc47;
}
.social-media.colored a.deviantart:hover {
  background-color: #049a36;
}
.social-media.colored a.dropbox {
  background-color: #007ee5;
}
.social-media.colored a.dropbox:hover {
  background-color: #0062b2;
}
.social-media.colored a.etsy {
  background-color: #d5641c;
}
.social-media.colored a.etsy:hover {
  background-color: #a84f16;
}
.social-media.colored a.fedex {
  background-color: #4d148c;
}
.social-media.colored a.fedex:hover {
  background-color: #340e5f;
}
.social-media.colored a.flickr {
  background-color: #ff0084;
}
.social-media.colored a.flickr:hover {
  background-color: #cc006a;
}
.social-media.colored a.flipboard {
  background-color: #e12828;
}
.social-media.colored a.flipboard:hover {
  background-color: #bc1a1a;
}
.social-media.colored a.pocket {
  background-color: #ef4056;
}
.social-media.colored a.pocket:hover {
  background-color: #e9132e;
}
.social-media.colored a.google {
  background-color: #4285f4;
}
.social-media.colored a.google:hover {
  background-color: #1266f1;
}
.social-media.colored a.instagram {
  background-color: #fd1d1d;
}
.social-media.colored a.instagram:hover {
  background-color: #e50202;
}
.social-media.colored a.linkedin {
  background-color: #0077b5;
}
.social-media.colored a.linkedin:hover {
  background-color: #005582;
}
.social-media.colored a.medium {
  background-color: #00ab6c;
}
.social-media.colored a.medium:hover {
  background-color: #00784c;
}
.social-media.colored a.odnoklassniki {
  background-color: #ed812b;
}
.social-media.colored a.odnoklassniki:hover {
  background-color: #d36812;
}
.social-media.colored a.paypal {
  background-color: #003087;
}
.social-media.colored a.paypal:hover {
  background-color: #001e54;
}
.social-media.colored a.pinterest {
  background-color: #e60023;
}
.social-media.colored a.pinterest:hover {
  background-color: #b3001b;
}
.social-media.colored a.reddit {
  background-color: #ff4500;
}
.social-media.colored a.reddit:hover {
  background-color: #cc3700;
}
.social-media.colored a.shopify {
  background-color: #96bf48;
}
.social-media.colored a.shopify:hover {
  background-color: #7a9d37;
}
.social-media.colored a.skype {
  background-color: #00aff0;
}
.social-media.colored a.skype:hover {
  background-color: #008abd;
}
.social-media.colored a.slack {
  background-color: #6ecadc;
}
.social-media.colored a.slack:hover {
  background-color: #45bbd2;
}
.social-media.colored a.soundcloud {
  background-color: #ff8800;
}
.social-media.colored a.soundcloud:hover {
  background-color: #cc6d00;
}
.social-media.colored a.spotify {
  background-color: #1db954;
}
.social-media.colored a.spotify:hover {
  background-color: #168d40;
}
.social-media.colored a.stripe {
  background-color: #00afe1;
}
.social-media.colored a.stripe:hover {
  background-color: #0087ae;
}
.social-media.colored a.telegram {
  background-color: #0088cc;
}
.social-media.colored a.telegram:hover {
  background-color: #006699;
}
.social-media.colored a.trello {
  background-color: #0079bf;
}
.social-media.colored a.trello:hover {
  background-color: #00598c;
}
.social-media.colored a.tumblr {
  background-color: #35465c;
}
.social-media.colored a.tumblr:hover {
  background-color: #222d3c;
}
.social-media.colored a.tiktok {
  background-color: #000;
}
.social-media.colored a.tiktok:hover {
  background-color: black;
}
.social-media.colored a.viber {
  background-color: #59267c;
}
.social-media.colored a.viber:hover {
  background-color: #3d1a55;
}
.social-media.colored a.vimeo {
  background-color: #1ab7ea;
}
.social-media.colored a.vimeo:hover {
  background-color: #1295bf;
}
.social-media.colored a.vine {
  background-color: #00b488;
}
.social-media.colored a.vine:hover {
  background-color: #008161;
}
.social-media.colored a.vk {
  background-color: #45668e;
}
.social-media.colored a.vk:hover {
  background-color: #344d6c;
}
.social-media.colored a.whatsapp {
  background-color: #25d366;
}
.social-media.colored a.whatsapp:hover {
  background-color: #1da851;
}
.social-media.colored a.youtube {
  background-color: #ff0000;
}
.social-media.colored a.youtube:hover {
  background-color: #cc0000;
}
.social-media.light a.facebook {
  color: #1877f2;
  background-color: rgba(24, 119, 242, 0.08);
}
.social-media.light a.facebook:hover {
  color: #fff;
  background-color: #1877f2;
}
.social-media.light a.twitter {
  color: #1da1f2;
  background-color: rgba(29, 161, 242, 0.08);
}
.social-media.light a.twitter:hover {
  color: #fff;
  background-color: #1da1f2;
}
.social-media.light a.twitch {
  color: #9146ff;
  background-color: rgba(145, 70, 255, 0.08);
}
.social-media.light a.twitch:hover {
  color: #fff;
  background-color: #9146ff;
}
.social-media.light a.fivehundredpx {
  color: #0099e5;
  background-color: rgba(0, 153, 229, 0.08);
}
.social-media.light a.fivehundredpx:hover {
  color: #fff;
  background-color: #0099e5;
}
.social-media.light a.airbnb {
  color: #fd5c63;
  background-color: rgba(253, 92, 99, 0.08);
}
.social-media.light a.airbnb:hover {
  color: #fff;
  background-color: #fd5c63;
}
.social-media.light a.amazon {
  color: #ff9900;
  background-color: rgba(255, 153, 0, 0.08);
}
.social-media.light a.amazon:hover {
  color: #fff;
  background-color: #ff9900;
}
.social-media.light a.behance {
  color: #1769ff;
  background-color: rgba(23, 105, 255, 0.08);
}
.social-media.light a.behance:hover {
  color: #fff;
  background-color: #1769ff;
}
.social-media.light a.blogger {
  color: #f57d00;
  background-color: rgba(245, 125, 0, 0.08);
}
.social-media.light a.blogger:hover {
  color: #fff;
  background-color: #f57d00;
}
.social-media.light a.deviantart {
  color: #05cc47;
  background-color: rgba(5, 204, 71, 0.08);
}
.social-media.light a.deviantart:hover {
  color: #fff;
  background-color: #05cc47;
}
.social-media.light a.dropbox {
  color: #007ee5;
  background-color: rgba(0, 126, 229, 0.08);
}
.social-media.light a.dropbox:hover {
  color: #fff;
  background-color: #007ee5;
}
.social-media.light a.etsy {
  color: #d5641c;
  background-color: rgba(213, 100, 28, 0.08);
}
.social-media.light a.etsy:hover {
  color: #fff;
  background-color: #d5641c;
}
.social-media.light a.fedex {
  color: #4d148c;
  background-color: rgba(77, 20, 140, 0.08);
}
.social-media.light a.fedex:hover {
  color: #fff;
  background-color: #4d148c;
}
.social-media.light a.flickr {
  color: #ff0084;
  background-color: rgba(255, 0, 132, 0.08);
}
.social-media.light a.flickr:hover {
  color: #fff;
  background-color: #ff0084;
}
.social-media.light a.flipboard {
  color: #e12828;
  background-color: rgba(225, 40, 40, 0.08);
}
.social-media.light a.flipboard:hover {
  color: #fff;
  background-color: #e12828;
}
.social-media.light a.pocket {
  color: #ef4056;
  background-color: rgba(239, 64, 86, 0.08);
}
.social-media.light a.pocket:hover {
  color: #fff;
  background-color: #ef4056;
}
.social-media.light a.google {
  color: #4285f4;
  background-color: rgba(66, 133, 244, 0.08);
}
.social-media.light a.google:hover {
  color: #fff;
  background-color: #4285f4;
}
.social-media.light a.instagram {
  color: #fd1d1d;
  background-color: rgba(253, 29, 29, 0.08);
}
.social-media.light a.instagram:hover {
  color: #fff;
  background-color: #fd1d1d;
}
.social-media.light a.linkedin {
  color: #0077b5;
  background-color: rgba(0, 119, 181, 0.08);
}
.social-media.light a.linkedin:hover {
  color: #fff;
  background-color: #0077b5;
}
.social-media.light a.medium {
  color: #00ab6c;
  background-color: rgba(0, 171, 108, 0.08);
}
.social-media.light a.medium:hover {
  color: #fff;
  background-color: #00ab6c;
}
.social-media.light a.odnoklassniki {
  color: #ed812b;
  background-color: rgba(237, 129, 43, 0.08);
}
.social-media.light a.odnoklassniki:hover {
  color: #fff;
  background-color: #ed812b;
}
.social-media.light a.paypal {
  color: #003087;
  background-color: rgba(0, 48, 135, 0.08);
}
.social-media.light a.paypal:hover {
  color: #fff;
  background-color: #003087;
}
.social-media.light a.pinterest {
  color: #e60023;
  background-color: rgba(230, 0, 35, 0.08);
}
.social-media.light a.pinterest:hover {
  color: #fff;
  background-color: #e60023;
}
.social-media.light a.reddit {
  color: #ff4500;
  background-color: rgba(255, 69, 0, 0.08);
}
.social-media.light a.reddit:hover {
  color: #fff;
  background-color: #ff4500;
}
.social-media.light a.shopify {
  color: #96bf48;
  background-color: rgba(150, 191, 72, 0.08);
}
.social-media.light a.shopify:hover {
  color: #fff;
  background-color: #96bf48;
}
.social-media.light a.skype {
  color: #00aff0;
  background-color: rgba(0, 175, 240, 0.08);
}
.social-media.light a.skype:hover {
  color: #fff;
  background-color: #00aff0;
}
.social-media.light a.slack {
  color: #6ecadc;
  background-color: rgba(110, 202, 220, 0.08);
}
.social-media.light a.slack:hover {
  color: #fff;
  background-color: #6ecadc;
}
.social-media.light a.soundcloud {
  color: #ff8800;
  background-color: rgba(255, 136, 0, 0.08);
}
.social-media.light a.soundcloud:hover {
  color: #fff;
  background-color: #ff8800;
}
.social-media.light a.spotify {
  color: #1db954;
  background-color: rgba(29, 185, 84, 0.08);
}
.social-media.light a.spotify:hover {
  color: #fff;
  background-color: #1db954;
}
.social-media.light a.stripe {
  color: #00afe1;
  background-color: rgba(0, 175, 225, 0.08);
}
.social-media.light a.stripe:hover {
  color: #fff;
  background-color: #00afe1;
}
.social-media.light a.telegram {
  color: #0088cc;
  background-color: rgba(0, 136, 204, 0.08);
}
.social-media.light a.telegram:hover {
  color: #fff;
  background-color: #0088cc;
}
.social-media.light a.trello {
  color: #0079bf;
  background-color: rgba(0, 121, 191, 0.08);
}
.social-media.light a.trello:hover {
  color: #fff;
  background-color: #0079bf;
}
.social-media.light a.tumblr {
  color: #35465c;
  background-color: rgba(53, 70, 92, 0.08);
}
.social-media.light a.tumblr:hover {
  color: #fff;
  background-color: #35465c;
}
.social-media.light a.tiktok {
  color: #000;
  background-color: rgba(0, 0, 0, 0.08);
}
.social-media.light a.tiktok:hover {
  color: #fff;
  background-color: #000;
}
.social-media.light a.viber {
  color: #59267c;
  background-color: rgba(89, 38, 124, 0.08);
}
.social-media.light a.viber:hover {
  color: #fff;
  background-color: #59267c;
}
.social-media.light a.vimeo {
  color: #1ab7ea;
  background-color: rgba(26, 183, 234, 0.08);
}
.social-media.light a.vimeo:hover {
  color: #fff;
  background-color: #1ab7ea;
}
.social-media.light a.vine {
  color: #00b488;
  background-color: rgba(0, 180, 136, 0.08);
}
.social-media.light a.vine:hover {
  color: #fff;
  background-color: #00b488;
}
.social-media.light a.vk {
  color: #45668e;
  background-color: rgba(69, 102, 142, 0.08);
}
.social-media.light a.vk:hover {
  color: #fff;
  background-color: #45668e;
}
.social-media.light a.whatsapp {
  color: #25d366;
  background-color: rgba(37, 211, 102, 0.08);
}
.social-media.light a.whatsapp:hover {
  color: #fff;
  background-color: #25d366;
}
.social-media.light a.youtube {
  color: #ff0000;
  background-color: rgba(255, 0, 0, 0.08);
}
.social-media.light a.youtube:hover {
  color: #fff;
  background-color: #ff0000;
}
.social-media.bordered a.facebook {
  color: #1877f2;
  border: 1px solid #1877f2;
}
.social-media.bordered a.facebook:hover {
  color: #fff;
  background-color: #1877f2;
  border-color: transparent;
}
.social-media.bordered a.twitter {
  color: #1da1f2;
  border: 1px solid #1da1f2;
}
.social-media.bordered a.twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: transparent;
}
.social-media.bordered a.twitch {
  color: #9146ff;
  border: 1px solid #9146ff;
}
.social-media.bordered a.twitch:hover {
  color: #fff;
  background-color: #9146ff;
  border-color: transparent;
}
.social-media.bordered a.fivehundredpx {
  color: #0099e5;
  border: 1px solid #0099e5;
}
.social-media.bordered a.fivehundredpx:hover {
  color: #fff;
  background-color: #0099e5;
  border-color: transparent;
}
.social-media.bordered a.airbnb {
  color: #fd5c63;
  border: 1px solid #fd5c63;
}
.social-media.bordered a.airbnb:hover {
  color: #fff;
  background-color: #fd5c63;
  border-color: transparent;
}
.social-media.bordered a.amazon {
  color: #ff9900;
  border: 1px solid #ff9900;
}
.social-media.bordered a.amazon:hover {
  color: #fff;
  background-color: #ff9900;
  border-color: transparent;
}
.social-media.bordered a.behance {
  color: #1769ff;
  border: 1px solid #1769ff;
}
.social-media.bordered a.behance:hover {
  color: #fff;
  background-color: #1769ff;
  border-color: transparent;
}
.social-media.bordered a.blogger {
  color: #f57d00;
  border: 1px solid #f57d00;
}
.social-media.bordered a.blogger:hover {
  color: #fff;
  background-color: #f57d00;
  border-color: transparent;
}
.social-media.bordered a.deviantart {
  color: #05cc47;
  border: 1px solid #05cc47;
}
.social-media.bordered a.deviantart:hover {
  color: #fff;
  background-color: #05cc47;
  border-color: transparent;
}
.social-media.bordered a.dropbox {
  color: #007ee5;
  border: 1px solid #007ee5;
}
.social-media.bordered a.dropbox:hover {
  color: #fff;
  background-color: #007ee5;
  border-color: transparent;
}
.social-media.bordered a.etsy {
  color: #d5641c;
  border: 1px solid #d5641c;
}
.social-media.bordered a.etsy:hover {
  color: #fff;
  background-color: #d5641c;
  border-color: transparent;
}
.social-media.bordered a.fedex {
  color: #4d148c;
  border: 1px solid #4d148c;
}
.social-media.bordered a.fedex:hover {
  color: #fff;
  background-color: #4d148c;
  border-color: transparent;
}
.social-media.bordered a.flickr {
  color: #ff0084;
  border: 1px solid #ff0084;
}
.social-media.bordered a.flickr:hover {
  color: #fff;
  background-color: #ff0084;
  border-color: transparent;
}
.social-media.bordered a.flipboard {
  color: #e12828;
  border: 1px solid #e12828;
}
.social-media.bordered a.flipboard:hover {
  color: #fff;
  background-color: #e12828;
  border-color: transparent;
}
.social-media.bordered a.pocket {
  color: #ef4056;
  border: 1px solid #ef4056;
}
.social-media.bordered a.pocket:hover {
  color: #fff;
  background-color: #ef4056;
  border-color: transparent;
}
.social-media.bordered a.google {
  color: #4285f4;
  border: 1px solid #4285f4;
}
.social-media.bordered a.google:hover {
  color: #fff;
  background-color: #4285f4;
  border-color: transparent;
}
.social-media.bordered a.instagram {
  color: #fd1d1d;
  border: 1px solid #fd1d1d;
}
.social-media.bordered a.instagram:hover {
  color: #fff;
  background-color: #fd1d1d;
  border-color: transparent;
}
.social-media.bordered a.linkedin {
  color: #0077b5;
  border: 1px solid #0077b5;
}
.social-media.bordered a.linkedin:hover {
  color: #fff;
  background-color: #0077b5;
  border-color: transparent;
}
.social-media.bordered a.medium {
  color: #00ab6c;
  border: 1px solid #00ab6c;
}
.social-media.bordered a.medium:hover {
  color: #fff;
  background-color: #00ab6c;
  border-color: transparent;
}
.social-media.bordered a.odnoklassniki {
  color: #ed812b;
  border: 1px solid #ed812b;
}
.social-media.bordered a.odnoklassniki:hover {
  color: #fff;
  background-color: #ed812b;
  border-color: transparent;
}
.social-media.bordered a.paypal {
  color: #003087;
  border: 1px solid #003087;
}
.social-media.bordered a.paypal:hover {
  color: #fff;
  background-color: #003087;
  border-color: transparent;
}
.social-media.bordered a.pinterest {
  color: #e60023;
  border: 1px solid #e60023;
}
.social-media.bordered a.pinterest:hover {
  color: #fff;
  background-color: #e60023;
  border-color: transparent;
}
.social-media.bordered a.reddit {
  color: #ff4500;
  border: 1px solid #ff4500;
}
.social-media.bordered a.reddit:hover {
  color: #fff;
  background-color: #ff4500;
  border-color: transparent;
}
.social-media.bordered a.shopify {
  color: #96bf48;
  border: 1px solid #96bf48;
}
.social-media.bordered a.shopify:hover {
  color: #fff;
  background-color: #96bf48;
  border-color: transparent;
}
.social-media.bordered a.skype {
  color: #00aff0;
  border: 1px solid #00aff0;
}
.social-media.bordered a.skype:hover {
  color: #fff;
  background-color: #00aff0;
  border-color: transparent;
}
.social-media.bordered a.slack {
  color: #6ecadc;
  border: 1px solid #6ecadc;
}
.social-media.bordered a.slack:hover {
  color: #fff;
  background-color: #6ecadc;
  border-color: transparent;
}
.social-media.bordered a.soundcloud {
  color: #ff8800;
  border: 1px solid #ff8800;
}
.social-media.bordered a.soundcloud:hover {
  color: #fff;
  background-color: #ff8800;
  border-color: transparent;
}
.social-media.bordered a.spotify {
  color: #1db954;
  border: 1px solid #1db954;
}
.social-media.bordered a.spotify:hover {
  color: #fff;
  background-color: #1db954;
  border-color: transparent;
}
.social-media.bordered a.stripe {
  color: #00afe1;
  border: 1px solid #00afe1;
}
.social-media.bordered a.stripe:hover {
  color: #fff;
  background-color: #00afe1;
  border-color: transparent;
}
.social-media.bordered a.telegram {
  color: #0088cc;
  border: 1px solid #0088cc;
}
.social-media.bordered a.telegram:hover {
  color: #fff;
  background-color: #0088cc;
  border-color: transparent;
}
.social-media.bordered a.trello {
  color: #0079bf;
  border: 1px solid #0079bf;
}
.social-media.bordered a.trello:hover {
  color: #fff;
  background-color: #0079bf;
  border-color: transparent;
}
.social-media.bordered a.tumblr {
  color: #35465c;
  border: 1px solid #35465c;
}
.social-media.bordered a.tumblr:hover {
  color: #fff;
  background-color: #35465c;
  border-color: transparent;
}
.social-media.bordered a.tiktok {
  color: #000;
  border: 1px solid #000;
}
.social-media.bordered a.tiktok:hover {
  color: #fff;
  background-color: #000;
  border-color: transparent;
}
.social-media.bordered a.viber {
  color: #59267c;
  border: 1px solid #59267c;
}
.social-media.bordered a.viber:hover {
  color: #fff;
  background-color: #59267c;
  border-color: transparent;
}
.social-media.bordered a.vimeo {
  color: #1ab7ea;
  border: 1px solid #1ab7ea;
}
.social-media.bordered a.vimeo:hover {
  color: #fff;
  background-color: #1ab7ea;
  border-color: transparent;
}
.social-media.bordered a.vine {
  color: #00b488;
  border: 1px solid #00b488;
}
.social-media.bordered a.vine:hover {
  color: #fff;
  background-color: #00b488;
  border-color: transparent;
}
.social-media.bordered a.vk {
  color: #45668e;
  border: 1px solid #45668e;
}
.social-media.bordered a.vk:hover {
  color: #fff;
  background-color: #45668e;
  border-color: transparent;
}
.social-media.bordered a.whatsapp {
  color: #25d366;
  border: 1px solid #25d366;
}
.social-media.bordered a.whatsapp:hover {
  color: #fff;
  background-color: #25d366;
  border-color: transparent;
}
.social-media.bordered a.youtube {
  color: #ff0000;
  border: 1px solid #ff0000;
}
.social-media.bordered a.youtube:hover {
  color: #fff;
  background-color: #ff0000;
  border-color: transparent;
}